import { useEffect, useState } from "react";
// import Sidebar from "../Sidebar";
// import Topbar from "../Topbar";
import { Spinner } from "react-bootstrap";
import "./ConfigSetting.css";
import { useSelector } from "react-redux";
import { fetchApiKey } from "features/configuration/configSettingService";

import { toast } from "react-toastify";
import PasswordInput from "components/reusables/PasswordInput";

const Key = () => {
  //  user form data
  const [formData, setFormData] = useState({
    cost: "",
  });

  const [loading, setLoading] = useState(false);

  //  Modal states

  const { isLoading } = useSelector((state) => state.verificationTypes);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [keys, setKeys] = useState(null);
  const [keys1, setKeys1] = useState(null);

  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);

  const handleToggele = () => {
    setToggle(!toggle);
  };
  const handleToggele2 = () => {
    setToggle2(!toggle2);
  };
  const handleToggele3 = () => {
    setToggle3(!toggle3);
  };
  const handleToggele4 = () => {
    setToggle4(!toggle4);
  };
  const getApiKey = async () => {
    setLoading(true);
    try {
      const response = await fetchApiKey();
      if (response.success) {
        // setKeys(response.data.configuration_setting);
        let live = response.data.configuration_setting.filter(e => e.mode === 'live');
        setKeys(live);

        // test
        let test = response.data.configuration_setting.filter(e => e.mode === 'test');
        setKeys1(test);


        

      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setLoading(false);
    }
  };

  const copyText = async (text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    toast.success("Key copied to clipboard successfully");
  };

  useEffect(() => {
    getApiKey();
  }, []);

  return (
    <>
      <div className="pb-5">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="info" />
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="precision-verify-card w-lg-100">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-10">
                        <label htmlFor="">Test Client ID</label>
                        <PasswordInput
                          inputLabel={`Test Public Key`}
                          inputType={toggle ? `text` : `password`}
                          inputName="current_password"
                          inputRequire={true}
                          value={keys1 && keys1[0] && keys1[0].public_key || ''}
                          onChange={handleChange}
                          toggle={toggle}
                          handleToggele={handleToggele}
                           disabled={true}
                          size="col-lg-12"
                        />
                      </div>
                      <div className="col-md-2 pt-2 mt-2">
                        <label htmlFor=""></label>
                        <button
                          className="btn btn-sm btn-custom"
                          onClick={() =>
                            copyText(keys1 && keys1[0] && keys1[0].public_key)
                          }
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-10">
                        <label htmlFor="">Test Secret Key</label>
                        <PasswordInput
                          inputLabel={`Test Secret Key`}
                          inputType={toggle2 ? `text` : `password`}
                          inputName="current_password"
                          inputRequire={true}
                          value={keys1 && keys1[0] && keys1[0].secret_key || ''}
                          onChange={handleChange}
                          toggle={toggle2}
                          handleToggele={handleToggele2}
                          size="col-lg-12"
                           disabled={true}
                        />
                      </div>
                      <div className="col-md-2 pt-2 mt-2">
                        <label htmlFor=""></label>
                        <button
                          className="btn btn-sm btn-custom"
                          onClick={() =>
                            copyText(keys1 && keys1[0] && keys1[0].secret_key)
                          }
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <div className="precision-verify-card w-lg-100">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-10">
                        <label htmlFor="">Live Client ID</label>
                        <PasswordInput
                          inputLabel={`Live Public Key`}
                          inputType={toggle3 ? `text` : `password`}
                          inputName="current_password"
                          inputRequire={true}
                          value={keys && keys[0] && keys[0].public_key || ''}
                          onChange={handleChange}
                          toggle={toggle3}
                          handleToggele={handleToggele3}
                          size="col-lg-12"
                           disabled={true}
                        />
                      </div>
                      <div className="col-md-2 pt-2 mt-2">
                        <label htmlFor=""></label>
                        <button
                          className="btn btn-sm btn-custom"
                          onClick={() =>
                            copyText(keys && keys[0] && keys[0].public_key)
                          }
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-10">
                        <label htmlFor="">Live Secret Key</label>
                        <PasswordInput
                          inputLabel={`Live Secret Key`}
                          inputType={toggle4 ? `text` : `password`}
                          inputName="current_password"
                          inputRequire={true}
                          value={keys && keys[0] && keys[0].secret_key || ''}
                          onChange={handleChange}
                          toggle={toggle4}
                          handleToggele={handleToggele4}
                           disabled={true}
                          size="col-lg-12"
                        />
                      </div>
                      <div className="col-md-2 pt-2 mt-2">
                        <label htmlFor=""></label>
                        <button
                          className="btn btn-sm btn-custom"
                          onClick={() =>
                            copyText(keys && keys[0] && keys[0].secret_key)
                          }
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Key;
