import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const uploadDoc = async (inputData, businessId, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/user/businesses/${businessId}/uploads`, inputData, config);
    return data
}

// @ retun list of directors for a particular business
const getUpload = async (businessId, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/businesses/${businessId}/uploads`, config);
    return data
}


const uploadService = {
    uploadDoc,  
    getUpload,
    
}

export default uploadService