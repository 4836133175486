import Footer from "components/Footer"
import Navbar from "components/Navbar"
import HelpCta from "pages/helpCenter/HelpCta"
import TermsComponent from "./TermsComponent"
import TermsHeader from "./TermsHeader"


const Terms = () => {
  return (
    <>
        <Navbar />
        <TermsHeader />
        <TermsComponent />
        <HelpCta />
        <Footer />
    </>
  )
}

export default Terms