import { FaAddressBook } from "react-icons/fa"

const TermsComponent = () => {

   

  return (
    <section className="section-md-sm">
    <div className="container px-4">					
        <div className="row gx-4 gx-lg-5 justify-content-centers gv-about-intro">                    
            <div className="col-lg-10 offset-lg-1 about-intro-content animate__animated animate__fadeIn animate__delay-b">
                <div className="gv-accordion">
                    <div className="accordion accordion-flush" id="helpCenterItems">

                <h3> Terms and Conditions </h3>

<strong>Welcome to Giving.ng. </strong> 

<p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the giving.ng website or mobile application (the "Platform") developed and operated by Sterling One Foundation ("us", "we", "our", “the Foundation”).  </p>

<p>These Terms represent an agreement between You (“User”, “Campaign Organizer”) and Us and contain the terms and conditions governing your use of and access to the Platform. By using or accessing the Platform, you represent that you have read and understood these Terms and you agree to be bound by these Terms. If you do not agree with any part of the following Terms, you should not use the Platform or access the service on the Platform. </p>

<strong>Modifications to the Terms and Conditions </strong>

<p>We may change these terms from time to time. This is solely at our discretion and changes will be effective when posted on the Platform with no other notice provided. Please check these Terms and Conditions regularly for updates as changes shall be effective immediately, and we may indicate the date of such revision.</p> 

<p>In addition, we may change, suspend, or discontinue any aspect of the Services at any time, including the availability of any feature, or content. We may also impose limits on certain features and service or restrict your access to parts or all of the Platform or the Services provided without notice or liability. </p>

 

<p>If you keep using Giving.ng after a change, that means you accept the new terms. </p>

<strong>
ACCESS AND USE OF THE SERVICE 
<br/>
Giving.ng Service description 
</strong>
<br />

The Giving.ng Services offered on the platform are;   

<ul>
    <li>to enable an individual, entity or non-profit organization (the "Campaign Organizer") to post a fundraising campaign ("Campaign") on the Platform, </li>
    <li>to accept monetary donations (“Donations”) from donors ("Donors"). </li>
    <li>to enable a Campaign Organizer to accept monetary Donations from Donors for a charitable cause.  </li>
    <li>to enable members of cooperatives and associations contribute monthly or at pre-determined intervals for dues or defined projects </li>
    <li>to enable groups of people (like friends or colleagues) contribute funds to be given on a rotational basis to each member of the group periodically or agreed intervals. </li>
</ul>
 
<strong>Fees</strong> 

<p>Setting up a campaign on the platform is free, at no cost to the campaign organizer. This also applies for revolving contributions and group collections. You may be charged a 2% service fee on amounts raised upon conclusion of a campaign on the platform, and standard Industry payment processing fees may also apply. You may also be charged a cap of N1,500 for every sweep on revolving contributions and group collections. </p>

 

<strong>Refunds </strong>

<p>Donors acknowledge and agree that donations made on the Platform are not refundable under any circumstance. </p> 

 

<strong>Personal Information </strong>

<p>Donors are not required to provide any personally identifiable information to access the Platform. You may be required to share your name and email address to enable Giving.ng process donations to beneficiaries and provide project updates. In such cases, we will store this information securely and will not share, rent, or sell it to anyone. You can read our privacy policy for more information on the use of your information. </p>

 

<strong>Registration </strong>

<p>
We may require you to register with Giving.ng in order to access and use certain features of the Services. If you choose to register for the Services, you agree to provide and maintain true, accurate, up-to-date and complete information about yourself or your Charity. If you are a Campaign Organizer, Cooperative or Association you must register using your true identities (or the identities of your Charities’ authorized representatives or members), including their name, address and/or any image purporting to depict the Campaign Organizer or the Beneficiary of such campaign.  

</p>
 
 <p>If you are under the age of 18 years, you may use the Site only with the involvement of a parent or legal guardian who agrees to be bound by these Terms. 
</p>

<strong>Registration Requirements for the use of Platform </strong>
 <p>A Campaign Organizer, Cooperative or Association would be required to register its information on the Platform in order to act in that capacity. These details should be updated when/if they change (e.g. email address, telephone) on your account. The information required to be provided include without limitation: </p>
 

 <strong>For Individuals </strong>

<ul>
    <li>Name</li>
    <li>Photo (Optional) </li>
    <li>Email</li>
    <li>Functional Phone number </li>
    <li>Physical address </li>
    <li>Country/State </li>
    <li>Website</li>
    <li>Social media handles - Facebook, Instagram, and Twitter (optional) </li>
</ul>
 

<strong> For Corporate Bodies/Associations  </strong>

<ul>
    <li>Registered Name </li>
    <li>Registration documents </li>
    <li>Memorandum and Articles of association OR Byelaws of a Cooperative </li>
    <li>Corporate governance structure </li>
    <li>Valid means of identification of at least 2 trustees/Directors </li>
    <li>Authorized signatory list </li>
</ul>

<strong>Project Information</strong> 
<ul>
    <li>Summary of project </li>
    <li>Project description  </li>
    <li>Proposed Amount to be raised</li>
    <li>Number and location of Project beneficiaries </li>
    <li>Expected project impact- (qualitative and quantitative) </li>
    <li>Project duration </li>
    <li>Proof of project (Project image(s) Project video (Not more than 4minutes))  </li>
    <li>Account details of project beneficiary(ies) ▪ Bank ▪ Account Name ▪ Account Number  </li>
</ul>

<strong>Fund raising Campaigns </strong>

<p>Although we recommend you have no more than one active Project at any one time, we permit you to post as many active Projects as you like. We also recommend that you have an effective communication campaign per project to ensure project goals are achieved. The minimum Fundraising Period that you can choose for your Project is 20 days and the maximum is 12 months. </p>

 

<p>Depending on your profile and the Target Amount, your Project may take up to 3 working days before it goes live and your Project Profile Page is made available on giving.ng. From time to time, we may provide Crowdfunding counsel to provide guidance relating to your Campaign, and offer tips for marketing and press exposure. Please note that counsel provided is general information only and we will not be liable in connection with the Campaign Goal, and/or performance, promotion and marketing of your Project, which shall remain solely your responsibility. Once submitted, your Campaign Goal, Fundraising Duration or Target Amount cannot be changed. You may post content and other material (e.g. promotional material such as pictures and videos containing your Project) and post Project updates to keep Donors updated on the performance of your Project.  </p>

 

<p>
We reserve the right, but are not obliged, to attempt to verify your identity and other information you provide to us and to reject, cancel, interrupt, remove, or suspend an active Project at any time and for any reason. In particular, Projects that are flagged to us as fraudulent by Donors or our third-party payment providers will be subject to review. If we find fraudulent Pledges have been made to your Project, we will cancel those Pledges and remove the details of the associated Donors from your Project Profile Page. If, in our sole discretion, we determine your Project to be a high fraud risk we may also ask you for more information and you agree to respond to such requests and provide such information within a reasonable time. We or our payment providers may also perform an examination before any funds are distributed to you. We will not be liable to you for any losses that you suffer or incur as a result of us taking any of  these actions. We reserve the right to pass on the cost of chargebacks to you. 
</p>

 
<p>
    
If the Fundraising Period of your Project ends without any distribution of funds to you (for reasons other than a breach by you of these Terms) you may choose to recreate your Project and repost it for submission. We do not provide funds for Campaigns. All finance for Campaigns/Projects is made by donors.  Should the Projects and/or offer of Rewards relate to active or pending legal proceedings, we take no involvement in such Campaigns. We do not stand to benefit as to the outcome of any Project nor do we seek to control Projects or their outcomes. 
</p>

 

<strong>Donations/Contribution </strong>

<p>
Certain features on the Platform may allow the Users to make donations in support of the different causes on the Platform using a number of different means including credit cards, debit cards (“Payment Method”). The platform functionality also allows for group collection and revolving contribution typical with Social Interest and Impact groups such as Alumni Associations, Religious Associations etc. In Group collection and revolving contribution, a Group Administrator shall take charge of registering and providing relevant documentation for the use of the Platform. Although we may from time to time agree to promote and advertise certain Campaigns or help to raise awareness of some Projects, we will not be involved in the management of or use of Donations in relation to a Campaign except where explicitly stated. 
</p>

 

<strong>Payment </strong>
<p>By providing information related to a Payment Method, the User represents, acknowledges and agrees that the User: (i) is permitted to use that Payment Method; and (ii) shall be solely responsible for any fee charged by the User’s financial institution or other payment method provider. </p>
<p>
We take reasonable care to ensure that all requests to make a donation are conducted via a secure link. However, the security of information and payments transmitted via the internet cannot be guaranteed. You acknowledge and agree that: (i) donations are at your own discretion and risk; and (ii) We shall have no liability whatsoever for (a) any loss you may suffer if a third party obtains unauthorized access to any information provided and (b) any act or omission by your financial institution, credit, debit card or other payment method provider, telecommunications services providers or any third party in connection with the donations related thereto.
</p> 

 

<p>When you make a Donation through the Platform, it is your responsibility to understand how your money will be used. We are not responsible for any offers, promises, rewards or promotions made or offered by Users or Campaigns. We do not and cannot verify the information that Users or Campaigns supply, nor do we represent or guarantee that the Donations will be used in accordance with any fundraising purpose prescribed by a User or Campaign or in accordance with applicable laws. Notwithstanding the foregoing, we take possible fraudulent activity and the misuse of funds raised very seriously. If you have reason to believe that a User or Campaign is not raising or using the funds for their stated purpose, please send an email to the email address provided on the Platform, to alert our team of this potential issue and we will investigate. A Charity or Campaign Organizer shall have full discretion to determine how all Donations will be used. Donors are not permitted to impose restrictions on the use of Donations by a Charity or Campaign Organizer. 
</p>
 

<p>The amounts due to Campaign Organisers will only be transferred to them once all necessary internal checks have been carried out. All fees payable to us, and any amounts payable by us to Campaign Organisers, will be paid in Nigerian Naira only. Payment in other currencies will be subject to the prevailing regulatory dispensation. </p>

 

<strong>Donors </strong> 

<p>Donors are not obligated to register prior to making a donation. A donor may elect to donate anonymously. </p> 

 

<p>A Donor may also choose to make a one-time donation or set a recurring donation request on the platform for a specified period either: Weekly ▪ Monthly ▪ Quarterly or Annually.  </p>

 

<strong>Taxes </strong>

<p>We make no representation as to whether all or any portion of your Donations, including, if any, Payment Processor Fees, are tax deductible or eligible for tax credits. We will have no liability for any claim by any Governmental body or other tax authority with respect to any applicable tax return of any Donation by you, any User or any Charity. You understand and agree to consult your tax advisor as to the amount of your Donation that is tax deductible or eligible for tax credit, having regard. </p>

 

<strong>Campaign Organizer’s Warranties </strong>

<p>You will be required to register as a Campaign Organizer on the platform 

As a campaign Organizer, you represent, warrant, and covenant that: </p> 
 

<p>(i) all information you provide in connection with a Campaign or Beneficiary is accurate, complete, and not likely to deceive or defraud reasonable donors; </p> 

<p>(ii) all Donations contributed to your Campaign will be used solely as described in the materials that you post or otherwise provide;  </p>

<p>
(iii) if you withdraw donations believed by reasonable donors to be raised on behalf of someone other than you (i.e. “the Beneficiary”), all Donations will be given to and/or spent on behalf of the Beneficiary; 
</p>

<p>(iv) if you add a fraudulent Beneficiary through the service, you will relinquish control of the Donations, including the ability to issue refunds (if any);  </p>

<p>
(v) you will not infringe the rights of other users of the Platform;  
</p>

<p>
(vi) you will comply with all relevant and applicable laws and financial reporting obligations, including but not limited to laws and regulations relating to registration, tax reporting, political contributions, and asset disclosures for your project; and  
</p>

<p>
(vii) we may require you to share with us any personal data of any third party for any purpose, including the names, email addresses and phone numbers of your personal contacts from whom you have obtained necessary consent. You authorize Us and We reserve the right to, provide information relating to your Campaign to Donors, beneficiaries of your Campaign or law enforcement, and to assist in any investigation thereof. 
</p>

<p>
If you use the Services as an agent of a Charity using the services to raise funds for such Charity, you represent and warrant that:  

</p>
<p>(a) you are a representative of the Charity, which representative is authorized to raise funds or bind the Charity to these Terms of Service;  </p>

<p>(b) you are raising funds for a Charity, with a cause or activity that is legal under all applicable federal, state, provincial, territorial and local laws and regulations; </p>

<p> (c) all donated funds will be used solely for the purpose you have stated on and in connection with your Campaign, and under no circumstances may you use the funds for any other purpose. </p>

<strong>Campaign Organizers also warrant to: </strong>

<p>(a) promptly and accurately respond in full and to our satisfaction all queries, clarifications or requests made by us and/or any Donors; </p>

<p>(b) comply with all obligations on personal data usage under applicable data protection and privacy laws, by providing appropriate privacy information to the Donors whose information you receive through giving.ng; </p>

<p>(c) promptly contact and work with Donors to reach a mutually satisfactory resolution, which may include refunding their Pledges if you are unable to fulfil any of your commitments; </p>

<p>(d) comply with all applicable laws and regulations in relation to your Campaign/Project, and your use of donations; </p>

<p>(e) be responsible for paying all fees and collecting and remitting all applicable taxes (including income tax and VAT or similar taxes) connected with your use of giving.ng, the Pledges you receive and the Rewards you offer; </p> 

<p>
(f) where you have received any donations, be responsible for issuing refund of Pledges to and responding to requests for refunds of Pledges from Donors; 
</p>

<p>(g) not take any action or make any business or other decision in reliance on having your Campaign/Project posted on giving.ng or on having any funds from donations until you have the received clear funds into your bank account; and </p>

<p>(h) provide all assistance and co-operation required by us from time to time, especially with relation to proof of project execution; including by providing copies of any records or information you hold, for any regulatory investigation, audit or enquiry and for marketing purposes </p>

<strong>Receiving Funds </strong>

<p>Remittance of Donations to a Charity or campaign, Revolving contribution and Collection requests are subject to the applicable payment processing fees. </p>

 

<p>When paying using Giving.ng, we take your donation immediately and hold it in a secure account. We are therefore responsible for distributing funds to Campaign Organisers. </p>

 

<p>We do not withhold funds for tax purposes or otherwise. Campaigns, Cooperatives, and other bodies will be solely responsible for tax remittances based on applicable taxes or levies.  </p>

 

<strong>Account Security </strong>

<p>You acknowledge and agree that you are responsible for maintaining the confidentiality of your password and account, if any, and are fully responsible for any and all activities that occur under your password or account. You agree to immediately notify us of any unauthorized use of your password or account. We will not be liable for any loss or damage arising from your failure to comply with this provision.  We do not verify the identity of everyone who becomes a Community Member nor the information they provide about their Campaign/Project. We cannot therefore give any guarantee that any of the Community Members are who they say they are or that the information they provide is accurate, complete or true. Consequently, we make no recommendation or representations in relation to any Community Members or their Campaigns/Projects. </p>

 

<strong>Third Party Links </strong>
<p>
The Platform may contain links and references to third-party web sites. The linked sites are not under our control and will not be deemed to be our responsibility. We do not accept claims of liability or responsibility in any way for these policies or links, as we have no way to control the third-party sites. </p>

 

<strong>Prohibited Conduct  </strong>

<p>Below are examples of User content and/or use of the platform that is illegal or prohibited. This list is not exhaustive, and we reserve the right to remove any Campaign and/or investigate any User who, in our sole discretion, violates any of the terms or conditions of these Terms of Service. When we investigate Your Campaign, a User, or User Content, we may consider all available material including but not limited to social media, related news, and any other information that we, in our sole discretion, deem relevant in our review. We further reserve the right, without limitation, to ban or disable your use of the Services, remove the offending User Content, suspend or terminate Your account, stop payments to any such Campaign or charity, freeze or place a hold on any or all Donations, and report you to law enforcement authorities or otherwise take appropriate legal action including seeking restitution on behalf of ourselves and/or our users. </p>

 

<strong>In view of the foregoing, you understand and agree: </strong>

<p>not to use the Services to raise funds or establish or contribute to any Campaign with the implicit or explicit purpose of or involving: </p>

<p>the violation of any law, regulation, industry requirement, or third-party guidelines or agreements by which you are bound, including those of payment card providers and transaction processors that you utilize in connection with the Services; </p>

<p>user content or campaigns that are fraudulent, misleading, inaccurate, dishonest, or impossible; </p>

<p>drugs, narcotics, steroids, controlled substances, pharmaceuticals or similar products or therapies that are either illegal, prohibited, or enjoined by an applicable regulatory body; legal substances that provide the same effect as an illegal drug; or other products, medical practices, or any related equipment or paraphernalia that have been found by an applicable regulatory body to cause consumer harm;</p> 

<p>knives, explosives, ammunition, firearms, or other weaponry or accessories; </p>

<p>annuities, investments, loans, equity or lottery contracts, lay-away systems, off-shore banking or similar transactions, money service businesses (including currency exchanges), pyramid schemes, “get rich quick schemes” (i.e., Investment opportunities or other services that promise high rewards), network marketing and multi-level referral marketing programs, debt collection or crypto-currencies; </p>

<p>gambling, gaming and/or any other activity with an entry fee and a prize, including, but not limited to raffles, casino games, sports betting, fantasy sports, horse or greyhound racing, lottery tickets, raffle tickets, auctions and other ventures that facilitate gambling, games of skill or chance (whether or not it is legally defined as a lottery), promotions involving monetary rewards, including gift cards, or sweepstakes; </p>

<p>user content that we deem, in our sole discretion, to be in support of hate, violence, harassment, bullying, discrimination, terrorism, or intolerance of any kind relating to race, ethnicity, national origin, religious affiliation, sexual orientation, sex, gender or gender identity, or serious disabilities or diseases; </p>

<p>campaign that we deem, in our sole discretion, to be for the legal defense of alleged crimes associated with hate, violence, harassment, bullying, discrimination, terrorism, or intolerance of any kind relating to race, ethnicity, national origin, religious affiliation, sexual orientation, sex, serious disabilities or diseases, or financial crimes or crimes of deception; </p>

<p>activities with, in, or involving persons, or entities that are subject to Regulatory  and other economic sanctions under applicable law, unless such activities are expressly authorized by the appropriate governmental authority </p>

<p>funding a ransom, human trafficking or exploitation, vigilantism, bribes or bounty; </p>

<p>pornography or other sexual content or offensive, graphic, perverse or sensitive content; </p>

<p>collecting payments on behalf of merchants by payment processors or otherwise; including but not limited to self-payments on campaigns or an attempt to bypass or otherwise circumvent the designated method of payment as provided by Giving.ng; </p>

<p>aggregation of funds owed to third parties, factoring, or other activities intended to obfuscate the origin of funds; </p>

<p>collecting or providing funds for any purpose other than as described in a Campaign description; </p>

<p>any other activity that We may deem in our sole discretion to be in support of individuals and/or entities associated with alleged financial crimes including but not limited to corruption, bribery, tax evasion, fraud, and activities of a similar nature; or </p>

<p>any other activity that Giving.ng may deem in its sole discretion to be unacceptable. </p>

<p>not to interfere with or disrupt servers or networks connected to the Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Services; </p>

<p>not to harvest, collect or publish personally identifiable information of others; </p>

<p>not to raise funds for a minor without the express permission of the minor’s parents or legal guardian unless the funds are transferred into a trust account for the sole benefit of the minor; </p>

<p>not to use the Services on behalf of a third party or post any personal data or other information about a third party, without the express consent of that third party; </p>

<p>not to use another User's account or URL without permission, impersonate any person or entity, falsely state or otherwise misrepresent your affiliation with a person or entity, misrepresent a Charity or Campaign through the Services, or post User Content in any inappropriate category or areas on the Services; </p>

 

<strong>Intellectual property </strong>

<p>Giving.ng Site/Platform is the property of Sterling One Foundation. It is protected by copyright, trademark and other intellectual property laws. You agree to retain all copyright and trademark notices, including any other proprietary notices, contained in the materials, and not to alter, obscure or obliterate any of such notices. Unless otherwise set forth in these Terms, none of the content or data found on the Platform may be copied, reproduced, republished, sold, transferred, modified or distributed in any way without our prior written consent. Trademarks, logos, images and service marks displayed on the Giving.ng Site are the property of either the Foundation or other third parties. Users agree not to display or use any Giving.ng or third-party marks without our prior written consent or that of the third party. We reserve all rights not expressly granted to User. </p>

 

<b>Termination</b> 

<p>We may, in our sole discretion, terminate or suspend your access to all or part of the Platform or the Services offered for any reason, including breach of any of the provisions of these Terms and Conditions. </p>

<p>Termination of these Terms and Conditions for any reason, will not affect survival of those provisions of these Terms and Conditions for which survival is equitable. </p>

<p>Your non-termination of a Donation Subscription or revolving contribution reaffirms that we are authorized to charge your Payment Instrument for the Donation Subscription or any other subscription in accordance with these terms. </p> 

<strong>Disclaimer</strong> 

<p>All information and content provided by Us on the Platform relating to the Services is for informational purposes only, and we do not guarantee the accuracy, completeness, timeliness or reliability of any such information or content. No content is intended to provide financial, legal, tax or other professional advice. Before making any decisions regarding any Campaigns, Charities, Donations, Donors, or any information or content relating to the Services, you should consult your financial, legal, tax or other professional advisor as appropriate. You acknowledge that all information and content accessed by you using the Services is at your own risk. </p> 

<p>
The Platform and services are provided on an "as is" and "as available" basis. We and our  affiliates expressly disclaim and exclude, to the fullest extent permitted by applicable law, all warranties, conditions and representations of any kind, whether express, implied or statutory, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. 
</p>

<p>
We and our affiliates make no warranty or condition that (i) the services will meet your requirements, (ii) the services will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the services will be accurate or reliable, or (iv) the services, information, or other material obtained by you through the services will meet your expectations. Furthermore,  we do not make any promises as to the truth, accuracy, integrity, quality or completeness of the content or information that appears on the Platform neither are we responsible for verifying the ownership of any content posted or uploaded on the Platform. 
</p>

<p>
The Platform is offered to help Campaign Organizers and charities raise money including helping cooperatives and associations accept funds. Giving.ng provides the technology to allow fundraisers to connect with Donors. The existence of the Services to Campaign Organizers is not a solicitation of donations by Giving.ng. on behalf of the Campaign Organizers. Giving.ng does not consult on the solicitation of contributions from the public on behalf of the Campaign Organizers. By using the Services, you understand and agree that Giving.ng shall not be responsible for the use of your donations or the amount of funds raised for the User, Campaign, or event. 
</p>

<p>
You acknowledge that we do not have any involvement in any arrangements that Users or group of Users make with each other through Giving.ng. Therefore, we do not accept any responsibility for the nature or quality of a Campaign, Group Contribution, Project, or revolving contribution, nor do we warrant that any User of the platform, whether Campaign Organizer, Donor, Cooperative or Group member will perform their roles as promised. The arrangements you make are solely private and the contracts relating to Projects, Campaigns and Pledges, are made directly between you and the individual parties concerned. Accordingly, you agree and acknowledge that you take full responsibility for your arrangements with the relevant Users whom you contact and the nature, terms and extent of your arrangements with them and obligations to them. 
</p>

<p>Any posting of comments or information on the Platform represents the opinion of the person posting only and is not a reflection of our opinions or attitudes, nor does it constitute any form of recommendation, representation, endorsement or arrangement by us. To be clear, each User acts on it/his/her own behalf at all times and not as our representative or agent in any way. </p>

<strong>Indemnity</strong> 

<p>
You agree to indemnify on demand and hold the Foundation, affiliates, officers, employees, directors and agents harmless from any and all losses, damages, expenses, including reasonable attorneys' fees, costs, awards, fines, damages, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of the Services or Platform and any other person that uses your account with your permission or as a result of your negligence, any Donation or Campaign, any User Content, your connection to the Services, your violation of these Terms (including as a result of any Campaign that you post on the Platform or any actions you take which disrupt access to and/or the functioning of the Platform) or your violation of any rights of another. You agree that we have the right to conduct our own defense of any claims at our own discretion, and that you will indemnify us for the costs of our defense (including, but not limited to attorney’s fees.) 
</p>

 

<strong>Limitation of Liability </strong>

<p>You expressly understand and agree that, to the fullest extent permitted by applicable law, we will not be liable for any </p> 

(a) indirect, incidental, special, consequential, punitive, or exemplary damages,  

(b) damages for loss of profits,  

(c) damages for loss of goodwill,  

(d) damages for loss of use,  

(e) loss or corruption of data, or 

<p>
Under no circumstances, including negligence, shall we, or our directors, officers, employees, agents or affiliates, be liable for any indirect, incidental, special or consequential damages, including for any lost profits or lost data, that result from the use of, or the inability to use, the Content, unauthorized access to or alteration of your transmissions or data, the Content or any errors or omissions in the Content, even if Giving.ng is aware or has been advised of the possibility of such damages. </p> 

<strong>Severability</strong>  

<p>If any term or provision of these terms is judicially determined to be illegal, unenforceable, or invalid in whole or in part for any reason, such illegal, unenforceable, or invalid provisions or part(s) of the same shall be stricken from this terms, and such provision shall not affect the legality, enforceability, or validity of the remainder of this terms. The stricken provision maybe replaced, to the extent possible, with a legal, enforceable, and valid provision that is as similar in term to the stricken provision as is legally possible. </p>

 

<strong>Waiver</strong>  

<p>No waiver of any provision of this term shall constitute a waiver of any other provision(s) or of the same provision on another occasion. Our failure to enforce any provision of this terms shall not constitute a waiver of such provision or any other provision(s). </p>

 

<strong>Governing Law and Dispute Resolution  </strong> 

<p>These Terms shall be interpreted and governed by the laws of the Federal Republic of Nigeria. Any disputes or claims arising out of or about its subject matter or formation (including non-contractual disputes or claims) that cannot be resolved amicably by the parties through negotiation within thirty (30) days, shall be referred to Mediation at the Lagos State Multi-Door Courthouse (LMDC) for resolution under the provisions of Lagos State Multi-Door Courthouse (LMDC) Law 2007. Each party shall bear their cost of the Mediation. The mediator shall be a person who is legally trained and who has experience in crowd funding and not-for-profit organizations in Nigeria and is independent of either party. </p>

 
<br />
<strong><FaAddressBook /> Contact </strong>

<p>For enquires, suggestions, information, or feedback, please contact us at info@giving.ng.  
close</p>

 

 









 
                        </div>
						</div>
                    </div>
                </div>
            </div>
        </section>	
  )
}

export default TermsComponent