import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const createProject = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/admin/projects`, inputData, config);
    return data
}
//Update project
const updateProject = async (inputData, projectId, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.put(`${apiUrl}/admin/projects/${projectId}`, inputData, config);
    return data
}

// Activate project
export const activateProject = async (projectId, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      
      const { data } = await axios.patch(`${apiUrl}/admin/projects/${projectId}/toggle-active`, {}, config);
   
    return data
}

// @ retun list of directors for a particular business
const getProjects = async ( token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/admin/projects?include=country,city,state`, config);
    return data
}

const getProjectReport = async ( token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.get(`${apiUrl}/admin/projects/reporting`, config);
  return data
}

const getStat = async ( token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.get(`${apiUrl}/admin/projects/dashboard`, config);
  return data
}


// @ retun list of directors for a particular business
const getProject = async (projectId, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/admin/projects/${projectId}?include=donations,user,country,city,state`, config);
    return data
}



// @ function delete a project with project ID    
const deleteProject = async (projectId, token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.delete(`${apiUrl}/admin/projects/${projectId}`, config);
  return data
}

const generalProjectService = {
    createProject,  
    getProject,
    getProjects,
    updateProject,
    deleteProject,
    activateProject,
    getProjectReport,
    getStat
}

export default generalProjectService