import { Breadcrumb, Button, Modal, Spinner, Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { useEffect, useState } from "react";
import './Report.css'
import { useDispatch, useSelector } from "react-redux";
import { getUserVerificationTypes, getVerificationTypes } from "features/generic/verification/verificationTypeSlice";
import { fetchUserReportStats, fetchUserReportTable } from "features/user/report/userReportService";
import { toast } from "react-toastify"
import Moment from 'react-moment';
import { Pagination } from '@mui/material';
import VerificationResult from "components/PrecisionVerify/VerificationResult";


const ReportContent = () => {
    //  user form data
    const [formData, setFormData] = useState({
        start_date: '',
        end_date: '',
        type: ''
    })
    const [loading, setLoading] = useState(false)
    const [reportTable, setReportTable] = useState([])
    const [reportStat, setReportStat] = useState([])
    const [currentReport, setCurrentReport] = useState(null)

    const dispatch = useDispatch()

    const {userVerificationTypes: verificationTypes, isError, isLoading, isSuccess, message} = useSelector(state => state.verificationTypes)

    const selectOptions = verificationTypes ? verificationTypes.items.map(v => (
        {id: v.id, value: v.id, caption: v.name}
    )) : []

    const [toggle, setToggle] = useState(false)

    //  Modal states
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //  state for pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(1);

    //  pagination function
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const filterReport = (e) => {
        e.preventDefault()
        if(!formData.start_date && formData.end_date) {
            return toast.error("Please, select a start date")
        }
        if(formData.start_date && !formData.end_date) {
            return toast.error("Please, select an end date")
        }
        fetchUserVerificationReport(page, perPage, formData)
        fetchUserVerificationReportStat(formData)
    }

    const handleToggele = () =>{
        setToggle(!toggle)
    }

    const fetchUserVerificationReport = async(page, perPage, formData) => {
        try {
            setLoading(true)
            const resp = await fetchUserReportTable(page, perPage, formData)
            
            if(resp.success){
                toast.success(resp.message)
                setReportTable(resp.data.report.data.length ? resp.data.report.data : null)
                const pageCount = Math.ceil(resp.data.report.total / resp.data.report.per_page);
                setPageCount(pageCount)
                setLoading(false)
            }
        
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setLoading(false)
        }
    }

    const fetchUserVerificationReportStat = async(formData) => {
        try {
            setLoading(true)
            const resp = await fetchUserReportStats(formData)
            
            if(resp.success){
                // toast.success(resp.message)
                setReportStat(resp.data.statistics.length ? resp.data.statistics[0] : null)
                setLoading(false)
            }
        
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setLoading(false)
        }
    }

    const viewDetails = (report) => {
        setCurrentReport(report)
        handleShow()
    }

    useEffect(() => {
        dispatch(getUserVerificationTypes())
        fetchUserVerificationReportStat()
    }, [])

    useEffect(() => {
        fetchUserVerificationReport(page, perPage, formData)
    }, [perPage, page])

    return (
        <>
            <div className="inner-page-wrapper">
                {/* Sidebar */}
                <Sidebar />
                <div
                id="content-wrapper"
                className="content-wrapper d-flex flex-column"
                >
                {/* Main Content  */}
                <div id="content">
                    {/* Topbar */}

                    <Topbar />

                    {/* Begin Page Content  */}
                    <div className="container-fluid">
                    {/* Page Heading  */}
                    <div className="d-sm-flex align-items-center justify-content-between mb-5">
                        <h1 className="h3 mb-0 text-gray-800 font-weight-bold">
                        Verification Report
                        </h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item active>Report</Breadcrumb.Item>
                        </Breadcrumb>
                        {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                                    <i className="bi bi-cloud-download"></i> Generate Report</a> */}
                    </div>

                    {/* Content Row  */}
                    <div className="pb-5">
                            {loading || isLoading ?
                                <div className="d-flex justify-content-center align-items-center">
                                    <Spinner animation="grow" variant="primary"/>
                                    <Spinner animation="grow" variant="danger"/>
                                    <Spinner animation="grow" variant="warning"/>
                                    <Spinner animation="grow" variant="info"/>
                                </div>
                            :
                                <>
                                    <div className="report-analytics mb-4">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="precision-verify-card shadow-sm mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="analytics-title">
                                                            <p>Total</p>
                                                            <p>Verifications</p>
                                                        </span>
                                                        <span className="ml-auto analytics-count">
                                                            <h4 className="text-info">{reportStat?.total_verifications}</h4>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="precision-verify-card shadow-sm mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="analytics-title">
                                                            <p>Successful</p>
                                                            <p>Verifications</p>
                                                        </span>
                                                        <span className="ml-auto analytics-count">
                                                            <h4 className="text-success">{reportStat?.total_success}</h4>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="precision-verify-card shadow-sm mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="analytics-title">
                                                            <p>Failed</p>
                                                            <p>Verifications</p>
                                                        </span>
                                                        <span className="ml-auto analytics-count">
                                                            <h4 className="text-danger">{reportStat?.total_failed}</h4>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="precision-verify-card shadow-sm mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="analytics-title">
                                                            <p>Mismatched</p>
                                                            <p>Verifications</p>
                                                        </span>
                                                        <span className="ml-auto analytics-count">
                                                            <h4 className="text-info">{reportStat?.total_mismatch}</h4>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="precision-verify-card shadow-lg">
                                        <form onSubmit={filterReport}>
                                            <div className="form-group row my-4 ana">
                                                <div className="col-lg-3 mb-3">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="start-date">
                                                            Start Date
                                                        </span>
                                                        <input 
                                                            type="date" 
                                                            className="form-control" 
                                                            aria-describedby="start-date"
                                                            name="start_date"
                                                            value={formData.start_date}
                                                            onChange={handleChange} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="end-date">
                                                            End Date
                                                        </span>
                                                        <input 
                                                            type="date" 
                                                            className="form-control" 
                                                            aria-describedby="end-date"
                                                            name="end_date"
                                                            value={formData.end_date}
                                                            onChange={handleChange}  
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="veri-type">
                                                            Verification Type
                                                        </span>
                                                        <select className="form-select" aria-label="Verification Type" 
                                                            aria-describedby="veri-type"
                                                            name="type"
                                                            value={formData.type}
                                                            onChange={handleChange}
                                                        >
                                                            <option selected>--Select Type--</option>
                                                            <option value="">All</option>
                                                            {selectOptions.map((option, i) => (
                                                                <option value={option.value} key={option.id}>{option.caption}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <div>
                                                        <button className="btn btn-danger w-50" type="submit">
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div>
                                            <Table responsive="sm" hover>
                                                <thead>
                                                    <tr>
                                                        <th width="3%">#</th>
                                                        <th>Request ID</th>
                                                        <th>Verification Type</th>
                                                        <th>ID Number</th>
                                                        <th>Date Requested</th>
                                                        <th>Service Fee (₦)</th>
                                                        <th >Status</th>
                                                        {/* <th className="text-center">Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {reportTable ?
                                                        reportTable.map((report, i) => (
                                                            <tr key={report.id} style={{cursor: 'pointer'}} 
                                                                onClick={() => viewDetails(report)}
                                                            >
                                                                <td>{ i + 1 }</td>
                                                                <td>{ report.request_id }</td>
                                                                <td>{ report.verification_types.name }</td>
                                                                <td>{ report.id_number }</td>
                                                                <td>
                                                                    <Moment format='MMM DD, YYYY - hh:mm:a'>
                                                                        { report.created_at }
                                                                    </Moment>    
                                                                </td>
                                                                <td>{ report.cost }</td>
                                                                <td>
                                                                    <span className={`badge ${report.status == 'verified' ? 'bg-success' : 'bg-danger'} px-2 py-2 text-capitalize`}>
                                                                        { report.status }
                                                                    </span>
                                                                </td>
                                                                {/* <td className="dropdown text-center">
                                                                        <a className="dropdown-toggle menu text-dark text-muted" id="actionDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="mdi mdi-cog-outline" style={{fontSize: '20px'}}></i>
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-right preview-list" aria-labelledby="actionDropdown">
                                                                            <a className="dropdown-item custom-drop" 
                                                                                onClick={() => {
                                                                                    setIsEdit(true);
                                                                                    handleShow()
                                                                                }}
                                                                            >
                                                                                <i className="mdi mdi-pencil mr-2 text-success"></i> <span>Edit Info</span>
                                                                            </a>
                                                                            <div className="dropdown-divider"></div>
                                                                            <a className="dropdown-item custom-drop">
                                                                                <i className="mdi mdi-account-cancel-outline mr-2 text-info"></i> <span>Deactivate Account</span>
                                                                            </a>
                                                                            <div className="dropdown-divider"></div>
                                                                            <a className="dropdown-item custom-drop">
                                                                                <i className="mdi mdi-shield-check-outline mr-2 text-warning"></i> <span>Verification Requests</span>
                                                                            </a>
                                                                            <div className="dropdown-divider"></div>
                                                                            <a className="dropdown-item custom-drop">
                                                                                <i className="mdi mdi-account-circle-outline mr-2 text-primary"></i> <span>Profile</span>
                                                                            </a>
                                                                        </div>
                                                                </td> */}
                                                            </tr>
                                                        ))
                                                    :
                                                        <tr>
                                                            <td colSpan="7">
                                                                <div className="alert alert-info text-center">
                                                                    <h6>No record found</h6>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className='d-flex justify-content-end mt-2'>
                                            <div className='d-flex'>
                                            per page
                                            <select className='ml-1'  
                                                name='perPage' 
                                                onChange={(e) => setPerPage(e.target.value)}
                                                value={perPage}
                                            >
                                                <option value='5'>5</option>
                                                <option value='10'>10</option>
                                                <option value='15'>15</option>
                                                <option value='20'>20</option>
                                            </select>
                                            </div>
                                            <Pagination 
                                                onChange={handlePageChange}  
                                                count={pageCount} 
                                                color="primary" 
                                                shape="rounded"
                                                size="small"
                                                page={page}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                    </div>
                    </div>
                </div>
                </div>
            </div>

            {currentReport && <VerificationResult 
                show={show} 
                closeModal={handleClose} 
                data={JSON.parse(currentReport.raw_response)}
                verificationInfo={currentReport.verification_types}
                idNumber={currentReport.id_number}
            />}
        </>
    )
}

export default ReportContent