import InnerFooter from "pages/inner/InnerFooter";
import AdminProfileContent from "./ProfileContent";

const AdminProfile = () => {
    return (
      <div className="inner-page">
        {/* <Navbar /> */}
        {/* <InnerHeader /> */}
        <AdminProfileContent />
        <InnerFooter />
      </div>
    );
  };
  
  export default AdminProfile;