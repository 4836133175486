import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Forminputgroup from "../../components/reusables/Forminputgroup";
import Forminputshort from "../../components/reusables/Forminputshort";
import { ImSpinner3 } from 'react-icons/im'
import { toast } from "react-toastify";
import logo from "../../assets/img/precisionverify/precision.png"
import PasswordChecklist from "react-password-checklist"
import { useSelector, useDispatch } from "react-redux";
import { register, reset } from "../../features/auth/authSlice";

import { userTypes } from "../../data/SelectList";
import SelectAccountType from "../../components/reusables/SelectAccountType";

import PasswordInput from "../../components/reusables/PasswordInput";
import { getUserProfile } from "data/generalService";
import FormTextareaComponent from "components/reusables/FormTextareaComponent";

const Register = () => {
  const [userData, setUserData] = useState({
    first_name:'',
    last_name:'',
    email:'',
    password:'',
    password_confirmation:'',
    company_name: '',
    address: '',
    phone: ''
  })
  const [toggle, setToggle] = useState(false)
  const [terms, setTerms] = useState(true)
  // Destructure userData
  const { first_name, last_name, email, password, password_confirmation, company_name, address, phone } = userData

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const campaign = JSON.parse(localStorage.getItem("campaign"));
  const { user, isError, isSuccess, isLoading, message, validationError} = useSelector(state => state.auth)

  const handleToggele = () =>{
    setToggle(!toggle)
  }

  const userProfile = async () =>{
    const { data } = await getUserProfile()
    localStorage.setItem("profile", JSON.stringify(data.data.user))
  }

  const handleChange = (e) =>{
    setUserData({...userData, [e.target.name]:e.target.value})
  }

  useEffect(()=>{
    // dispaly toast message when error
    if(isError){
      toast.error(message)
    }
    //redirect to dashboard, when successful
    if(isSuccess){
      // userProfile()
      toast.success("Account Created Succesfully")
      navigate("/auth/verify-email");
    }
  },[isError, isSuccess, isLoading, message, user, navigate, dispatch])

  const handleSubmit = (e) =>{
      e.preventDefault()
      // reset auth state
      dispatch(reset())

      // validation check
      if(password !== password_confirmation){
        toast.error("Password do not match")
      }else{
        dispatch(register(userData))
      }
  }

  const handleTerms = () =>{
    setTerms(!terms)
  }


  return (
    <section className="section-sm bg-light-2 reg-bg">
      <div className="container px-4">
        <div className="row gx-4 gx-lg-5">
          <div className="inner-row">
            <div className="auth-form-wrap reg-form-wrap">
            <div className="navbar-brand logo-wrap align-items-center">
              <Link className="navbar-brand" to={`/`}>
                <img className="logo auth-logo" src={logo} alt="precision_verify" />
              </Link>
							</div>

              <div className="auth-form">
                <form
                  autoComplete="off"
                  name="register"
                  onSubmit={handleSubmit}
                >
                  <div className="form-header text-center">
                    <h3 className="form-title">Create an Account </h3>
                  </div>
                  <div className="form-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <Forminputgroup
                          inputLabel={`First Name`}
                          inputName="first_name"
                          inputType={`text`}
                          inputRequired={true}
                          value={first_name}
                          onChange={handleChange}
                          errorMsg={validationError.first_name ? validationError.first_name[0] : ''}
                        />
                      </div>
                      <div className="col-lg-6">
                        <Forminputgroup
                          inputLabel={`Last Name`}
                          inputName="last_name"
                          inputType={`text`}
                          inputRequired={true}
                          value={last_name}
                          onChange={handleChange}
                          errorMsg={validationError.last_name ? validationError.last_name[0] : ''}
                        />
                      </div>
                    </div>

                    <Forminputgroup
                      inputLabel={`Company Name`}
                      inputName="company_name"
                      inputType={`text`}
                      inputRequire={true}
                      value={company_name}
                      onChange={handleChange}
                      errorMsg={validationError.company_name ? validationError.company_name[0] : ''}
                    />

                    <Forminputgroup
                      inputLabel={`Email Address`}
                      inputName="email"
                      inputType={`email`}
                      inputRequire={true}
                      value={email}
                      onChange={handleChange}
                      errorMsg={validationError.email ? validationError.email[0] : ''}
                    />

                    <Forminputgroup
                      inputLabel={`Phone`}
                      inputName="phone"
                      inputType={`phone`}
                      inputRequire={true}
                      value={phone}
                      onChange={handleChange}
                      errorMsg={validationError.phone ? validationError.phone[0] : ''}
                    />


                    <FormTextareaComponent
                      textLabel={`Address`}
                      textName="address"
                      inputRequire={true}
                      value={address}
                      onChange={handleChange}
                    />

                    {/* <SelectAccountType
                      selectName="account_type"
                      value={account_type}
                      onChange={handleChange}
                      itemList={userTypes}
                      selectOption={`---Select Account Type---`}
                    /> */}
                  
                      {/* <Forminputshort
                        inputLabel={`Password`}
                        inputName="password"
                        inputType={toggle ? `text` : `password`}
                        inputRequired={true}
                        value={password}
                        onChange={handleChange}
                      />  */}

                    <PasswordInput
                      inputLabel={`Password`}
                      inputType={toggle ? `text` : `password`}
                      inputName="password"
                      inputRequire={true}
                      value={password}
                      onChange={handleChange}
                      toggle={toggle}
                      handleToggele ={handleToggele}
                      size="col-lg-12"
                      errorMsg={validationError.password ? validationError.password[0] : ''}
                    />
                    {
                      password !== "" ? 
                      <PasswordChecklist
                      rules={["minLength","specialChar","number","capital","match"]}
                      minLength={8}
                      value={password}
                      valueAgain={password_confirmation}
                      onChange={(isValid) => {}}
                      className="password-format"
                    />
                    :
                    <></>
                    }

                    <PasswordInput
                      inputLabel={`Re-enter Password`}
                      inputType={toggle ? `text` : `password`}
                      inputName="password_confirmation"
                      inputRequire={true}
                      value={password_confirmation}
                      onChange={handleChange}
                      toggle={toggle}
                      handleToggele ={handleToggele}
                      size="col-lg-12"
                    />

                      {/* <Forminputshort
                        inputLabel={`Repeat Password`}
                        inputName="password_confirmation"
                        inputType={toggle ? `text` : `password`}
                        inputRequired={true}
                        value={password_confirmation}
                        onChange={handleChange}
                      /> */}
                    
                    {/* <div className="form-group">
                      <input type="checkbox" className="" name="hidePassword" onClick={handleToggele} /><label htmlFor="hidePassword" className="password-check-label">Show Password</label>  
                    </div> */}
                    {/* <div className="form-group password-format">
                       <ul>
                        <li>Must comprise of upper and lower case letter</li>
                        <li>Must contain special character</li>
                        <li>Must contain number</li>
                       </ul>
                    </div> */}

                <div className="form-group tAndC mt-4">
                  <input type="checkbox" name="terms" id="terms" onChange={handleTerms}/>{" "}
                 <span>
                  Completing this registration means you have accepted our <Link to={`/auth/register`}>Privacy Policy </Link> and <Link to={`/auth/register`}>Terms of Use </Link>
                 </span>

                </div>

                    <div className="form-group btn-wrap">
                      <button disabled={terms} className="btn btn-primary btn-shadow btn-round btn-md btn-login">
                      
                        Create Account
                        {isLoading  && <ImSpinner3 className="icon-spin" />}
                      </button>
                    </div>
                  </div>
                  <div className="form-footer text-center">
                    <p className="txt-center">
                      Have an account? {" "}
                      <Link className="inline-link" to={`/auth`}>
                        {"  "}
                        Sign In{" "}
                      </Link>{" "}
                    
                    </p>
                  </div>
                </form>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
