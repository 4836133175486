
const PrivacyComponent = () => {

   

  return (
    <section className="section-md-sm">
    <div className="container px-4">					
        <div className="row gx-4 gx-lg-5 justify-content-centers gv-about-intro">                    
            <div className="col-lg-10 offset-lg-1 about-intro-content animate__animated animate__fadeIn animate__delay-b">
                <div className="gv-accordion">
                    <div className="accordion accordion-flush" id="helpCenterItems">

                   <h3> Privacy Policy for Giving.ng </h3>

<strong>Effective date: August 2020 </strong>

<p>Sterling One Foundation ("us", "we", "our" or “the Foundation”) operates the https://www.giving.ng/ website (the "Service"). </p>

<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. </p> 

<p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.  </p>

<strong>Information Collection and Use </strong>

<p>We collect several different types of information for various purposes to provide and improve our Service to you.</p> 

<strong>Types of Data Collected </strong>

<strong>Personal Data </strong>

<p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to your name(s), contact details, identification, work and residential addresses, gender, positions held, forms submitted, payment details, and other enquiry details which would enhance your Service experience: </p>

<strong>Usage Data </strong>

<p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data. </p>

<p>When you access the Service by or through a mobile device or use the mobile app, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data. </p>

<strong>Details about your banking activities and transactions with us </strong> 

<p>This includes information on any bank accounts you use, debit card numbers, financial history, information you provide to deliver payment initiation services and account information services regarding accounts you hold with other providers. </p>

 
<strong>
Tracking & Cookies Data </strong>

<p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. </p>

<p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyse our Service. </p>

<p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. You can also refuse permissions to read your phone data by the mobile application. However, if you do not accept cookies on your browser or allow permissions on your mobile device, you may not be able to use some portions of our Service. </p>

<strong>Examples of Cookies we use: </strong>
<br />
Session Cookies. We use Session Cookies to operate our Service. 
<br />
Preference Cookies. We use Preference Cookies to remember your preferences and various settings. 
<br />
Security Cookies. We use Security Cookies for security purposes. 
<br />
<strong>Use of Data</strong> 
<br />
We use the collected data for various purposes: 
<br />
<ul>
    <li>To provide and maintain the Service. </li>
    <li>To notify you about changes to our Service </li>
    <li>To allow you to participate in interactive features of our Service when you choose to do so. </li>
    <li>To provide customer care and support </li>
    <li>To provide analysis or valuable information so that we can improve the Service. </li>
    <li>To monitor the usage of the Service</li>
    <li>To detect, prevent and address technical issues. </li>
    <li>To recommend other services we think you may be interested in </li>
</ul>

<strong>Transfer of Data </strong>

<p>Your information, including Personal Data, may only be accessed by employees that have a business reason to do so. We do not share your information with other organizations for marketing purposes.  </p>

<p>If you are located outside Nigeria and choose to provide information to us, please note that we transfer the data, including Personal Data, to Nigeria and process it there. </p>

<p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer. </p>

<p>We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to a third party or a country unless there are adequate controls in place including the security of your data and other personal information. </p>

<strong>Disclosure of Data </strong>

<ul>
    <li>We may disclose your Personal Data in the following circumstances. </li>
    <li>We have your consent to share the information.</li>
    <li>We find that your actions on our web sites violate any part of our Privacy Policy. </li>
    <li>To comply with a legal obligation </li>
    <li>To protect and defend the rights or property of the Foundation. </li>
    <li>To prevent or investigate possible wrongdoing in connection with the Service. </li>
    <li>To protect the personal safety of users of the Service or the public </li>
    <li>To protect against legal liability </li>
   
</ul>
  
<p>For introducing the services of Giving.ng, related entities and our affiliates. </p>

<p>With financial institutions that we have partnered with to process payment on the Platform </p>

<p>To trusted third parties who assist us in conducting our business. We require that these parties agree to process such information based on our instructions and in compliance with this privacy policy and any other appropriate confidentiality and security measures. </p>

<p>We do not sell, trade, or otherwise transfer your personally identifiable information to unaffiliated third parties. This does not include Application Support team and other parties who assist us in operating the Application and providing the Service to you.  We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property, or safety. </p>

<strong>Your choices regarding our use and disclosure of information </strong>

<p>You may exercise the following control over your personal data with us. </p>

<p>Opt-in or opt-out from receiving electronic communications from us: If you no longer want to receive marketing-related emails or mobile text messages from us on a going-forward basis, you may opt-out of receiving these marketing-related emails or push notifications by changing your preferences in your account settings or following the unsubscribe prompts from within the messages themselves.</p>  

<p>Device permissions: Depending on the mobile operating system of a phone, most mobile device platforms have certain types of device data that applications cannot access without the device owner’s permission, and these platforms have different methods for how that permission can be obtained. Your device may notify you the first time we make a request to access certain data on your device. If you do not enable these permissions, you may be unable to access some of our services. We may require you to enable your location, camera, microphone, photo, calendar etc. at the device level. </p>

<strong>Security of Data </strong>

<p>We implement a variety of reasonable security measures to protect the security and integrity of your personal information. We use computer safeguard such as firewalls and data encryption, enforce physical access to our buildings and files and only authorize access to personal information to employees who require it to fulfill their job responsibilities, or our affiliates as may be required in the provision of our services to you. However, as the internet is not a secure medium, and we cannot guarantee that security breaches will not occur. Accordingly, we are not responsible for the matters, which include actions of hackers and other unauthorized third parties that breach our reasonable security procedure. No method of electronic transmission or storage is 100% secure. Therefore, we cannot guarantee absolute security of your Personal Information. You also play a role in protecting your Personal Information. Please safeguard your username and password for your account and do not share them with others. If we receive instructions using your account login information, we will consider that you have authorized the instructions. You agree to notify us immediately of any unauthorized use of your account or any other breach of security. We reserve the right, in our sole discretion, to refuse to provide our Services, terminate accounts on the Service, and to remove or edit content. </p>

<strong>How long we will keep your information. </strong>

<p>We will keep your personal data for as long as we have a relationship with you, i.e. for as long as you use the Platform, or partake in surveys. Once that relationship with you has come to an end, we will not gather or collect any new information about you. Once the relationship is over, we will only retain personal data for a period of time. This period will vary depending on the purpose for which we hold that information and subject to business or legal and regulatory requirements. </p>

 

<strong>Service Providers </strong>

<p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analysing how our Service is used. </p>

<p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose. </p>

<strong>Links to Other Sites </strong>

<p>Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services. </p>

<strong>Information Shared Socially </strong>

<p>Our Service may allow you to connect and share your actions, comments, content, and information publicly or with friends. We are not responsible for maintaining the confidentiality of any information you share publicly or with friends. </p>

<p>Our Service may also allow you to connect with us on, share on, and use third-party websites, applications, and services. Please be mindful of your personal privacy needs and the privacy needs of others, as you choose whom to connect with and what to share and make public. We cannot control the privacy or security of information you choose to make public or share with others. We also do not control the privacy practices of third parties. Please contact those sites and services directly if you want to learn about their privacy practices. </p>

<strong>Children's Privacy </strong>

<p>Our Service does not address anyone under the age of 18 ("Children"). </p>

<p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are under the age of 18, do not subscribe to our Service or submit any information to us. If you are a parent or guardian and you are aware that your Child(ren) has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers. </p>

 

<strong>Changes to This Privacy Policy </strong>

<p>We may update our Privacy Policy from time to time to reflect changes to our business, the App or Services, or applicable laws. We may let you know via email and/or a prominent notice on our Platform and update the "effective date" at the top of this Privacy Policy. Accordingly, you are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. </p>

<p>Your continued use of the Platform after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy. 
</p>
 

<strong>Information Update </strong>

<p>You must notify us of any change in your Personal Information by updating your account profile through our Services. Any changes will affect only future uses of your Personal Information. Subject to applicable law, which might, from time to time, oblige us to store your Personal Information for a certain period of time, we will respect your wishes to correct inaccurate information. Otherwise, we will hold your Personal Information for as long as we believe it will help us achieve our objectives as detailed in this Privacy Policy. </p>

<strong>Your Consent </strong> 

<p>By submitting any personal information (including, without limitation, your financial information and contact details) to us, you consent to the use of your personal information as set out in this Privacy Policy.</p> 

<strong>Contact Us </strong>

<p>If you have any questions about this Privacy Policy, please contact us at info@giving.ng  </p>


                        </div>
						</div>
                    </div>
                </div>
            </div>
        </section>	
  )
}

export default PrivacyComponent