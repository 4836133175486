import { Link } from "react-router-dom";
import logo from "../../assets/img/precisionverify/precision.png";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const {user, admin} = useSelector((state) => state.auth)

  return (
    <>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-lg-block d-none"
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Brand --> */}

        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to={`/`}
        >
          <img
            className="logo sidebar-brand-text mx-3"
            src={logo}
            alt="giving.ng"
          />
        </Link>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/* <!-- Nav Item - Dashboard --> */}
        {user && <li className="nav-item active mt-4">
          <Link className="nav-link" to={`/inner`}>
            <i className="mdi mdi-speedometer mr-2"></i> <span>Dashboard</span>
          </Link>
        </li>}

        {admin && <li className="nav-item active mt-4">
          <Link className="nav-link" to={`/admin`}>
            <i className="mdi mdi-speedometer mr-2"></i> <span>Dashboard</span>
          </Link>
        </li>}

        {user && <li className="nav-item">
          <Link className="nav-link" to={`/inner/profile`}>
            <i className="mdi mdi-account-circle-outline mr-2 text-gray-400"></i>{" "}
            <span>Profile</span>
          </Link>
        </li>}

        {admin && <li className="nav-item">
          <Link className="nav-link" to={`/admin/profile`}>
            <i className="mdi mdi-account-circle-outline mr-2 text-gray-400"></i>{" "}
            <span>Profile</span>
          </Link>
        </li>}

        {user && <li className="nav-item">
          <Link className="nav-link" to={`/inner/verification`}>
            <i className="mdi mdi-shield-check-outline mr-2 text-gray-400"></i>{" "}
            <span>Verification</span>
          </Link>
        </li>}

        {user && <li className="nav-item">
          <Link className="nav-link" to={`/inner/wallet`}>
            <i className="mdi mdi-wallet-bifold-outline mr-2 text-gray-400"></i>{" "}
            <span>Wallet</span>
          </Link>
        </li>}

        {admin && <li className="nav-item">
          <Link className="nav-link" to={`/inner/admin/wallet`}>
            <i className="mdi mdi-wallet-bifold-outline mr-2 text-gray-400"></i>{" "}
            <span>Wallet</span>
          </Link>
        </li>} {/*  Remove */}

        {user && <li className="nav-item">
          <Link className="nav-link" to={`/inner/report`}>
            <i className="mdi mdi-chart-box-outline mr-2 text-gray-400"></i>{" "}
            <span>Report</span>
          </Link>
        </li>}

        {user && <li className="nav-item">
          <Link className="nav-link" to={`/inner/config-setting`}>
            <i className="mdi mdi-chart-box-outline mr-2 text-gray-400"></i>{" "}
            <span>Settings</span>
          </Link>
        </li>}

        {admin && <li className="nav-item">
          <Link className="nav-link" to={`/inner/users`}>
            <i className="mdi mdi-account-group-outline mr-2 text-gray-400"></i>{" "}
            <span>Users</span>
          </Link>
        </li>}

        {admin && <li className="nav-item">
          <Link className="nav-link" to={`/admin/settings`}>
            <i className="mdi mdi-cog-transfer-outline mr-2 text-gray-400"></i>{" "}
            <span>Settings</span>
          </Link>
        </li>}

        {/* <div className="sidebar-heading txt-upper txt-left txt-grey">
          Platform
        </div> */}
      </ul>

      {/* Mobile sidebar (using bootstrap 5 off canvas)*/}
      <div
        className="offcanvas d-block d-lg-none offcanvas-start bg-gradient-primary sidebar-mobile sidebar-dark accordion"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        {/* <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div> */}
        <div className="">
          <ul className="navbar-nav">
            {/* <!-- Sidebar - Brand --> */}

            <Link
              className="sidebar-brand d-flex align-items-center justify-content-center"
              to={`/`}
            >
              <img
                className="logo sidebar-brand-text img-fluid"
                src={logo}
                alt="precisionverify"
              />
            </Link>

            {/* <!-- Divider --> */}
            <hr className="sidebar-divider my-0" />

            {/* <!-- Nav Item - Dashboard --> */}
            {user && <li className="nav-item active mt-4">
              <Link className="nav-link" to={`/inner`}>
                <i className="mdi mdi-speedometer mr-2"></i>{" "}
                <span>Dashboard</span>
              </Link>
            </li>}

            {admin && <li className="nav-item active mt-4">
              <Link className="nav-link" to={`/admin`}>
                <i className="mdi mdi-speedometer mr-2"></i>{" "}
                <span>Dashboard</span>
              </Link>
            </li>}

            {user && <li className="nav-item">
              <Link className="nav-link" to={`/inner/profile`}>
                <i className="mdi mdi-account-circle-outline mr-2 text-gray-400"></i>{" "}
                <span>Profile</span>
              </Link>
            </li>}

            {admin && <li className="nav-item">
              <Link className="nav-link" to={`/admin/profile`}>
                <i className="mdi mdi-account-circle-outline mr-2 text-gray-400"></i>{" "}
                <span>Profile</span>
              </Link>
            </li>}

            {user && <li className="nav-item">
              <Link className="nav-link" to={`/inner/verification`}>
                <i className="mdi mdi-shield-check-outline mr-2 text-gray-400"></i>{" "}
                <span>Verification</span>
              </Link>
            </li>}

            {user && <li className="nav-item">
              <Link className="nav-link" to={`/inner/wallet`}>
                <i className="mdi mdi-wallet-bifold-outline mr-2 text-gray-400"></i>{" "}
                <span>Wallet</span>
              </Link>
            </li>}

            {admin && <li className="nav-item">
              <Link className="nav-link" to={`/inner/admin/wallet`}>
                <i className="mdi mdi-wallet-bifold-outline mr-2 text-gray-400"></i>{" "}
                <span>Wallet</span>
              </Link>
            </li>} {/*  Remove */}

            {user && <li className="nav-item">
              <Link className="nav-link" to={`/inner/report`}>
                <i className="mdi mdi-chart-box-outline mr-2 text-gray-400"></i>{" "}
                <span>Report</span>
              </Link>
            </li>}

            {admin && <li className="nav-item">
              <Link className="nav-link" to={`/inner/users`}>
                <i className="mdi mdi-account-group-outline mr-2 text-gray-400"></i>{" "}
                <span>Users</span>
              </Link>
            </li>}

            {admin && <li className="nav-item">
              <Link className="nav-link" to={`/admin/settings`}>
                <i className="mdi mdi-cog-transfer-outline mr-2 text-gray-400"></i>{" "}
                <span>Settings</span>
              </Link>
            </li>}

            {/* <div className="sidebar-heading txt-upper txt-left txt-grey">
          Platform
        </div> */}
          </ul>
        </div>
      </div>
      {/* Ends here */}
    </>
  );
};

export default Sidebar;
