import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import businessService from './businessService'


const initialState = {
    respData: {},
    businesses:null,
    isError: false,
    isSuccess:false,
    isFetchSuccess: false,
    isDelete:false,
    isDeleting:false,
    isLoading:false,
    message:''
}

//@function: create individual record
export const createBusiness = createAsyncThunk('business/create', async (inputData, thunkAPI) =>{
    // const token  = thunkAPI.getState().auth
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await businessService.create(inputData, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getBusiness = createAsyncThunk('business/fetch', async (_, thunkAPI) =>{
    // const token  = thunkAPI.getState().auth
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await businessService.getRecord(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete bussiness
export const deleteBusiness = createAsyncThunk('business/delete', async (businessId, thunkAPI) =>{
    // const token  = thunkAPI.getState().auth
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await businessService.deleteRecord(businessId,data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const businessSlice = createSlice({
    name:'business',
    initialState,
    reducers:{
        reset:(state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.isFetchSuccess = false
            state.isDelete = false
            state.isDeleting = false
            state.message = ""
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createBusiness.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(createBusiness.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.respData = action.payload
            })
            .addCase(createBusiness.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload
              
            })
            .addCase(getBusiness.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getBusiness.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isFetchSuccess = true
                state.businesses = action.payload
            })
            .addCase(getBusiness.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload
              
            })
            .addCase(deleteBusiness.pending, (state)=>{
                state.isDeleting = true
            })
            .addCase(deleteBusiness.fulfilled, (state, action)=>{
                state.isDeleting = false
                state.isDelete = true
            })
            .addCase(deleteBusiness.rejected, (state, action) =>{
                state.isError = true
                state.isDeleting = false
                state.message = action.payload
              
            })
    }
})

export const { reset} = businessSlice.actions
export default businessSlice.reducer