import { Link } from "react-router-dom";
import logo from "../assets/img/giving_logo.png";

const Navbar = () => {
	const resp = JSON.parse(localStorage.getItem("user"));
	
  return (

    <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
		
        <div className="container">
				<div className="navbar-brand logo-wrap align-items-center">
					<Link className="navbar-brand" to={`/`}><img className="logo" src={logo} alt="giving.ng" /></Link>
				</div>
				<div className="navbar-collapse navbar-left align-items-center" id="mainNav1">					
                    <ul className="navbar-nav my-2 my-lg-0">                        
						<li className="nav-item nav-items dropdown">
							<a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Our Solutions 
								<i className="bi bi-chevron-down"></i> 
							</a>                        
							<ul className="dropdown-menu">
								{/* <li className="dropdown-item"><Link className="nav-link" to={`/offerings`}>Overview</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/ekosha`}>EKOSHA</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/giving-impact`}>Giving Impact</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/giving-group`}>Giving Group</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/finishing-grant`}>Finishing Grant</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/volunteer-network`}>Volunteer Network</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/givify`}>Givify</Link></li> */}

<li className="dropdown-item"><Link className="nav-link" to={`/givify`}>Giving+</Link></li>
						
								<li className="dropdown-item"><Link className="nav-link" to={`/giving-impact`}>Giving Impact</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/giving-group`}>Giving Group</Link></li>
								
								<li className="dropdown-item"><Link className="nav-link" to={`/volunteer-network`}>Volunteer Network</Link></li>
						
							</ul>
						</li>
            <li className="nav-item nav-items"><Link className="nav-link" to={`/about`}>About Giving</Link></li>
            <li className="nav-item nav-items"><Link className="nav-link" to={`/crowdfunding`}>Campaigns</Link></li>
			{/* <li className="nav-item nav-items"><Link className="nav-link" to={`/auth`}>Sign in</Link></li> */}
            <li className="nav-item nav-items"><Link className="nav-link" to={`/start`}>Start a Fundraiser</Link></li>
			<li className="nav-item nav-items dropdown">
							<a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">More  
								<i className="bi bi-chevron-down"></i> 
							</a>                        
							<ul className="dropdown-menu">
								<li className="dropdown-item"><Link className="nav-link" to={`/how-it-works`}>How it works</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/careers`}>Careers</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/help-center`}>FAQ</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/privacy-policy`}>Privacy Policy</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/terms-of-use`}>Terms of Use</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/auth`}>Sign In</Link></li>
								
							</ul>
						</li>
						{
							resp !== null ? 
							// resp.data.user !== null ?
							<li className="nav-item nav-items"><Link className="nav-link" to={`/inner`}>Dashboard</Link></li>
							:
							<></>
						}
          
						

						<li className="nav-item nav-items search-toggle"><Link className="nav-link" to={`#`}><i className="bi bi-search"></i> </Link></li>
						<li className="nav-item nav-items nav-btn cta-btn"><Link className="nav-link" to={`/donate`}>Give Network</Link></li>
							
                    </ul>
                </div>
				<button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#mobileNav" aria-controls="mobileNav" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
            </div>

            
			<div className="container mobile-nav-container">
				<div className="mobile-nav collapse" id="mobileNav">
					<ul className="navbar-nav ms-auto my-2 my-lg-0">
						<li className="nav-item"><Link className="nav-link" to={`/`}>Home</Link></li>
						<li className="nav-item dropdown">
							<a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Our Offering 
								<i className="bi bi-chevron-down"></i> 
							</a>                        
							<ul className="dropdown-menu">
              <li className="dropdown-item"><Link className="nav-link" to={`/offerings`}>Overview</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/ekosha`}>EKOSHA</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/giving-impact`}>Giving Impact</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/giving-group`}>Giving Group</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/finishing-grant`}>Finishing Grant</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/volunteer-network`}>Volunteer Network</Link></li>
								<li className="dropdown-item"><Link className="nav-link" to={`/givify`}>Givify</Link></li>


								
							</ul>
						</li>
						<li className="nav-item"><Link className="nav-link" to={`/about`}>About Giving</Link></li>
						<li className="nav-item"><Link className="nav-link" to={`#`}>How It Works</Link></li>
						<li className="nav-item"><Link className="nav-link" to={`/auth`}>Sign In</Link></li>
					</ul>
				</div>
			</div>
        </nav>



   
  );
};

export default Navbar;
