import {useState, useEffect} from 'react'
import {Modal, Button, Breadcrumb, Spinner} from 'react-bootstrap'

import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import Sidebar from 'pages/inner/Sidebar';
import Topbar from 'pages/inner/Topbar';
import { getAdminAnalytics, getAdminAnalyticsByService, getAdminChartAnalytics } from 'features/user/Dashboard/dashboardService';
  
  ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
  );
  
  
  const AdminDashboardContent = () => {
	const [analytics, setAnalytics] = useState([])
	const [chartAnalytics, setChartAnalytics] = useState([])
	const [serviceData, setServiceData] = useState([])
	const [analyticsLoading, setAnalyticsLoading] = useState(false)
	const [chartLoading, setChartLoading] = useState(false)
	const [serviceDataLoading, setServiceDataLoading] = useState(false)

	const fetchAnalytics = async() => {
		setAnalyticsLoading(true)
		try {
			const resp = await getAdminAnalytics()
            
			if(resp.success){
				// toast.success(resp.message)
				setAnalytics(resp.data.verification_analytics)
				setAnalyticsLoading(false)
			}
		} catch (error) {
			setAnalyticsLoading(false)
        	toast.error(error)
		}
	}

	const fetchAnalyticsByService = async() => {
		setServiceDataLoading(true)
		try {
			const resp = await getAdminAnalyticsByService()
            
			if(resp.status == 'success'){
				// toast.success(resp.message)
				setServiceData(resp.analysis)
				setServiceDataLoading(false)
			}
		} catch (error) {
			setServiceDataLoading(false)
        	toast.error(error)
		}
	}

	const fetchAnalyticsChart = async() => {
		setChartLoading(true)
		try {
			const resp = await getAdminChartAnalytics()
			if(resp.status == 'success'){
				// toast.success(resp.message)
				setChartAnalytics(resp.chart_analysis)
				setChartLoading(false)
			}
		} catch (error) {
			setChartLoading(false)
        	toast.error(error)
		}
	}

	useEffect(() =>{
		fetchAnalytics()
		fetchAnalyticsByService()
		fetchAnalyticsChart()
	},[])

	
	  
	  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 
			  'July', 'August', 'September', 'October', 'November', 'December'];

		const colors = ['rgba(255, 99, 132, 0.4)', 'rgba(75, 192, 192, 0.4)', 'rgba(255, 206, 86, 0.4)',
		'rgba(153, 102, 255, 0.4)', 'rgba(53, 162, 235, 0.4)', 'rgba(15, 200, 10, 0.4)', 'rgba(200, 17, 10, 0.4)',
		'rgba(121, 85, 72, 0.4)', 'rgba(172, 64, 188, 0.4)', 'rgba(53, 162, 235, 0.4)']
	  
	  let chartData = {
		labels,
		datasets: chartAnalytics && chartAnalytics.map((chrt, i) => ({
		  label: chrt.name,
		  data: labels.map(lb => (
			  chrt.details[lb]
		  )),
		  backgroundColor: colors[i] 
		})),
	  };
	  const options = {
		plugins: {
		  title: {
			display: false,
			text: 'Chart.js Bar Chart - Stacked',
		  },
		},
		responsive: true,
		scales: {
		  x: {
			stacked: true,
		  },
		  y: {
			stacked: true,
		  },
		},
		legend: {
			labels: {
				fontSize: 18
			}
		}
	  };
  return (
	<>
    <div className="inner-page-wrapper">
			
				{/* Sidebar */}
                <Sidebar />
			<div id="content-wrapper" className="content-wrapper d-flex flex-column">

				{/* Main Content  */}
				<div id="content">

					 {/* Topbar */}

				<Topbar />

					 {/* Begin Page Content  */}
					<div className="container-fluid">

						{/* Page Heading  */}
						<div className="d-sm-flex align-items-center justify-content-between mb-5">
							<h1 className="h3 mb-0 text-gray-800 font-weight-bold">Dashboard</h1>
							<Breadcrumb>
								{/* <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
								<Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
									Library
								</Breadcrumb.Item> */}
								<Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
							</Breadcrumb>
							{/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
								<i className="bi bi-cloud-download"></i> Generate Report</a> */}
						</div>

						{/* Content Row  */}
						<div className='px-3'>
							{analyticsLoading || chartLoading || serviceDataLoading ?
									<div className="d-flex justify-content-center align-items-center">
										<Spinner animation="grow" variant="primary"/>
										<Spinner animation="grow" variant="danger"/>
										<Spinner animation="grow" variant="warning"/>
										<Spinner animation="grow" variant="info"/>
									</div>
							:
								<div className="row">
									<div className="col-lg-3 mb-3">
										<div className='precision-verify-card bg-dark p-4 shadow-lg rounded-0'>
											<div className="small-box">
												<div className="d-flex inner">
													<div>
														<h3 className='text-warning'>
															{analytics.users}
														</h3>
														<p className='text-light'>Total Users</p>
													</div>
													<span className="b-avatar badge-light-warning rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
														<span className="b-avatar-custom">
															<i className='mdi mdi-account-group' style={{fontSize: '24px'}}></i>
														</span>
													</span>
												</div>
											</div>
										</div>

									</div>
									<div className="col-lg-3 mb-3">
										<div className='precision-verify-card p-4 shadow-lg rounded-0'>
											<div className="small-box">
												<div className="d-flex inner">
													<div>
														<h3 className='text-success'>{analytics.verification_this_month}</h3>
														<p>Verifications this month</p>
													</div>
													<span className="b-avatar badge-light-success rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
														<span className="b-avatar-custom">
															<i className='mdi mdi-shield-check' style={{fontSize: '24px'}}></i>
														</span>
													</span>
												</div>
											</div>
										</div>

									</div>
									<div className="col-lg-3 mb-3">
										<div className='precision-verify-card p-4 shadow-lg rounded-0'>
											<div className="small-box">
												<div className="d-flex inner">
													<div>
														<h3 className='text-danger'>{analytics.verification_this_week}</h3>
														<p>Verifications this week</p>
													</div>
													<span className="b-avatar badge-light-danger rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
														<span className="b-avatar-custom">
															<i className='mdi mdi-shield-check' style={{fontSize: '24px'}}></i>
														</span>
													</span>
												</div>
											</div>
										</div>

									</div>
									<div className="col-lg-3 mb-3">
										<div className='precision-verify-card p-4 shadow-lg rounded-0'>
											<div className="small-box">
												<div className="d-flex inner">
													<div>
														<h3 className='text-info'>{analytics.verification_today}</h3>
														<p>Verifications today</p>
													</div>
													<span className="b-avatar badge-light-info rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
														<span className="b-avatar-custom">
															<i className='mdi mdi-shield-check' style={{fontSize: '24px'}}></i>
														</span>
													</span>
												</div>
											</div>
										</div>

									</div>
									<div className="col-lg-6 mb-3 mt-lg-4">
										<div className='precision-verify-card shadow-md'>
											<h4 className='precision-card-header'>Total API calls made for Real Time services</h4>
											<Bar options={options} data={chartData} className='custom-height'/>
										</div>
									</div>
									<div className="col-lg-6 mb-3 mt-lg-4">
										<div className='precision-verify-card shadow-md'>
											<h4 className='precision-card-header'>Verifications</h4>
											<div className="table-responsive custom-height">
												<table className='table table-responsive-sm table-hover'>
													<thead>
														<tr className='bg-grey'>
															<th>Category</th>
															<th>Requested</th>
															<th>Successful</th>
															<th>Failed</th>
															<th>Mismatched</th>
														</tr>
													</thead>
													<tbody>
														{serviceData.length ?
															serviceData.map(svc_data => (
																<tr key={svc_data.id}>
																	<td>{svc_data.name}</td>
																	<td>{svc_data.details.total_verifications ?? 0}</td>
																	<td>{svc_data.details.total_success ?? 0}</td>
																	<td>{svc_data.details.total_failed ?? 0}</td>
																	<td>{svc_data.details.total_mismatch ?? 0}</td>
																</tr>
															))
														:
															<tr>
																<td colSpan="5">
																	<div className="alert alert-info text-center">
																		<h6>No record found</h6>
																	</div>
																</td>
															</tr>
														}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
  )
}

export default AdminDashboardContent