import { Breadcrumb, Button, Modal } from "react-bootstrap"
import './Profile.css'
import Forminputgroup from "components/reusables/Forminputgroup"
import FormTextareaComponent from "components/reusables/FormTextareaComponent"
import { useEffect, useState } from "react"
import Forminputshort from "components/reusables/Forminputshort"
import PasswordInput from "components/reusables/PasswordInput"
import PasswordChecklist from "react-password-checklist"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify";
import { ImSpinner3 } from "react-icons/im"
import { reset, resetPassword, updateProfile } from "features/admin/profile/profileSlice"
import Sidebar from "pages/inner/Sidebar"
import Topbar from "pages/inner/Topbar"

const AdminProfileContent = () => {
    const dispatch = useDispatch()
    
    const  { profile:admin, isError, isSuccess, isPasswordSuccess, isLoading, message, validationError } = useSelector(state => state.adminProfile);

    const firstName = admin?.data.admin.first_name;
    const lastName = admin?.data.admin.last_name;
    const adminEmail = admin?.data.admin.email
    const adminAddress = admin?.data.admin.address
    const companyName = admin?.data.admin.company_name

    //  user form data
    const [formData, setFormData] = useState({
        first_name: firstName ? firstName : '',
        last_name: lastName ? lastName : '',
        email: adminEmail ? adminEmail : '',
        current_password: '',
        new_password:'',
        new_password_confirmation:'',
        company_name: companyName ? companyName : '',
        address: adminAddress ? adminAddress : '',
    })

    //  Modal states
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [toggle, setToggle] = useState(false)

    // Destructure formData
    const { first_name, last_name, email, current_password, new_password, 
        new_password_confirmation, company_name, 
        address } = formData

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleToggele = () =>{
        setToggle(!toggle)
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        // reset profile state
        dispatch(reset())
        dispatch(updateProfile(formData))
    }

    const handlePasswordChange = (e) =>{
        e.preventDefault()
        // reset profile state
        dispatch(reset())
        dispatch(resetPassword(formData))
    }

    useEffect(()=>{
        // dispaly toast message when error
        if(isError){
          toast.error(message)
        }
        
        if(isSuccess){
          toast.success(message)
        }

        if(isPasswordSuccess){
            toast.success("Password changed succesfully")
            handleClose()
        }
    },[isError, isSuccess, isPasswordSuccess, isLoading, message, admin])

    return (
        <>
            <div className="inner-page-wrapper">
                {/* Sidebar */}
                <Sidebar />
                <div id="content-wrapper" className="content-wrapper d-flex flex-column">
                    {/* Main Content  */}
                    <div id="content">
                        {/* Topbar */}
                        <Topbar />

                        {/* Begin Page Content  */}
                        <div className="container-fluid">
                            {/* Page Heading  */}
                            <div className="d-sm-flex align-items-center justify-content-between mb-5">
                                <h1 className="h3 mb-0 text-gray-800 font-weight-bold">
                                    Profile
                                </h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            {/* Content Row  */}
                            <div className="pb-5">
                                <div className="row">
                                    <div className="col-lg-4 mb-4">
                                        <div className="profile-wrapper">
                                            <div className="profile-content text-center">
                                                <img 
                                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyudBxqf1sdD2e3L4nI3nqsMt1_tceOyuZ7A&usqp=CAU" 
                                                    alt="profile-img" 
                                                    className="img-fluid"
                                                />
                                                <h5>{lastName} {firstName}</h5>
                                                <p>{adminEmail}</p>
                                                <button 
                                                    className="btn btn-outline-warning mt-2 mx-auto px-3"
                                                    style={{fontSize: '13px'}} onClick={handleShow}
                                                >
                                                    Change Password
                                                </button>
                                            </div>  
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="precision-verify-card">
                                            <h4 className="precision-card-header">Basic Information</h4>
                                            <div className="row">
                                                <form onSubmit={handleSubmit}>
                                                    {/* <div className="form-group row">
                                                        <Forminputshort
                                                            inputLabel={`First Name`}
                                                            inputName="first_name"
                                                            inputType={`text`}
                                                            inputRequired={false}
                                                            value={first_name}
                                                            onChange={handleChange}
                                                            errorMsg={validationError.first_name ? validationError.first_name[0] : ''}
                                                        />
                                                        <Forminputshort
                                                            inputLabel={`Last Name`}
                                                            inputName="last_name"
                                                            inputType={`text`}
                                                            inputRequired={false}
                                                            value={last_name}
                                                            onChange={handleChange}
                                                            errorMsg={validationError.last_name ? validationError.last_name[0] : ''}
                                                        />
                                                    </div> */}
                                                    <Forminputgroup
                                                        inputLabel={`First Name`}
                                                        inputName="first_name"
                                                        inputType={`text`}
                                                        inputRequired={false}
                                                        value={first_name}
                                                        onChange={handleChange}
                                                        errorMsg={validationError.first_name ? validationError.first_name[0] : ''}
                                                    />
                                                    <Forminputgroup
                                                        inputLabel={`Last Name`}
                                                        inputName="last_name"
                                                        inputType={`text`}
                                                        inputRequired={false}
                                                        value={last_name}
                                                        onChange={handleChange}
                                                        errorMsg={validationError.last_name ? validationError.last_name[0] : ''}
                                                    />

                                                    {/* <Forminputgroup
                                                        inputLabel={`Company Name`}
                                                        inputName="company_name"
                                                        inputType={`text`}
                                                        inputRequire={false}
                                                        value={company_name}
                                                        onChange={handleChange}
                                                        errorMsg={validationError.company_name ? validationError.company_name[0] : ''}
                                                    /> */}

                                                    <Forminputgroup
                                                        inputLabel={`Email Address`}
                                                        inputName="email"
                                                        inputType={`email`}
                                                        inputRequire={false}
                                                        value={email}
                                                        onChange={handleChange}
                                                        errorMsg={validationError.email ? validationError.email[0] : ''}
                                                    />

                                                    {/* <FormTextareaComponent
                                                        textLabel={`Address`}
                                                        textName="address"
                                                        inputRequire={false}
                                                        value={address}
                                                        onChange={handleChange}
                                                    /> */}

                                                    <div className="d-flex justify-content-end my-4">
                                                        <button
                                                            className="btn btn-secondary"
                                                            type="button"
                                                        >
                                                            cancel
                                                        </button>
                                                        <button
                                                            className="btn btn-danger px-4"
                                                            type="submit"
                                                        >
                                                            update
                                                            {isLoading  && <ImSpinner3 className="icon-spin" />}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Change password modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="prompt-title font-weight-bold">
                        Change Password
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handlePasswordChange}>
                    <Modal.Body>
                        <div className="form-body pb-3">
                            <PasswordInput
                                inputLabel={`Old Password`}
                                inputType={toggle ? `text` : `password`}
                                inputName="current_password"
                                inputRequire={true}
                                value={current_password}
                                onChange={handleChange}
                                toggle={toggle}
                                handleToggele ={handleToggele}
                                size="col-lg-12"
                                errorMsg={validationError.current_password ? validationError.current_password[0] : ''}
                            />
                            <PasswordInput
                                inputLabel={`New Password`}
                                inputType={toggle ? `text` : `password`}
                                inputName="new_password"
                                inputRequire={true}
                                value={new_password}
                                onChange={handleChange}
                                toggle={toggle}
                                handleToggele ={handleToggele}
                                size="col-lg-12"
                                errorMsg={validationError.new_password ? validationError.new_password[0] : ''}
                            />
                            {
                                new_password !== "" ? 
                                    <PasswordChecklist
                                        rules={["minLength","specialChar","number","capital","match"]}
                                        minLength={8}
                                        value={new_password}
                                        valueAgain={new_password_confirmation}
                                        onChange={(isValid) => {}}
                                        className="password-format"
                                    />
                                :
                                    <></>
                            }
                            <PasswordInput
                                inputLabel={`Re-enter Password`}
                                inputType={toggle ? `text` : `password`}
                                inputName="new_password_confirmation"
                                inputRequire={true}
                                value={new_password_confirmation}
                                onChange={handleChange}
                                toggle={toggle}
                                handleToggele ={handleToggele}
                                size="col-lg-12"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" type="button" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="success" type="submit">
                            Update
                            {isLoading  && <ImSpinner3 className="icon-spin" />}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default AdminProfileContent