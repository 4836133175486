import { useEffect, useState } from "react";
import Sidebar from "../../Sidebar";
import Topbar from "../../Topbar";
import { Breadcrumb, Spinner, Table } from "react-bootstrap";
import Forminputshorter from "components/reusables/Forminputshorter";
import { Pagination } from '@mui/material';
import { getAllUserWallet } from "features/admin/wallet/adminWalletService";
import { toast } from "react-toastify";
import AdminWalletTopUp from "components/PrecisionVerify/AdminWalletTopup";

const AdminWalletContent = () => {
    //  user form data
    const [formData, setFormData] = useState({
        searchFilter: ''
    })

    const [allWallets, setAllWallets] = useState(null)
    const [loading, setLoading] = useState(false)
    const [currentWallet, setCurrentWallet] = useState(null)

    //  Modal states
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //  state for pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(1);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //  pagination function
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchAllUserWallet = async(page, perpage, data) => {
        try {
            setLoading(true)
            const resp = await getAllUserWallet(page, perpage, data)
            
            if(resp.success){
                toast.success(resp.message)
                setAllWallets(resp.data.user_wallet.data.length ? resp.data.user_wallet.data : null)
                const pageCount = Math.ceil(resp.data.user_wallet.total / resp.data.user_wallet.per_page);
                setPageCount(pageCount)
                setLoading(false)
            }
        
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setLoading(false)
        }
    }

    const filterWallet = (e) => {
        e.preventDefault()
        fetchAllUserWallet(page, perPage, formData)
    }

    const showTopup = async(wallet) => {
        await setCurrentWallet(wallet)
        handleShow()
    }

    useEffect(() => {
        fetchAllUserWallet(page, perPage, formData)
    }, [perPage, page])

    return (
        <>
            <div className="inner-page-wrapper">
                {/* Sidebar */}
                <Sidebar />
                <div
                id="content-wrapper"
                className="content-wrapper d-flex flex-column"
                >
                {/* Main Content  */}
                <div id="content">
                    {/* Topbar */}

                    <Topbar />

                    {/* Begin Page Content  */}
                    <div className="container-fluid">
                        {/* Page Heading  */}
                        <div className="d-sm-flex align-items-center justify-content-between mb-5">
                            <h1 className="h3 mb-0 text-gray-800 font-weight-bold">
                                Users Wallet
                            </h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item active>Users</Breadcrumb.Item>
                            </Breadcrumb>
                            {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                                        <i className="bi bi-cloud-download"></i> Generate Report</a> */}
                        </div>

                        {/* Content Row  */}
                        <div className="pb-5">
                            {loading ?
                                <div className="d-flex justify-content-center align-items-center">
                                    <Spinner animation="grow" variant="primary"/>
                                    <Spinner animation="grow" variant="danger"/>
                                    <Spinner animation="grow" variant="warning"/>
                                    <Spinner animation="grow" variant="info"/>
                                </div>
                            :
                                <div className="precision-verify-card shadow-lg">
                                    <form onSubmit={filterWallet}>
                                        <div className="form-group row mt-4 ana ">
                                            <div className="col-lg-4 mb-3">
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    aria-describedby="start-date"
                                                    name="searchFilter"
                                                    value={formData.searchFilter}
                                                    onChange={handleChange} 
                                                    placeholder="Search user..."
                                                />
                                            </div>
                                            <div className="col-lg-3 mb-3">
                                                <div>
                                                    <button className="btn btn-danger w-50" type="submit">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="mt-2">
                                        <Table responsive="sm" hover>
                                            <thead>
                                                <tr>
                                                    <th width="25%">User Info</th>
                                                    <th>Email</th>
                                                    <th>Company Name</th>
                                                    <th >Wallet Balance (₦)</th>
                                                    {/* <th >Status</th> */}
                                                    <th className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allWallets ?
                                                    allWallets.map((wallet) => (
                                                        <tr key={wallet.id} style={{cursor: 'pointer'}}>
                                                            <td className="d-flex">
                                                                <span>
                                                                    <img 
                                                                        src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpaTTohNZ3rp44K7dWEeDsrLtFx-n23eZPjZLvf7Sx_CoOvLAgLVmKTrV4nbrH1xugAqc&usqp=CAU'} width='40'
                                                                        className='rounded-circle' 
                                                                        alt='img' 
                                                                    />
                                                                </span>
                                                                <span className='ml-3 align-items-center'>
                                                                    <p className="m-0 font-weight-bold">{wallet.user.last_name} {wallet.user.first_name}</p>
                                                                    <p className="text-muted m-0" style={{fontSize: '12px', lineHeight: 1.8}}>
                                                                        {wallet.user.company_name ? wallet.user.company_name : '-'}
                                                                    </p>
                                                                </span>
                                                            </td>
                                                            <td>{wallet.user.email}</td>
                                                            <td>{wallet.user.company_name ? wallet.user.company_name : '-'}</td>
                                                            <td>{Number(wallet.total).toLocaleString()}</td>
                                                            {/* <td><span className="badge bg-success px-2 py-2">Active</span></td> */}
                                                            <td className="dropdown text-center">
                                                                    <a className="dropdown-toggle menu text-dark text-muted" id="actionDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="mdi mdi-cog-outline" style={{fontSize: '20px'}}></i>
                                                                    </a>
                                                                    <div className="dropdown-menu dropdown-menu-right preview-list" aria-labelledby="actionDropdown">
                                                                        <a className="dropdown-item custom-drop" 
                                                                            onClick={() => {
                                                                                showTopup(wallet)
                                                                            }}
                                                                        >
                                                                            <i className="mdi mdi-plus mr-2 text-info"></i> <span>Top Up</span>
                                                                        </a>
                                                                    </div>
                                                                </td>
                                                        </tr>
                                                    ))
                                                :
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div className="alert alert-info text-center">
                                                                <h6>No record found</h6>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className='d-flex justify-content-end mt-2'>
                                        <div className='d-flex'>
                                        per page
                                        <select className='ml-1'  
                                            name='perPage' 
                                            onChange={(e) => setPerPage(e.target.value)}
                                            value={perPage}
                                        >
                                            <option value='5'>5</option>
                                            <option value='10'>10</option>
                                            <option value='15'>15</option>
                                            <option value='20'>20</option>
                                        </select>
                                        </div>
                                        <Pagination 
                                            onChange={handlePageChange}  
                                            count={pageCount} 
                                            color="primary" 
                                            shape="rounded"
                                            size="small"
                                            page={page}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                </div>
            </div>

            {/* top up modal component import */}
            {currentWallet && <AdminWalletTopUp 
                show={show} 
                closeModal={handleClose} 
                currentUser={currentWallet.user}
                walletId={currentWallet.id}
            />}
        </>
    )
}

export default AdminWalletContent