import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from './grantService'


const initialState = {
    grant: null,
    grants:null,
    isErrorG: false,
    isSuccessG:false,
    isFetchSuccessG: false,
    isDeleteG:false,
    isDeletingG:false,
    isLoadingG:false,
    messageG:''
}

//@function: create director record
export const createGrant = createAsyncThunk('grant/create', async (inputData, thunkAPI) =>{
  
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await service.createGrant(inputData, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getGrants = createAsyncThunk('grants/fetch', async (_,thunkAPI) =>{
    try {
        
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await service.getGrants(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getGrant = createAsyncThunk('grant/fetch', async (id, thunkAPI) =>{
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await service.getGrant(id, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete bussiness
export const deleteGrant = createAsyncThunk('grant/delete', async (id, thunkAPI) =>{
    
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await service.deleteGrant(id,data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const grantSlice = createSlice({
    name:'grant',
    initialState,
    reducers:{
        reset:(state) => {
            state.isErrorG = false
            state.isLoadingG = false
            state.isSuccessG = false
            state.isFetchSuccessG = false
            state.isDeleteG = false
            state.isDeletingG = false
            state.messageG = ""
            state.grant = null
            state.grants = null
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createGrant.pending, (state)=>{
                state.isLoadingG = true
            })
            .addCase(createGrant.fulfilled, (state, action)=>{
                state.isLoadingG = false
                state.isSuccessG = true
                state.grant = action.payload
            })
            .addCase(createGrant.rejected, (state, action) =>{
                state.isErrorG = true
                state.isLoadingG = false
                state.messageG = action.payload
              
            })
            .addCase(getGrants.pending, (state)=>{
                state.isLoadingG = true
            })
            .addCase(getGrants.fulfilled, (state, action)=>{
                state.isLoadingG = false
                state.isFetchSuccessG = true
                state.grants = action.payload
            })
            .addCase(getGrants.rejected, (state, action) =>{
                state.isErrorG = true
                state.isLoadingG = false
                state.messageG = action.payload
              
            })
            .addCase(deleteGrant.pending, (state)=>{
                state.isDeletingG = true
            })
            .addCase(deleteGrant.fulfilled, (state, action)=>{
                state.isDeletingG = false
                state.isDeleteG = true
            })
            .addCase(deleteGrant.rejected, (state, action) =>{
                state.isErrorG = true
                state.isDeletingG = false
                state.messageG = action.payload
            })
            .addCase(getGrant.pending, (state)=>{
                state.isLoadingG = true
            })
            .addCase(getGrant.fulfilled, (state, action)=>{
                state.isLoadingG = false
                state.isFetchSuccessG = true
                state.grant = action.payload
            })
            .addCase(getGrant.rejected, (state, action) =>{
                state.isErrorG = true
                state.isLoadingG = false
                state.messageG = action.payload
              
            })
    }
})

export const { reset} = grantSlice.actions
export default grantSlice.reducer