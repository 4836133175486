import React from "react";

const InnerFooter = () => {
  return (
    <footer className="section-xsm bg-light-2">
      <div className="footer-bottom">
        <div className="container px-4">
          <div className="row">
            <div className="small">Copyright &copy; {new Date().getFullYear()} - Precision Verify</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default InnerFooter;
