import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { ImSpinner3 } from "react-icons/im";

import Forminputgroup from "../../components/reusables/Forminputgroup";
import logo from "../../assets/img/precisionverify/precision.png";
import PasswordInput from "../../components/reusables/PasswordInput";
import { adminLogin, removeUser, reset } from "features/auth/authSlice";

const Login = () => {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [toggle, setToggle] = useState(false)
  const { email, password } = userData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isAdminError, isAdminSuccess, isAdminLoading, adminMessage } = useSelector(
    (state) => state.auth
  );

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleToggele = () =>{
    setToggle(!toggle)
  }

  useEffect(() => {
    // dispaly toast message when error
    if (isAdminError) {
      toast.error(adminMessage);
    }
    //redirect to dashboard, when successful
    if (isAdminSuccess) {
      toast.success("Login successfully!");
      dispatch(removeUser())
      navigate("/admin");
      
    }
    dispatch(reset());
  }, [isAdminError, isAdminSuccess, adminMessage, user, navigate, dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem('user')
    if (password === "") {
      toast.error("Password can not be empty");
    } else if (email === "") {
      toast.error("Email can not be empty");
    } else {
      dispatch(adminLogin(userData));
    }
  };
  return (
    <section className="section-sm bg-light-2 reg-bg">
      <div className="container px-4">
        <div className="row gx-4 gx-lg-5">
          <div className="inner-row">
            <div className="auth-form-wrap">
              <div className="navbar-brand logo-wrap align-items-center">
                <Link className="navbar-brand" to={`/`}>
                  <img className="logo auth-logo" src={logo} alt="precisionverify" />
                </Link>
              </div>
              <div className="auth-form">
                <form autoComplete="off" onSubmit={onSubmit}>
                  <div className="form-header text-center">
                    <h3 className="form-title">Admin Sign In </h3>
                  </div>
                  <div className="form-body signin-form-body">
                    <Forminputgroup
                      inputLabel={`Email`}
                      inputType={`email`}
                      inputName="email"
                      inputRequire={`required`}
                      value={email}
                      onChange={handleChange}
                    />

                    <PasswordInput
                      inputLabel={`Password`}
                      inputType={toggle ? `text` : `password`}
                      inputName="password"
                      inputRequire={`required`}
                      value={password}
                      onChange={handleChange}
                      toggle={toggle}
                      handleToggele ={handleToggele}
                    />

                    <div className="form-group btn-wrap">
                      <button className="btn btn-primary btn-block btn-shadow btn-round btn-md btn-login">
                        Sign In
                        {isAdminLoading && <ImSpinner3 className="icon-spin" />}
                      </button>
                    </div>
                  </div>


                  <div className="form-footer">
                    <p className="txt-center">
                      <Link
                        className="inline-link"
                        to={`/admin/forgot-password`}
                      >
                        {" "}
                        Forgot your password?
                      </Link>
                    </p>
                   
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
