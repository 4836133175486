import React from 'react'

const Forminputshort = ({ errorMsg, inputName, inputLabel, inputType, inputRequired, value, onChange, disable, onFocus}) => {
  const checkRequired = () => {
    return inputRequired ? ('*') : ''
  }
  return (
    <div className="col-lg-6 input-wrapper">
      <input type={inputType}
        className="form-control" name={inputName}
        id={inputName} autoComplete="off"
        placeholder={`${inputLabel} ${checkRequired()}`} required={inputRequired} value={value} onChange={onChange}
        onMouseEnter={onFocus || undefined}
        disabled={disable} 
      />
      {errorMsg && <small className='text-danger my-1'>{errorMsg}</small>}
    </div>
  )
}

export default Forminputshort
