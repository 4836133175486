import http from './httpService'
import authHeader from "./header.service";
const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

// /generic/give-network/paystack/payment-intent
export const paystackPayment = (data) =>{
    return http.post(`${apiUrl}/generic/projects/paystack/payment-intent`, data,  {headers: authHeader() })
}
export const flutterwavePayment = (data) =>{
    return http.post(`${apiUrl}/generic/projects/flutterwave/payment-intent`, data,  {headers: authHeader() })
}

// Give Network paystack payment
export const paystackPaymentGive = (data) =>{
    return http.post(`${apiUrl}/generic/give-network/paystack/payment-intent`, data,  {headers: authHeader() })
}

export const flutterwavePaymentGive = (data) =>{
    return http.post(`${apiUrl}/generic/give-network/fluttwave/payment-intent`, data,  {headers: authHeader() })
}


export const resetMail = (data) =>{
    return http.post(`${apiUrl}/user/auth/password/forgot`, data,  {headers: authHeader() })
}


export const authenticate = (data) =>{
    return http.post(`${apiUrl}/user/auth/google/login`, data)
}

export const googleAuth = () =>{
    return http.get(`${apiUrl}/user/auth/google/redirect-url`)
}

