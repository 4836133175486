import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const create = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/user/business-bank-details`, inputData, config);
    return data
}

const getRecord = async (token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/business-bank-details`, config);
    return data
}

const bankService = {
    create,  
    getRecord,
}

export default bankService