import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import directorService from './directorService'


const initialState = {
    director: {},
    directors:null,
    isErrorD: false,
    isSuccessD:false,
    isFetchSuccessD: false,
    isDeleteD:false,
    isDeletingD:false,
    isLoadingD:false,
    messageD:''
}

//@function: create director record
export const createDirector = createAsyncThunk('director/create', async (inputData, thunkAPI) =>{
  
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await directorService.create(inputData, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getDirectors = createAsyncThunk('directors/fetch', async (businessId, thunkAPI) =>{
    try {
        
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await directorService.getRecord(businessId, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getDirector = createAsyncThunk('director/fetch', async (directorId, thunkAPI) =>{
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await directorService.getSingleDirector(directorId, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete bussiness
export const deleteDirector = createAsyncThunk('director/delete', async (directorId, thunkAPI) =>{
    
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await directorService.deleteRecord(directorId,data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const directorSlice = createSlice({
    name:'director',
    initialState,
    reducers:{
        reset:(state) => {
            state.isErrorD = false
            state.isLoadingD = false
            state.isSuccessD = false
            state.isFetchSuccessD = false
            state.isDeleteD = false
            state.isDeletingD = false
            state.messageD = ""
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createDirector.pending, (state)=>{
                state.isLoadingD = true
            })
            .addCase(createDirector.fulfilled, (state, action)=>{
                state.isLoadingD = false
                state.isSuccessD = true
                state.director = action.payload
            })
            .addCase(createDirector.rejected, (state, action) =>{
                state.isErrorD = true
                state.isLoadingD = false
                state.messageD = action.payload
              
            })
            .addCase(getDirectors.pending, (state)=>{
                state.isLoadingD = true
            })
            .addCase(getDirectors.fulfilled, (state, action)=>{
                state.isLoadingD = false
                state.isFetchSuccessD = true
                state.directors = action.payload
            })
            .addCase(getDirectors.rejected, (state, action) =>{
                state.isErrorD = true
                state.isLoadingD = false
                state.messageD = action.payload
              
            })
            .addCase(deleteDirector.pending, (state)=>{
                state.isDeletingD = true
            })
            .addCase(deleteDirector.fulfilled, (state, action)=>{
                state.isDeletingD = false
                state.isDeleteD = true
            })
            .addCase(deleteDirector.rejected, (state, action) =>{
                state.isErrorD = true
                state.isDeletingD = false
                state.messageD = action.payload
            })
            .addCase(getDirector.pending, (state)=>{
                state.isLoadingD = true
            })
            .addCase(getDirector.fulfilled, (state, action)=>{
                state.isLoadingD = false
                state.isFetchSuccessD = true
                state.director = action.payload
            })
            .addCase(getDirector.rejected, (state, action) =>{
                state.isErrorD = true
                state.isLoadingD = false
                state.messageD = action.payload
              
            })
    }
})

export const { reset} = directorSlice.actions
export default directorSlice.reducer