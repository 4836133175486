import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import adminAuthservice from './adminAuthservice'

const admin = JSON.parse(localStorage.getItem("admin"))
const initialState = {
    admin: admin ? admin : null,
    isError: false,
    isSuccess:false,
    isLoading:false,
    message:''
}




// Login users
export const login = createAsyncThunk('admin/login', async (user, thunkAPI) =>{
    try {
        return await adminAuthservice.login(user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || 
        error.message || 
        error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Login users
export const logout = createAsyncThunk('auth/logout',  async(thunkAPI) =>{
    const { data } = JSON.parse(localStorage.getItem("user"));
     try {
        
        const resp = await adminAuthservice.logout(data.token)
        console.log(resp);
     } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || 
        error.message || 
        error.toString()

        return thunkAPI.rejectWithValue(message)
     }
})


export const adminAuthSlice = createSlice({
    name:'admin',
    initialState,
    reducers:{
        reset:(state) =>{
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers: (builder) =>{
        builder
           
            .addCase(login.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.admin = action.payload
            })
            .addCase(login.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.admin = null
                state.message = action.payload
            })
            .addCase(logout.fulfilled, (state)=>{
                state.admin = null
            })
    }
})

export const { reset} = adminAuthSlice.actions
export default adminAuthSlice.reducer