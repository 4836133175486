import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { ImSpinner3 } from "react-icons/im";

import Forminputgroup from "../../components/reusables/Forminputgroup";
import logo from "../../assets/img/precisionverify/precision.png";
import { login, removeAdmin, reset } from "../../features/auth/authSlice";
import PasswordInput from "../../components/reusables/PasswordInput";
import { googleAuth } from "../../services/post.service";
import { FaInfoCircle } from "react-icons/fa";
import { getUserProfile } from "data/generalService";

const Signin = () => {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);

  const { email, password } = userData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const userProfile = async () => {
    const { data } = await getUserProfile();
    localStorage.setItem("profile", JSON.stringify(data.data.user));
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    //redirect to dashboard, when successful
    if (isSuccess) {
      toast.success("Login successfully!");
      navigate("/inner");
    }
    dispatch(removeAdmin())
    dispatch(reset());
  }, [isError, isSuccess, message, user, navigate, dispatch]);

  const handleToggele = () => {
    setToggle(!toggle);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem('admin')

    if (password === "") {
      toast.error("Password can not be empty");
    } else if (email === "") {
      toast.error("Email can not be empty");
    } else {
      dispatch(login(userData));
    }
  };

  // Google Auth
  async function initiateGoogleAuth() {
    navigate("/inner");
    // try {
    //   setLoading(true);
    //   const { data } = await googleAuth();

    //   if (data !== null && data.data.url !== "") {
    //     window.location.replace(`${data.data.url}`);
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  return (
    <section className="section-sm bg-light-2 reg-bg">
      <div className="container px-4">
        <div className="row gx-4 gx-lg-5">
          <div className="inner-row">
            <div className="auth-form-wrap">
              <div className="navbar-brand logo-wrap align-items-center">
                <Link className="navbar-brand" to={`/`}>
                  <img className="logo auth-logo" src={logo} alt="precisionverify.ng" />
                </Link>
              </div>
              <div className="auth-form">
                <form autoComplete="off" onSubmit={onSubmit}>
                  <div className="form-header text-center">
                    <h3 className="form-title">Sign In </h3>
                  </div>

                  <div className="form-body signin-form-body">
                    <Forminputgroup
                      inputLabel={`Email Address`}
                      inputType={`email`}
                      inputName="email"
                      inputRequire={`required`}
                      value={email}
                      onChange={handleChange}
                    />

                    <PasswordInput
                      inputLabel={`Password`}
                      inputType={toggle ? `text` : `password`}
                      inputName="password"
                      inputRequire={`required`}
                      value={password}
                      onChange={handleChange}
                      toggle={toggle}
                      handleToggele={handleToggele}
                    />

                    <div className="form-group btn-wrap">
                      <button className="btn btn-primary btn-block btn-shadow btn-round btn-md btn-login">
                        Sign In
                        {isLoading && <ImSpinner3 className="icon-spin" />}
                      </button>
                    </div>
                    <div className="pass-reset-wrap">
                      <p className="txt-center">
                        <Link
                          className="inline-link"
                          to={`/auth/forgot-password`}
                        >
                          {" "}
                          Forgot your password?
                        </Link>
                      </p>
                      <p className="txt-center">
                        Don't have an account?{" "}
                        <Link className="inline-link" to={`/auth/register`}>
                          {" "}
                          Register{" "}
                        </Link>{" "}
                        here.
                      </p>
                    </div>
                  </div>

                  {/* <div className="form-footer social-wrap">
                    <div className="form-sub-header text-center">
                      <h5 className="form-sub-title">
                        OR <br />
                      </h5>
                    </div>
                    <div className="form-group">
                      <div className="social-login-wrap">
                        <a href="#" className="btn btn-social btn-facebook">
                          <i className="bi bi-facebook"></i> Login with Facebook
                        </a>
                        <a href="#" className="btn btn-social btn-twitter">
                          <i className="bi bi-twitter"></i> Login with Twitter
                        </a>
                        <a
                          onClick={initiateGoogleAuth}
                          className="btn btn-social btn-google"
                        >
                          <i className="bi bi-google"></i> Login with Google
                          {loading ? <ImSpinner3 /> : ""}
                        </a>
                      </div>
                    </div>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signin;
