import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`


// @ retun list of directors for a particular business
const getCampaigns = async () =>{

      const { data } = await axios.get(`${apiUrl}/generic/projects?include=donations,user,category`);
    return data
}

// @ retun list of directors for a particular business
const getCampaign = async (projectId) =>{
      const { data } = await axios.get(`${apiUrl}/generic/projects/${projectId}?include=user`);
    return data
}


const campaignService = {
 
    getCampaigns,
    getCampaign,
  
}

export default campaignService