import React from 'react'

const HelpCta = () => {
  return (
    <section className="section-xsm-sm">
    <div className="container px-4">
        <div className="cta-wrap bg-light-2">
            <div className="row align-middle">
                <div className="col-lg-7">
                    <h2 className="mb-4">Still Got Questions?</h2>						
                    <p>Got a question we haven’t answered? Let us know how we can help.</p>							
                    <div className="spacer spacer-sm"></div>	
                </div>
                <div className="col-lg-4 offset-lg-1 d-flex align-items-center justify-content-end">
                    <div className="text-widget align-middle">
                        <a className="btn btn-primary text-white btn-round btn-md" href="#">Get Help</a>
                    </div>
                </div>
            </div>
        </div>                
    </div>
</section>
  )
}

export default HelpCta