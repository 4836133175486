import FormSelectComponent from "components/reusables/FormSelectComponent";
import Forminputgroup from "components/reusables/Forminputgroup"
import nubanBankService from "features/generic/verification/nubanBankService";
import { useEffect, useState } from "react";

export default function NubanBvn({setValue, type}) {
    const [formData, setFormData] = useState({
        firstname:'',
        lastname:'',
        idNumber: '',
        bankCode: ''
    })

    const [banks, setBanks] = useState([])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const fetchBanks = async() => {
        try {
            // setLoading(true)
            const resp = await nubanBankService.fetchNubanBanks({ type })

            if(resp.success){
                const responseData = resp.data.values.map(v => (
                    {id: v.id, value: v.code, caption: v.name}
                ))
                setBanks(responseData)
            }
        
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            // toast.error(message)
            // setLoading(false)
        }
    }

    useEffect(() => {
        setValue(formData)
    }, [formData])

    useEffect(() => {
        fetchBanks()
    }, [type])

    return (
        <>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Account Number`}
                    inputName={'idNumber'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.idNumber}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`First Name`}
                    inputName={'firstname'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.firstname}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Last Name`}
                    inputName={'lastname'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.lastname}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <FormSelectComponent
                    selectName="bankCode"
                    selectOption="Select Bank"
                    value={formData.bankCode}
                    itemList={banks}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
        </>
    )
}