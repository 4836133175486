import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import withdrawService from './withdrawService'


const initialState = {
    withdraw: null,
    withdraws:null,
    isErrorW: false,
    isSuccessW:false,
    isFetchSuccessW: false,
    isDeleteW:false,
    isDeletingW:false,
    isLoadingW:false,
    messageW:''
}

//@function: create director record
export const createWithdraw = createAsyncThunk('withdraw/create', async (inputData, thunkAPI) =>{
  
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await withdrawService.createWithdraw(inputData, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create director record
export const updateWithdraw = createAsyncThunk('withdraw/update', async (inputData,Id, thunkAPI) =>{
  
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await withdrawService.updateWithdraw(inputData,Id, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getWithdrawals = createAsyncThunk('withdraws/fetch', async (_,thunkAPI) =>{
    try {
        
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await withdrawService.getWithdrawals(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getWithdrawal = createAsyncThunk('withdraw/fetch', async (Id, thunkAPI) =>{
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await withdrawService.getWithdrawal(Id, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete bussiness
export const deleteWithdraw = createAsyncThunk('withdraw/delete', async (Id, thunkAPI) =>{
    
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await withdrawService.deleteWithdraw(Id,data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const withdrawSlice = createSlice({
    name:'withdraw',
    initialState,
    reducers:{
        reset:(state) => {
            state.isErrorW = false
            state.isLoadingW = false
            state.isSuccessW = false
            state.isFetchSuccessW = false
            state.isDeleteW = false
            state.isDeletingW = false
            state.messageW = ""
            state.withdraw = null
            state.withdraws = null
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createWithdraw.pending, (state)=>{
                state.isLoadingW = true
            })
            .addCase(createWithdraw.fulfilled, (state, action)=>{
                state.isLoadingW = false
                state.isSuccessW = true
                state.withdraw = action.payload
            })
            .addCase(createWithdraw.rejected, (state, action) =>{
                state.isErrorW = true
                state.isLoadingW = false
                state.messageW = action.payload
              
            })
            .addCase(getWithdrawals.pending, (state)=>{
                state.isLoadingW = true
            })
            .addCase(getWithdrawals.fulfilled, (state, action)=>{
                state.isLoadingW = false
                state.isFetchSuccessW = true
                state.withdraws = action.payload
            })
            .addCase(getWithdrawals.rejected, (state, action) =>{
                state.isErrorW = true
                state.isLoadingW = false
                state.messageW = action.payload
              
            })
            .addCase(deleteWithdraw.pending, (state)=>{
                state.isDeletingW = true
            })
            .addCase(deleteWithdraw.fulfilled, (state, action)=>{
                state.isDeletingW = false
                state.isDeleteW = true
            })
            .addCase(deleteWithdraw.rejected, (state, action) =>{
                state.isErrorW = true
                state.isDeletingW = false
                state.messageW = action.payload
            })
            .addCase(getWithdrawal.pending, (state)=>{
                state.isLoadingW = true
            })
            .addCase(getWithdrawal.fulfilled, (state, action)=>{
                state.isLoadingW = false
                state.isFetchSuccessW = true
                state.withdraw = action.payload
            })
            .addCase(getWithdrawal.rejected, (state, action) =>{
                state.isErrorW = true
                state.isLoadingW = false
                state.messageW = action.payload
              
            })
            .addCase(updateWithdraw.pending, (state)=>{
                state.isLoadingW = true
            })
            .addCase(updateWithdraw.fulfilled, (state, action)=>{
                state.isLoadingW = false
                state.isSuccessW = true
                state.withdraw = action.payload
            })
            .addCase(updateWithdraw.rejected, (state, action) =>{
                state.isErrorW = true
                state.isLoadingW = false
                state.messageW = action.payload
              
            })
    }
})

export const { reset} = withdrawSlice.actions
export default withdrawSlice.reducer