import Forminputgroup from "components/reusables/Forminputgroup"
import { useEffect, useState } from "react";

export default function Address({setValue}) {
    const [formData, setFormData] = useState({
        applicant: {
            firstname:'',
            lastname:'',
            phone: ''
        },
        idNumber: '',
        customerReference: '',
        dob:'',
        street: '',
        stateName: '',
        lgaName: '',
        city: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // If the name attribute contains a dot, it means the field is inside the applicant object
        if (name.includes('.')) {
          const [nestedObject, nestedField] = name.split('.');
          setFormData((prevFormData) => ({
            ...prevFormData,
            [nestedObject]: {
              ...prevFormData[nestedObject],
              [nestedField]: value,
            },
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        }
    };
    

    useEffect(() => {
        formData.idNumber = formData.customerReference
        setValue(formData)
    }, [formData])

    return (
        <>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Customer Reference`}
                    inputName={'customerReference'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.customerReference}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`State`}
                    inputName={'stateName'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.stateName}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Lga Name`}
                    inputName={'lgaName'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.lgaName}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Street`}
                    inputName={'street'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.street}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`City`}
                    inputName={'city'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.city}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div>
                <h4>Applicant Data</h4>
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`First Name`}
                    inputName={'applicant.firstname'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.applicant.firstname}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Last Name`}
                    inputName={'applicant.lastname'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.applicant.lastname}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Phone`}
                    inputName={'applicant.phone'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.applicant.phone}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
        </>
    )
}