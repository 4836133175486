import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h2>404 Page not found</h2>
      <button className="btm btn-primary btn-shadow" onClick={() => navigate(-1)}>go back</button>
    </div>
  );
}
export default PageNotFound
