
const PrivacyHeader = () => {
  return (
    <header className="hero-image-bg-wrap bg-saturate help-header" >
			<div className="masthead bg-gradient-2 page-hero">
				<div className="container px-4 px-lg-5 h-100">
					<div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
						<div className="col-lg-8 align-self-end text-white">
							<h1 className="font-weight-bold page-hero-title">Privacy Policy</h1>
							<p className="font-weight-bold page-hero-description">Find out more about our privacy policies</p>
							<div className="spacer spacer-sm"></div>
						</div>						
					</div>
					<div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center">
						<div className="page-breadcrumbs">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb justify-content-center text-center">
									<li className="breadcrumb-item"><a href="/">Home</a></li>
									<li className="breadcrumb-item active" aria-current="page">Privacy Policy</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
        </header>
  )
}

export default PrivacyHeader