import axios from 'axios'
import { toast } from 'react-toastify';

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

export const apiUrl = `${baseUrl}/${apiVersion}`

export const paystackKey = 'pk_live_dc0ee5ae038f94923f5bdc5845c751b9a719f7cf'
// export const paystackKey = 'pk_test_6c520c042c45837cdb2a64e3ab23990ab28f434e'


export const axiosInstance = axios.create({
  baseURL: apiUrl,
});

const checkToken = (token) => {
  axios.get(`${apiUrl}/user/dashboard/analytics`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
      .then()
      .catch(err => {
        if(err.response.status == '401') {
          localStorage.removeItem("user")
          window.location.href = '/auth';
          console.log(err.response);
          return
        }
      })
}

const checkAdminToken = (token) => {
  axios.get(`${apiUrl}/admin/dashboard/analytics`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
      .then()
      .catch(err => {
        if(err.response.status == '401') {
          localStorage.removeItem("admin")
          window.location.href = '/admin/login';
          console.log(err.response);
          return
        }
      })
}

axiosInstance.interceptors.request.use(async req => {
  req.headers['Accept'] = 'application/json';
  // req.headers['Content-Type'] = 'multipart/form-data';

  const user = JSON.parse(localStorage.getItem("user"));

  const token = user?.data?.token;

  if (token) {
    checkToken(token)
  }

  req.headers['Authorization'] = token ? `Bearer ${token}` : null;

  return req
},
  (error) => {
    const message = (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();
    return Promise.reject(message)
  })


axiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error?.message?.includes('Network Error')) {
      toast.error('There seems to be something wrong with your internet connection, please check and try again.', { type: 'error', duration: 5000 })

      return
    }

    if (error?.message?.includes('Unauthenticated.')) {
      localStorage.removeItem('user')
      window.location.reload()
      return
    }

    const message = (error?.response &&
      error?.response?.data &&
      error?.response?.data?.message) ||
      error?.message ||
      error?.toString();
    return Promise.reject(message);
  }
);


// For ADMIN
export const axiosInstanceAdmin = axios.create({
  baseURL: apiUrl,
});

axiosInstanceAdmin.interceptors.request.use(async req => {
  req.headers['Accept'] = 'application/json';
  // req.headers['Content-Type'] = 'multipart/form-data';

  const admin = JSON.parse(localStorage.getItem("admin"));

  const token = admin?.data?.token;

  if (token) {
    checkAdminToken(token)
  }

  req.headers['Authorization'] = token ? `Bearer ${token}` : null;

  return req
},
  (error) => {
    const message = (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();
    return Promise.reject(message)
  })


axiosInstanceAdmin.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error?.message?.includes('Network Error')) {
      toast.error('There seems to be something wrong with your internet connection, please check and try again.', { type: 'error', duration: 5000 })

      return
    }
    if (error?.message?.includes('Unauthenticated.')) {
      localStorage.removeItem('admin')
      window.location.reload()
      return
    }
    const message = (error?.response &&
      error?.response?.data &&
      error?.response?.data?.message) ||
      error?.message ||
      error?.toString();
    return Promise.reject(message);
  }
);

// For Email Verification
export const axiosInstanceEmailVerification = axios.create({
  baseURL: apiUrl,
});

axiosInstanceEmailVerification.interceptors.request.use(async req => {
  req.headers['Accept'] = 'application/json';
  // req.headers['Content-Type'] = 'multipart/form-data';

  const admin = JSON.parse(localStorage.getItem("verificationData"));

  const token = admin?.data?.token;

  req.headers['Authorization'] = token ? `Bearer ${token}` : null;

  return req
},
  (error) => {
    const message = (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();
    return Promise.reject(message)
  })


axiosInstanceEmailVerification.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error?.message?.includes('Network Error')) {
      toast.error('There seems to be something wrong with your internet connection, please check and try again.', { type: 'error', duration: 5000 })

      return
    }
    const message = (error?.response &&
      error?.response?.data &&
      error?.response?.data?.message) ||
      error?.message ||
      error?.toString();
    return Promise.reject(message);
  }
);
