import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Modal,
  Pagination,
  Spinner,
  Table,
} from "react-bootstrap";
import "./ConfigSetting.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ImSpinner3 } from "react-icons/im";

import {
  fetchLiveWebhook,
  fetchTestWebhook,
  setWebhook,
} from "features/configuration/configSettingService";
import PasswordInput from "components/reusables/PasswordInput";
import Forminputgroup from "components/reusables/Forminputgroup";

const WebHook = () => {
  const [loading, setLoading] = useState(false);

  const { isLoading } = useSelector((state) => state.verificationTypes);
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [disable, setDisable] = useState(true);
  const [disable2, setDisable2] = useState(true);
  const [loading1, setloading1] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [validationError, setvalidationError] = useState({
    test:'',
    live:''
  });
  const handleToggele = () => {
    setToggle(!toggle);
  };
  const handleToggele2 = () => {
    setToggle2(!toggle2);
  };

  const [testWebhook, setTestWebhook] = useState("");
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  const handleChange = (e) => {
    if (!urlPattern.test(e.target.value)) {
      setvalidationError({
        ...validationError,
        test: 'Valid URL required' 
      });
      setDisable(true)
    } else{
      setvalidationError({
     ...validationError,
        test:''
      });
      setDisable(false)
    }
  
    setTestWebhook(e.target.value);

  };

  const [liveWebhook, setLiveWebhook] = useState("");
  const handleChange2 = (e) => {
    console.log(e.target.value);
    if (!urlPattern.test(e.target.value)) {
      setvalidationError({
        ...validationError,
        live: 'Valid URL required' 
      });
      setDisable2(true)
    }else {
      setvalidationError({
        ...validationError,
        live:''
         });
         setDisable(false)
    }
    setLiveWebhook(e.target.value);

  };

  const handleSubmit = async (mode) => {
    let data = {};
    if (mode === "live") {
      data = {
        mode: mode,
        key: liveWebhook,
        type: "webhook",
      };
      setloading2(true);

    }

    if (mode === "test") {
      data = {
        mode: mode,
        key: testWebhook,
        type: "webhook",
      };
      setloading1(true);

    }

    try {
      const resp = await setWebhook(data);

      if (resp.success) {
        toast.success(resp.message);
        setloading2(false);
        setloading1(false);


      }
    } catch (error) {
      setloading2(false);
      setloading1(false);

      toast.error(error);
    }
  };

  const getLiveWebhook = async () => {
    setLoading(true);
    try {
      const response = await fetchLiveWebhook();
      if (response.success) {
        // toast.success(response.message)
        setLiveWebhook(response.data.configuration_setting);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setLoading(false);
    }
  };
  const getTestWebhook = async () => {
    setLoading(true);
    try {
      const response = await fetchTestWebhook();
      if (response.success) {
        setTestWebhook(response.data.configuration_setting);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getLiveWebhook();
    getTestWebhook();
    setLoading(false);
  }, []);

  return (
    <>
      <div className="pb-5">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="info" />
          </div>
        ) : (
          <>
            {/* {loading && (
              <div className="d-flex align-items-center">
                <ImSpinner3 className="icon-spins mr-2" />
                Loading...
              </div>
            )} */}
            <div className="">
              <div className="precision-verify-card w-lg-100">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-10">
                        <label htmlFor="test">Test Webhook</label>
                        <Forminputgroup
                          inputLabel={`Enter Url`}
                          inputType={'text'}
                          inputName="current_password"
                          inputRequire={true}
                          value={testWebhook || ''}
                          onChange={(e) => handleChange(e)}
                          toggle={toggle}
                          handleToggele={handleToggele}
                          size="col-lg-12"
                          errorMsg={validationError ? validationError.test : ''}

                        />
                      </div>
                      <div className="col-md-2 pt-2 mt-2">
                        <label htmlFor=""></label>
                        <button
                          className="btn btn-sm btn-custom"
                          onClick={() => handleSubmit("test")}
                          disabled={disable}
                          
                        >
                          {loading1 && <ImSpinner3 className="icon-spins" />} Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-10">
                        <label htmlFor="live">Live Webhook</label>
                        <Forminputgroup
                          inputLabel={`Enter Url`}
                          inputType={'text'}
                          inputName="current_password"
                          inputRequire={true}
                          value={liveWebhook || ''}
                          onChange={(e) => handleChange2(e)}
                          toggle={toggle2}
                          handleToggele={handleToggele2}
                          size="col-lg-12"
                          errorMsg={validationError ? validationError.live : ''}

                        />
                      </div>
                      <div className="col-md-2 pt-2 mt-2">
                        <label htmlFor=""></label>
                        <button
                          className="btn btn-sm btn-custom"
                          onClick={() => handleSubmit("live")}
                          disabled={disable}
                        >
                          {loading2 && <ImSpinner3 className="icon-spins" />}
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WebHook;
