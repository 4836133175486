import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import {
  Breadcrumb,
  Button,
  Modal,
  Pagination,
  Spinner,
  Table,
} from "react-bootstrap";
import Forminputgroup from "components/reusables/Forminputgroup";
import "./ConfigSetting.css";
import { useDispatch, useSelector } from "react-redux";
import { getVerificationTypes } from "features/generic/verification/verificationTypeSlice";
import { ImSpinner3 } from "react-icons/im";
import { adminUpdateVerificationServiceCost } from "features/admin/wallet/adminWalletService";
import { toast } from "react-toastify";
import PasswordInput from "components/reusables/PasswordInput";
import Key from "./Key";
import WebHook from "./webhook";

const ConfigSettingContent = () => {
  //  user form data
  const [formData, setFormData] = useState({
    cost: "",
  });

  const [currentVerificationType, setCurrentVerificationType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState({});

  //  Modal states
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const { verificationTypes, isError, isLoading, isSuccess, message } =
    useSelector((state) => state.verificationTypes);

  //  state for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  //  pagination function
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const setPrice = async (veriData) => {
    await setCurrentVerificationType(veriData);
    handleShow();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const resp = await adminUpdateVerificationServiceCost(
        currentVerificationType.id,
        formData
      );

      if (resp.success) {
        toast.success(resp.message);
        dispatch(getVerificationTypes());
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
      // if(error.response.status == 422) {
      //     setValidationError(error.response.data.errors)
      // } else {
      //   message = (error.response && error.response.data && error.response.data.message) ||
      //               error.message ||
      //               error.toString()
      //   toast.error(message)
      // }
    }
  };
  const [toggle, setToggle] = useState(false);

  const handleToggele = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    dispatch(getVerificationTypes());
  }, []);

  return (
    <>
      <div className="inner-page-wrapper">
        {/* Sidebar */}
        <Sidebar />
        <div
          id="content-wrapper"
          className="content-wrapper d-flex flex-column"
        >
          {/* Main Content  */}
          <div id="content">
            {/* Topbar */}

            <Topbar />

            {/* Begin Page Content  */}
            <div className="container-fluid">
              {/* Page Heading  */}
              <div className="d-sm-flex align-items-center justify-content-between mb-5">
                <h1 className="h3 mb-0 text-gray-800 font-weight-bold">
                  Configuration Keys
                </h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    Configuration Settings
                  </Breadcrumb.Item>
                </Breadcrumb>
                {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                                    <i className="bi bi-cloud-download"></i> Generate Report</a> */}
              </div>

              {/* Content Row  */}
              {/* keys */}
              
              <div className="precision-verify-card w-lg-50">
                    <ul className="nav nav-pills ml-3" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="pill"
                          href="#home"
                        >
                         Api Key
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="pill" href="#menu1">
                          Webhook Setup
                        </a>
                      </li>
                    </ul>
                <div className="tab-content mt-3">
                  <div id="home" className="tab-pane active">
                    <Key />
                  </div>
                  <div id="menu1" className="tab-pane fade">
                    <WebHook />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigSettingContent;
