import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import {
  Breadcrumb,
  Button,
  Modal,
  Pagination,
  Spinner,
  Table,
} from "react-bootstrap";
import Forminputgroup from "components/reusables/Forminputgroup";
import "./Settings.css";
import { useDispatch, useSelector } from "react-redux";
import { getVerificationTypes } from "features/generic/verification/verificationTypeSlice";
import { ImSpinner3 } from "react-icons/im";
import {
  adminUpdateVerificationServiceCost,
  setStatus,
} from "features/admin/wallet/adminWalletService";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import {
  setVatPercentage,
  fetchVat,
} from "features/configuration/configSettingService";
import PasswordInput from "components/reusables/PasswordInput";
const SettingsContent = () => {
  //  user form data
  const [formData, setFormData] = useState({
    cost: "",
  });

  const [vatData, setvatData] = useState({
    vat: "",
    id: "",
  });

  const [currentVerificationType, setCurrentVerificationType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState({});

  //  Modal states
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow1(true);
  const handleClose2 = () => setShow1(false);

  const dispatch = useDispatch();
  const { verificationTypes, isError, isLoading, isSuccess, message } =
    useSelector((state) => state.verificationTypes);

  //  state for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  //  pagination function
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleChange2 = (e) => {
    setvatData({ ...vatData, vat: e.target.value, id: vatData.id });
  };
  const setPrice = async (veriData) => {
    await setCurrentVerificationType(veriData);
    setFormData({ cost: veriData.cost });
    handleShow();
  };

  const setVat = async (data) => {
    setvatData({ ...vatData, vat: data.vat, id: data.id });
    getVat();
    handleShow2();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const resp = await adminUpdateVerificationServiceCost(
        currentVerificationType.id,
        formData
      );

      if (resp.success) {
        toast.success(resp.message);
        setFormData({ ...formData, cost: "" });
        dispatch(getVerificationTypes());
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
      // if(error.response.status == 422) {
      //     setValidationError(error.response.data.errors)
      // } else {
      //   message = (error.response && error.response.data && error.response.data.message) ||
      //               error.message ||
      //               error.toString()
      //   toast.error(message)
      // }
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();

    setLoading(true);
    let data = {
      id: vatData ? vatData.id : "",
      vat: vatData.vat,
    };
    try {
      const resp = await setVatPercentage(data);

      if (resp.success) {
        toast.success(resp.message);
        setLoading(false);
        handleClose2();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };
  const getVat = async () => {
    try {
      const response = await fetchVat();
      if (response.success) {
        setvatData({
          ...vatData,
          vat:
            (response &&
              response.data &&
              response.data.vat &&
              response.data.vat.vat_percentage) ??
            "",
          id:
            (response &&
              response.data &&
              response.data.vat &&
              response.data.vat.id) ??
            "",
        });
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setLoading(false);
    }
  };

  const changeStatus = async (id, ver_status) => {
    setLoading(true);
    try {
      const status = ver_status == "active" ? "inactive" : "active";
      const resp = await setStatus(id, { status });

      if (resp.success) {
        toast.success(resp.message);
        dispatch(getVerificationTypes());
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    dispatch(getVerificationTypes());
    getVat();
  }, []);

  return (
    <>
      <div className="inner-page-wrapper">
        {/* Sidebar */}
        <Sidebar />
        <div
          id="content-wrapper"
          className="content-wrapper d-flex flex-column"
        >
          {/* Main Content  */}
          <div id="content">
            {/* Topbar */}

            <Topbar />

            {/* Begin Page Content  */}
            <div className="container-fluid">
              {/* Page Heading  */}
              <div className="d-sm-flex align-items-center justify-content-between mb-5">
                <h1 className="h3 mb-0 text-gray-800 font-weight-bold">
                  Settings
                </h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>Settings</Breadcrumb.Item>
                </Breadcrumb>
                {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                                    <i className="bi bi-cloud-download"></i> Generate Report</a> */}
              </div>

              {/* Content Row  */}
              <div className="pb-5">
                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="grow" variant="primary" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="info" />
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-6 order-lg-1 order-2">
                      <div className="precision-verify-card shadow-lg w-lg-50">
                        <div>
                          <Table responsive="sm" hover>
                            <thead>
                              <tr>
                                <th width="45%">Verification Type</th>
                                <th width="20%">Cost(₦)</th>
                                <th>Status</th>
                                <th width="20%" className="text-center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {verificationTypes ? (
                                verificationTypes.items.map((ver) => (
                                  <tr key={ver.id}>
                                    <td>{ver.name}</td>
                                    <td>{Number(ver.cost).toLocaleString()}</td>
                                    <td>
                                      <div
                                        className={`badge p-2 badge-pill ${
                                          ver.status === "active"
                                            ? "badge-success-light"
                                            : ver.status === "inactive"
                                            ? "badge-danger"
                                            : ""
                                        }`}
                                      >
                                        {ver.status}
                                      </div>
                                    </td>

                                    <td className="dropdown text-center">
                                      <a
                                        className="dropdown-toggle menu text-dark text-muted"
                                        id="actionDropdown"
                                        href="#"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="mdi mdi-cog-outline"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                      </a>
                                      <div
                                        className="dropdown-menu dropdown-menu-right preview-list"
                                        aria-labelledby="actionDropdown"
                                      >
                                        <a
                                          className="dropdown-item custom-drop"
                                          onClick={() => setPrice(ver)}
                                        >
                                          <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                          <span>Change Price</span>
                                        </a>
                                        <div className="dropdown-divider"></div>
                                        {/* <a className="dropdown-item custom-drop">
                                                <i className="mdi mdi-account-cancel-outline mr-2 text-info"></i> <span>Deactivate Account</span>
                                            </a> */}
                                        <a
                                          className="dropdown-item custom-drop"
                                          onClick={() =>
                                            changeStatus(ver.id, ver.status)
                                          }
                                        >
                                          <i className="mdi mdi-shield-check-outline mr-2 text-warning"></i>{" "}
                                          <span>
                                            {ver.status == "active"
                                              ? "Deactivate"
                                              : "Activate"}
                                          </span>
                                        </a>
                                      </div>
                                    </td>
                                    {/* <td>
                                      <button
                                        onClick={() => setPrice(ver)}
                                        className="btn btn-custom btn-warning"
                                      >
                                        Change Price
                                      </button>
                                    </td> */}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="3">
                                    <div className="alert alert-info text-center">
                                      <h6>No record found</h6>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-2">
                        <div className="d-flex">
                          per page
                          <select
                            className="ml-1"
                            name="perPage"
                            onChange={(e) => setPerPage(e.target.value)}
                            value={perPage}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                          </select>
                        </div>
                        <Pagination
                          onChange={handlePageChange}
                          count={pageCount}
                          color="primary"
                          shape="rounded"
                          size="small"
                          page={page}
                        />
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-6 order-lg-2 order-1">
                      <div className="card shadow mb-4">
                        <div className="card-header py-3 top">
                          <h6 className="m-0 font-weight-bold text-primary">
                            VAT SETTINGS
                          </h6>
                          {/* <button className="btn btn-primary"><FaPlus /> Add Role</button> */}
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <h6 className="text-sm">CURRENT VAT</h6>
                                  {vatData && vatData.vat === "" ? (
                                    <div className="pt-3">
                                      <span className="text-center alert alert-primary text-sm">
                                        VAT NOT SET
                                      </span>
                                    </div>
                                  ) : (
                                    <h5 className="text-center vat-value text-info">
                                      {vatData && vatData.vat}%
                                    </h5>
                                  )}
                                </div>
                                <div>
                                  <button
                                    className="btn btn-warning"
                                    onClick={() => setVat({ vatData })}
                                  >
                                    {/* <FaPlus /> */}
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Change Price modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="prompt-title font-weight-bold">
            Update Cost for{" "}
            <span className="text-danger font-weight-bold">
              {currentVerificationType?.name}
            </span>{" "}
            Service
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-body pb-3">
              <Forminputgroup
                inputLabel={`Cost`}
                inputName="cost"
                inputType={`text`}
                inputRequire={false}
                value={formData.cost}
                onChange={handleChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" type="button" onClick={handleClose}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Update
              {loading && <ImSpinner3 className="icon-spin" />}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Set Vat modal */}
      <Modal show={show1} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title className="prompt-title font-weight-bold">
            Update VAT{" "}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit2}>
          <Modal.Body>
            <div className="form-body pb-3">
              <Forminputgroup
                inputLabel={`Update Vat`}
                inputName="cost"
                inputType={`number`}
                inputRequire={false}
                value={vatData && vatData.vat}
                onChange={handleChange2}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" type="button" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Update
              {loading && <ImSpinner3 className="icon-spin" />}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default SettingsContent;
