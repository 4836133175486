const FormTextareaComponent = ({ error = null, textName, value, onChange, textLabel, required, size }) => {
  // @function : return list to the select item

  return (
    <div className="form-group input-wrapper">

      <textarea
        className={`form-control ${size ? size : ''}`}
        name={textName}
        cols="30"
        rows="2"
        required={required}
        placeholder={`${textLabel} ${required ? '*' : ''}`}
        value={value}
        onChange={onChange}
      ></textarea>
      {error && <small className='error-red'>{error}</small>}
    </div>
  );
};

export default FormTextareaComponent;
