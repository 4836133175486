
import axios from 'axios'
import { toast } from 'react-toastify'

//handling unexpected error globally
axios.interceptors.response.use(null, error => {
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
    if(!expectedError){
        toast.error(<span style={{background:'transparent'}}>Connection error!, pls check your network</span>)
    }

    return Promise.reject(error)
})
export default {
    get: axios.get,
    post: axios.post,
    patch: axios.patch,
    put: axios.put,
    delete: axios.delete
}