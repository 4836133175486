import { FaEye, FaEyeSlash} from 'react-icons/fa'
const PasswordInput = ({ inputLabel, inputType, errorMsg, inputName, inputRequire, value, onChange, toggle, handleToggele, size }) => {
    const checkRequired = ()=>{
      return inputRequire ? '*' : ''
    }
  
    return (
      <>
        <div className={`${size} form-group input-wrapper pwd`}>
        
          <input
            type={inputType}
            className="form-control"
            name={inputName}
            id={inputName}
            autoComplete="off"
            placeholder={`${inputLabel} ${checkRequired()}`}
            required={inputRequire}
            value={value} 
            onChange={onChange}
          />
          <div className="icon-wrap" onClick={handleToggele}>
            {toggle ? <FaEyeSlash  /> : <FaEye  />}
          </div>
        </div>
         {errorMsg && <small className='text-danger my-1'>{errorMsg}</small>}
      </>
    );
  };
  
  export default PasswordInput;
  