
import { footerData } from "../data/Footerdata";
import FooterItemComponent from "./reusables/FooterItemComponent";

const Footer = () => {
  //render fund raising items
  const renderFundraise = () => {
    if (footerData.fundRaise.data && footerData.fundRaise.data.length > 0) {
      return footerData.fundRaise.data.map((item) => {
        return (
          <FooterItemComponent key={item.id} url={item.url} label={item.label} />
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };

  //render learn more items
  const renderLearnmoreItems = () => {
    if (footerData.learnMore.data && footerData.learnMore.data.length > 0) {
      return footerData.learnMore.data.map((item) => {
        return (
            <FooterItemComponent key={item.id} url={item.url} label={item.label} />
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };

  //render resources items
  const renderResourceItems = () => {
    if (footerData.resources.data && footerData.resources.data.length > 0) {
      return footerData.resources.data.map((item) => {
        return (
            <FooterItemComponent key={item.id} url={item.url} label={item.label} />
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };

  return (
    <footer className="section-md bg-light">
      <div className="footer-top">
        <div className="container px-4">
          <div className="row">
            <div className="col-lg-3">
              <div className="footer-widget widget-one">
                <div className="footer-logo">
                  <img
                    className="logo"
                    src={footerData.logo}
                    alt={footerData.logoAlt}
                  />
                </div>
                <div className="footer-content">
                  <p>{footerData.brief}</p>
                  <a className="inline-link" href={footerData.learnMoreUrl}>
                    {footerData.learnMoreLabel}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget">
                <div className="widget-title">
                  <h4>{footerData.fundRaise.title}</h4>
                </div>
                <div className="footer-content footer-nav">
                  <ul className="nav">{renderFundraise()}</ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="footer-widget">
                <div className="widget-title">
                  <h4>{footerData.learnMore.title}</h4>
                </div>
                <div className="footer-content footer-nav">
                  <ul className="nav">{renderLearnmoreItems()}</ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="footer-widget">
                <div className="widget-title">
                  <h4>{footerData.resources.title}</h4>
                </div>
                <div className="footer-content footer-nav">
                  <ul className="nav">{renderResourceItems()}</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container px-4">
          <div className="row">
            <div className="small">Copyright &copy; {new Date().getFullYear()} {footerData.copyright}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
