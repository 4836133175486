import FormSelectComponent from "components/reusables/FormSelectComponent";
import Forminputgroup from "components/reusables/Forminputgroup"
import { useEffect, useState } from "react";

export default function Guarantor({setValue}) {
    const [formData, setFormData] = useState({
        applicant: {
            firstname:'',
            lastname:'',
            phone: ''
        },
        idNumber: '1',
        customerReference: '',
        dob:'',
        street: '',
        state: '',
        lgaName: '',
        city: '',
        firstname:'',
        lastname:'',
        phone: '',
        email: '',
        acceptableIdtype: ''
    })

    const selectOptions = [
        {id: 1, value: 'nin', caption: 'NIN'},
        {id: 2, value: 'bvn', caption: 'BVN'},
        {id: 3, value: 'drivers_license', caption: 'Drivers License'}
    ]

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // If the name attribute contains a dot, it means the field is inside the applicant object
        if (name.includes('.')) {
          const [nestedObject, nestedField] = name.split('.');
          setFormData((prevFormData) => ({
            ...prevFormData,
            [nestedObject]: {
              ...prevFormData[nestedObject],
              [nestedField]: value,
            },
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        }
    };
    

    useEffect(() => {
        formData.idNumber = formData.customerReference
        setValue(formData)
    }, [formData])

    return (
        <>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Customer Reference`}
                    inputName={'customerReference'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.customerReference}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Guarantor's Firstname`}
                    inputName={'firstname'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.firstname}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Guarantor's Lastname`}
                    inputName={'lastname'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.lastname}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Guarantor's Phone`}
                    inputName={'phone'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.phone}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Guarantor's Email`}
                    inputName={'email'}
                    inputType={`email`}
                    inputRequire={true}
                    value={formData.email}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Guarantor's local government`}
                    inputName={'lgaName'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.lgaName}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Street`}
                    inputName={'street'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.street}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <FormSelectComponent
                    selectName="acceptableIdtype"
                    selectOption="Identification type"
                    value={formData.acceptableIdtype}
                    itemList={selectOptions}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div>
                <h4>Applicant Data</h4>
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`First Name`}
                    inputName={'applicant.firstname'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.applicant.firstname}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Last Name`}
                    inputName={'applicant.lastname'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.applicant.lastname}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Phone`}
                    inputName={'applicant.phone'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.applicant.phone}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
        </>
    )
}