
import InnerFooter from "pages/inner/InnerFooter";
import AdminDashboardContent from "./AdminDashboardContent";



const AdminDashboard = () => {
  return (
    <div className="inner-page">
      {/* <Navbar /> */}
      {/* <InnerHeader /> */}
      <AdminDashboardContent />
      <InnerFooter />
    </div>
  );
};

export default AdminDashboard;
