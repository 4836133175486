import { Breadcrumb, Button, Modal, Spinner, Table } from "react-bootstrap";
import Sidebar from "../../Sidebar";
import Topbar from "../../Topbar";
import Forminputshort from "components/reusables/Forminputshort";
import { useEffect, useState } from "react";
import Forminputshorter from "components/reusables/Forminputshorter";
import Forminputgroup from "components/reusables/Forminputgroup";
import FormTextareaComponent from "components/reusables/FormTextareaComponent";
import PasswordInput from "components/reusables/PasswordInput";
import PasswordChecklist from "react-password-checklist";
import { Link } from "react-router-dom";
import { createUsersAdmin, deleteUsersAdmin, fetchAllUsersAdmin, getUserServicePrice, resetUsersPasswordAdmin, updateUserServicePrice, updateUsersAdmin } from "features/admin/manage-users/manageUsersService";
import { toast } from "react-toastify";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination } from '@mui/material';
import { ImSpinner3 } from "react-icons/im";
import Moment from 'react-moment';

const UserContent = () => {

    //  user form data
    const [formData, setFormData] = useState({
        first_name:'',
        last_name:'',
        email:'',
        password:'',
        password_confirmation:'',
        account_type:'',
        company_name: '',
        address: '',
        searchFilter: '',
        cost: '',
        phone: '',
        verificationServiceId: ''
    })

    const resetForm  = () => {
        setFormData({
            first_name:'',
            last_name:'',
            email:'',
            password:'',
            password_confirmation:'',
            account_type:'',
            company_name: '',
            address: '',
            searchFilter: '',
            cost: '',
            phone: '',
            verificationServiceId: ''
        })
    }

    const [users, setUsers] = useState(null)
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [currentUser, setCurrentUser] = useState(false)
    const [userPricing, setUserPricing] = useState(null)
    const [validationError, setValidationError] = useState(null)

    const [toggle, setToggle] = useState(false)


    // Destructure formData
    const { first_name, last_name, email, password, 
        password_confirmation,account_type, company_name, 
        address, searchFilter, phone } = formData

    //  Modal states
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showVerifications, setShowVerifications] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const [showVerificationPrice, setShowVerificationPrice] = useState(false)
    const [showDelete, setShowDelete] = useState(false);
    const [showReset, setShowReset] = useState(false);

    //  state for pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(1);

    //  pagination function
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleToggele = () =>{
        setToggle(!toggle)
    }

    const handleUserPrice = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const filterUsers = (e) => {
        e.preventDefault()

        fetchAllUsers(page, perPage, formData)
    }

    const fetchAllUsers = async(page, perPage, formData) => {
        setLoading(true)
        try {
            const response = await fetchAllUsersAdmin(page, perPage, formData)

            if(response.success) {
                toast.success(response.message)
                setUsers(response.data.users.data.length ? response.data.users.data : null)
                const pageCount = Math.ceil(response.data.users.total / response.data.users.per_page);
                setPageCount(pageCount)
                setLoading(false)
            }
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setLoading(false)
        }
    }

    const fetchUserServicePrice = async(user) => {
        setLoading(true)
        try {
            const response = await getUserServicePrice(user.id)

            if(response.success) {
                toast.success(response.message)
                setUserPricing(response.data.user_service.length ? response.data.user_service : null)
                setLoading(false)
            }
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setLoading(false)
        }
    }

    const setPrice = async(e, index) => {
        e.preventDefault()

        formData.verificationServiceId = userPricing[index].verification_service_id
        formData.cost = formData.cost == '' ? userPricing[index].base_price : formData.cost
        setSubmitLoading(true)
        try {
            const response =  await updateUserServicePrice(currentUser.id, formData)

            if(response.success) {
                toast.success(response.message)
                setSubmitLoading(false)
                fetchUserServicePrice(currentUser)
            }
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setSubmitLoading(false)
        }
    }

    const showUserEdit = async(user) => {
        await setCurrentUser(user)
        setIsEdit(true)
        handleShow()
    }

    const showUserDelete = async(user) => {
        await setCurrentUser(user)
        setShowDelete(true)
    }

    const showUserPasswordReset = async(user) => {
        await setCurrentUser(user)
        setShowReset(true)
    }

    const openVerifications = async(user) => {
        await setCurrentUser(user)
        setShowVerifications(true)
    }

    const openProfile = async(user) => {
        await setCurrentUser(user)
        setShowProfile(true)
    }

    const openverificationPriceUpdate = async(user) => {
        await fetchUserServicePrice(user)
        await setCurrentUser(user)
        setShowVerificationPrice(true)
    }

    const handleSubmit = async(e) => {
        e.preventDefault()

        setSubmitLoading(true)
        try {
            const response =  isEdit ? await updateUsersAdmin(currentUser.id, formData) : await createUsersAdmin(formData)

            if(response.success) {
                toast.success(response.message)
                setSubmitLoading(false)
                handleClose()
                fetchAllUsers(page, perPage, formData)
            }
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setSubmitLoading(false)
        }
    }

    const handleDeleteUser = async() => {
        setSubmitLoading(true)
        try {
            const response =  await deleteUsersAdmin(currentUser.id)

            if(response.success) {
                toast.success(response.message)
                setSubmitLoading(false)
                setShowDelete(false)
                fetchAllUsers(page, perPage, formData)
            }
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setSubmitLoading(false)
        }
    }

    const handlePasswordReset = async() => {
        setSubmitLoading(true)
        try {
            const response =  await resetUsersPasswordAdmin(currentUser.id)

            if(response.success) {
                toast.success(response.message)
                setSubmitLoading(false)
                setShowReset(false)
            }
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setSubmitLoading(false)
        }
    }

    useEffect(() => {
        fetchAllUsers(page, perPage, formData)
    }, [page, perPage])

    useEffect(() => {
        setFormData(prevFormData => (
            {
                ...prevFormData,
                first_name: currentUser ? currentUser.first_name : '',
                last_name: currentUser ? currentUser.last_name : '',
                email:currentUser ? currentUser.email : '',
                company_name: currentUser ? currentUser.company_name : '',
                address: currentUser ? currentUser.address : '', 
                phone: currentUser ? currentUser.phone : '', 
            }
        ))
    }, [currentUser])

    return (
        <>
            <div className="inner-page-wrapper">
                {/* Sidebar */}
                <Sidebar />
                <div
                id="content-wrapper"
                className="content-wrapper d-flex flex-column"
                >
                {/* Main Content  */}
                <div id="content">
                    {/* Topbar */}

                    <Topbar />

                    {/* Begin Page Content  */}
                    <div className="container-fluid">
                    {/* Page Heading  */}
                    <div className="d-sm-flex align-items-center justify-content-between mb-5">
                        <h1 className="h3 mb-0 text-gray-800 font-weight-bold">
                        Users
                        </h1>
                        <Breadcrumb>
                        <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Users</Breadcrumb.Item>
                        </Breadcrumb>
                        {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                                    <i className="bi bi-cloud-download"></i> Generate Report</a> */}
                    </div>

                    {/* Content Row  */}
                    <div className="pb-5">
                        {loading ?
                                <div className="d-flex justify-content-center align-items-center">
                                    <Spinner animation="grow" variant="primary"/>
                                    <Spinner animation="grow" variant="danger"/>
                                    <Spinner animation="grow" variant="warning"/>
                                    <Spinner animation="grow" variant="info"/>
                                </div>
                        :
                            <div className="precision-verify-card shadow-lg">
                                <form onSubmit={filterUsers}>
                                    <div className="form-group row mt-4 ana ">
                                        <div className="col-lg-4 mb-3">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                aria-describedby="start-date"
                                                name="searchFilter"
                                                value={searchFilter}
                                                onChange={handleChange} 
                                                placeholder="Search user..."
                                            />
                                        </div>
                                        <div className="col-lg-3 mb-3">
                                            <div>
                                                <button className="btn btn-danger w-50" type="submit">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div>
                                    <div className='d-flex justify-content-end mb-lg-4 mb-md-4 mb-sm-0 mt-sm-3 mt-md-3 mt-lg-0'>
                                        <button 
                                            className='btn btn-sm btn-outline-danger px-3'
                                            onClick={() => {
                                                resetForm()
                                                setIsEdit(false);
                                                handleShow()
                                            }}
                                        >
                                            <i className="mdi mdi-plus mr-2"></i>
                                            Add new user
                                        </button>
                                    </div>
                                    <Table responsive="sm" hover>
                                        <thead>
                                            <tr>
                                                <th width="25%">User Info</th>
                                                <th>Email</th>
                                                <th>Company Name</th>
                                                <th >Phone Number</th>
                                                <th >Email Verified</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users ?
                                                users.map((user) => (
                                                    <tr key={user.id} style={{cursor: 'pointer'}}>
                                                        <td className="d-flex">
                                                            <span>
                                                                <img 
                                                                    src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpaTTohNZ3rp44K7dWEeDsrLtFx-n23eZPjZLvf7Sx_CoOvLAgLVmKTrV4nbrH1xugAqc&usqp=CAU'} width='40'
                                                                    className='rounded-circle' 
                                                                    alt='img' 
                                                                />
                                                            </span>
                                                            <span className='ml-3 align-items-center'>
                                                                <p className="m-0 font-weight-bold">{user.last_name} {user.first_name}</p>
                                                                <p className="text-muted m-0" style={{fontSize: '12px', lineHeight: 1.8}}>
                                                                    {user.company_name}
                                                                </p>
                                                            </span>
                                                        </td>
                                                        <td>{user.email}</td>
                                                        <td>{user.company_name}</td>
                                                        <td>{user.phone}</td>
                                                        <td>
                                                            <span className={`badge ${user.email_verified ? 'badge-success' : 'badge-danger'} px-2 py-2`}>
                                                                {user.email_verified ? 'Yes' : 'No'}
                                                            </span>
                                                        </td>
                                                        <td className="dropdown text-center">
                                                                <a className="dropdown-toggle menu text-dark text-muted" id="actionDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="mdi mdi-cog-outline" style={{fontSize: '20px'}}></i>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-right preview-list" aria-labelledby="actionDropdown">
                                                                    <a className="dropdown-item custom-drop" 
                                                                        onClick={() => showUserEdit(user)}
                                                                    >
                                                                        <i className="mdi mdi-pencil mr-2 text-success"></i> <span>Edit Info</span>
                                                                    </a>
                                                                    <div className="dropdown-divider"></div>
                                                                    {/* <a className="dropdown-item custom-drop">
                                                                        <i className="mdi mdi-account-cancel-outline mr-2 text-info"></i> <span>Deactivate Account</span>
                                                                    </a> */}
                                                                    <Link className="dropdown-item custom-drop" onClick={() => openVerifications(user)}>
                                                                        <i className="mdi mdi-shield-check-outline mr-2 text-warning"></i> <span>Verification Requests</span>
                                                                    </Link>
                                                                    <div className="dropdown-divider"></div>
                                                                    <Link className="dropdown-item custom-drop" onClick={() => openProfile(user)}>
                                                                        <i className="mdi mdi-account-circle-outline mr-2 text-primary"></i> <span>Profile</span>
                                                                    </Link>
                                                                    <div className="dropdown-divider"></div>
                                                                    <a className="dropdown-item custom-drop" onClick={() => openverificationPriceUpdate(user)}>
                                                                        <i className="mdi mdi-check-outline mr-2 text-info"></i> <span>Set User Price</span>
                                                                    </a>
                                                                    <div className="dropdown-divider"></div>
                                                                    <a className="dropdown-item custom-drop" onClick={() => showUserPasswordReset(user)}>
                                                                        <i className="mdi mdi-cog-outline mr-2 text-warning"></i> <span>Reset Password</span>
                                                                    </a>
                                                                    <div className="dropdown-divider"></div>
                                                                    <a className="dropdown-item custom-drop" onClick={() => showUserDelete(user)}>
                                                                        <i className="mdi mdi-trash-can-outline mr-2 text-danger"></i> <span>Delete User</span>
                                                                    </a>
                                                                </div>
                                                            </td>
                                                    </tr>
                                                ))
                                            :
                                                <tr>
                                                    <td colSpan="6">
                                                        <div className="alert alert-info text-center">
                                                            <h6>No record found</h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='d-flex justify-content-end mt-2'>
                                    <div className='d-flex'>
                                    per page
                                    <select className='ml-1'  
                                        name='perPage' 
                                        onChange={(e) => setPerPage(e.target.value)}
                                        value={perPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='15'>15</option>
                                        <option value='20'>20</option>
                                    </select>
                                    </div>
                                    <Pagination 
                                        onChange={handlePageChange}  
                                        count={pageCount} 
                                        color="primary" 
                                        shape="rounded"
                                        size="small"
                                        page={page}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    </div>
                </div>
                </div>
            </div>

            {/* Add/Edit user modal */}
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="prompt-title font-weight-bold">
                        {isEdit ? 'Edit User Info' : 'Add New User'}
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="form-body pb-3">
                            <div className="form-group row">
                                <Forminputshort
                                    inputLabel={`First Name`}
                                    inputName="first_name"
                                    inputType={`text`}
                                    inputRequired={true}
                                    value={first_name}
                                    onChange={handleChange}
                                />
                                <Forminputshort
                                    inputLabel={`Last Name`}
                                    inputName="last_name"
                                    inputType={`text`}
                                    inputRequired={true}
                                    value={last_name}
                                    onChange={handleChange}
                                />
                            </div>

                            <Forminputgroup
                                inputLabel={`Company Name`}
                                inputName="company_name"
                                inputType={`text`}
                                inputRequire={true}
                                value={company_name}
                                onChange={handleChange}
                            />

                            <Forminputgroup
                                inputLabel={`Email Address`}
                                inputName="email"
                                inputType={`email`}
                                inputRequire={true}
                                value={email}
                                onChange={handleChange}
                            />

                            <Forminputgroup
                                inputLabel={`Phone`}
                                inputName="phone"
                                inputType={`phone`}
                                inputRequire={true}
                                value={phone}
                                onChange={handleChange}
                            />

                            <FormTextareaComponent
                                textLabel={`Address`}
                                textName="address"
                                inputRequire={true}
                                value={address}
                                onChange={handleChange}
                            />
                            {!isEdit && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <PasswordInput
                                                    inputLabel={`Password`}
                                                    inputType={toggle ? `text` : `password`}
                                                    inputName="password"
                                                    inputRequire={true}
                                                    value={password}
                                                    onChange={handleChange}
                                                    toggle={toggle}
                                                    handleToggele ={handleToggele}
                                                    size="col-lg-12"
                                                />
                                                {
                                                    password !== "" ? 
                                                        <PasswordChecklist
                                                            rules={["minLength","specialChar","number","capital","match"]}
                                                            minLength={8}
                                                            value={password}
                                                            valueAgain={password_confirmation}
                                                            onChange={(isValid) => {}}
                                                            className="password-format"
                                                        />
                                                    :
                                                        <></>
                                                }

                                            </div>
                                            <div className="col-lg-6">
                                                <PasswordInput
                                                    inputLabel={`Re-enter Password`}
                                                    inputType={toggle ? `text` : `password`}
                                                    inputName="password_confirmation"
                                                    inputRequire={true}
                                                    value={password_confirmation}
                                                    onChange={handleChange}
                                                    toggle={toggle}
                                                    handleToggele ={handleToggele}
                                                    size="col-lg-12"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" type="button" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="success" type="submit">
                            {isEdit ? 'Update' : 'Save'}
                            {submitLoading  && <ImSpinner3 className="icon-spin" />}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* user verifications modal */}
            <Modal show={showVerifications} onHide={() => setShowVerifications(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="prompt-title font-weight-bold">
                        User Verification Report
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Table responsive="sm" hover>
                            <thead>
                                <tr>
                                    <th width="3%">#</th>
                                    <th>Request ID</th>
                                    <th>Verification Type</th>
                                    <th>ID Number</th>
                                    <th>Date Requested</th>
                                    <th>Service Fee (₦)</th>
                                    <th >Status</th>
                                    {/* <th className="text-center">Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {currentUser && currentUser.verification.length > 0 ?
                                    currentUser.verification.map((report, i) => (
                                        <tr key={report.id} style={{cursor: 'pointer'}} >
                                            <td>{ i + 1 }</td>
                                            <td>{ report.request_id }</td>
                                            <td>{ report.verification_types.name }</td>
                                            <td>{ report.id_number }</td>
                                            <td>
                                                <Moment format='MMM DD, YYYY - hh:mm:a'>
                                                    { report.created_at }
                                                </Moment>    
                                            </td>
                                            <td>{ report.cost }</td>
                                            <td>
                                                <span className={`badge ${report.status == 'verified' ? 'bg-success' : 'bg-danger'} px-2 py-2`}>
                                                    { report.status }
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                :
                                    <tr>
                                        <td colSpan="7">
                                            <div className="alert alert-info text-center">
                                                <h6>No record found</h6>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" type="button" onClick={() => setShowVerifications(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* user profile modal */}
            <Modal show={showProfile} onHide={() => setShowProfile(false)} size="md">
                <Modal.Header closeButton>
                    <Modal.Title className="prompt-title font-weight-bold">
                        User Profile
                    </Modal.Title>
                </Modal.Header>
                <div className="bg-info-light">
                    <h4>₦{currentUser && Number(currentUser.wallet.total).toLocaleString()}</h4>
                    <span 
                        className={`px-3 py-2 text-capitalize`}
                        >
                        Wallet Balance
                    </span>
                </div>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>User Info</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Full Name</td>
                                    <td className="text-right">{currentUser?.last_name} {currentUser?.first_name}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td className="text-right">{currentUser?.email}</td>
                                </tr>
                                <tr>
                                    <td>Company Name</td>
                                    <td className="text-right">{currentUser?.company_name}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td className="text-right">{currentUser?.address}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" type="button" onClick={() => setShowProfile(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* user verification service price modal */}
            <Modal show={showVerificationPrice} onHide={() => setShowVerificationPrice(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="prompt-title font-weight-bold">
                        Set Verification Service Pricing for {currentUser?.last_name} {currentUser?.first_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Table responsive="sm" hover>
                          <thead>
                            <tr>
                              <th width="35%">Verification Type</th>
                              <th width="20%">Base Price(₦)</th>
                              <th>User Price(₦)</th>
                              <th width="20%" className="text-center"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {userPricing ?
                              userPricing.map((ver, i) => (
                                <tr>
                                  <td>{ver.name}</td>
                                  <td>{Number(ver.base_price).toLocaleString()}</td>
                                  <td>
                                    <input 
                                        type="text"
                                        name="cost"
                                        className="form-control"
                                        onChange={handleChange} 
                                        // value={ver.cost}
                                    />
                                    {ver.cost && <span className="text-success my-1 font-weight-bold">₦{ver.cost}</span>}
                                  </td>
                                  <td>
                                    <button onClick={(e) => setPrice(e, i)} className="btn btn-custom btn-warning">
                                      Update Price
                                    </button>
                                  </td>
                                </tr>
                              ))
                            :
                              <tr>
                                <td colSpan="3">
                                  <div className="alert alert-info text-center">
                                      <h6>No record found</h6>
                                  </div>
                                </td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" type="button" onClick={() => setShowVerificationPrice(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Notification */}
            <Dialog
                open={showDelete}
                onClose={() => setShowDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete {currentUser?.last_name} {currentUser?.first_name}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This action will delete the user record permanently and 
                    is irreversible. Are you sure you want to continue?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant="link" onClick={() => setShowDelete(false)}>No</Button>
                <Button onClick={handleDeleteUser} autoFocus>
                    Continue
                    {submitLoading  && <ImSpinner3 className="icon-spin" />}
                </Button>
                </DialogActions>
            </Dialog>

            {/* Reset Password Notification */}
            <Dialog
                open={showReset}
                onClose={() => setShowReset(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Reset {currentUser?.last_name} {currentUser?.first_name}'s password
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to continue?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant="link" onClick={() => setShowReset(false)}>No</Button>
                <Button onClick={handlePasswordReset} autoFocus>
                    Continue
                    {submitLoading  && <ImSpinner3 className="icon-spin" />}
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserContent;
