import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const createWithdraw = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/user/withdrawal-requests`, inputData, config);
    return data
}

const updateWithdraw = async (inputData, Id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.put(`${apiUrl}/user/withdrawal-requests/${Id}`, inputData, config);
    return data
}

// @ retun list of directors for a particular business
const getWithdrawals = async ( token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/withdrawal-requests?include=model`, config);
    return data
}

// @ retun list of directors for a particular business
const getWithdrawal = async (Id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/withdrawal-requests/${Id}`, config);
    return data
}

// @ function return a single director
const deleteWithdraw = async (Id, token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.delete(`${apiUrl}/user/withdrawal-requests/${Id}`, config);
  return data
}

const withdrawService = {
    createWithdraw,  
    getWithdrawal,
    getWithdrawals,
    updateWithdraw,
    deleteWithdraw
}

export default withdrawService