import { apiUrl, axiosInstance } from "hooks/axiosinstance";

const fetchNubanBanks = async (payload) => {
   return await axiosInstance.post(`${apiUrl}/generic/verification/nuban-banks`, payload);
}


const nubanBankService = {
    fetchNubanBanks
}

export default nubanBankService