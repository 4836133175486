import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`


//Update project
const updateWithdraw = async (inputData, Id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.put(`${apiUrl}/admin/withdrawal-requests/${Id}`, inputData, config);
    return data

}



// @ retun list of directors for a particular business
const getWithdraws = async ( token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/admin/withdrawal-requests?include=user,model`, config);
    return data
}

// @ retun list of directors for a particular business

const getWithdraw = async (Id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/admin/withdrawal-requests/${Id}`, config);
    return data
}



const a_withdrawService = {
    updateWithdraw,  
    getWithdraw,
    getWithdraws,
  
}

export default a_withdrawService