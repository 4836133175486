import { Link } from "react-router-dom";
import { useState } from "react";
import { ImSpinner3 } from 'react-icons/im'
import { toast } from "react-toastify";
import logo from "../../assets/img/precisionverify/precision.png";

import { resendUserEmailLink } from "features/admin/admin-control/adminService";

const VerifyEmail = () => {

  const url = process.env.NODE_ENV === 'development' ? 
  process.env.REACT_APP_DEV_CALL_BACK_URL : process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_PROD_CALL_BACK_URL : ''

  const callbackUrl = `${url}/auth/email-verified`
  const verificationData = JSON.parse(localStorage.getItem("verificationData"))

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const handleSubmit = async(e) =>{
    e.preventDefault()
    try {
        setLoading(true)
        const resp = await resendUserEmailLink(callbackUrl)
        if(resp.data.success){
            toast.success(resp.data.message)
            setMessage(resp.data.message)
            setLoading(false)
        }
    
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        toast.error(message)
        setLoading(false)
    }
  }
  

  return (
    <section className="section-sm bg-light-2 reg-bg">
      <div className="container px-4">
        <div className="row gx-4 gx-lg-5">
          <div className="inner-row">
            <div className="auth-form-wrap">
              <div className="navbar-brand logo-wrap align-items-center">
                <Link className="navbar-brand" to={`/`}>
                  <img className="logo auth-logo" src={logo} alt="giving.ng" />
                </Link>
              </div>
              <div className="auth-form">
                <form autoComplete="off" name="reset-password">
                  <div className="form-header text-center">
                    <h3 className="form-title">Verify Your Email</h3>
                    <div className="alert alert-info mt-4">
                        Account activation link sent to your email address: 
                        <b className="text-danger"> {verificationData.data.user.email}</b> Please follow the link inside to continue.
                    </div>
                  </div>
                  <div className="form-footer text-center">
                    <p>
                        Didn't get the mail? &nbsp; 
                        <Link className="inline-link" onClick={handleSubmit}>
                            Resend{" "}
                            {loading  && <ImSpinner3 className="icon-spin" />}
                        </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyEmail;
