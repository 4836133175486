import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import individualReducer from '../features/profile/individualSlice'
import bankReducer from '../features/profile/bankSlice'
import businessReducer from '../features/profile/businessSlice'
import directorReducer from '../features/profile/directorSlice'
import uploadReducer from '../features/profile/uploadSlice'
import projectReducer from '../features/cfd/projectSlice';
import adminReducer from '../features/admin/auth/adminAuthslice'
import generalProjectReducer from '../features/admin/project/generalProjectSlice'
import campaignReducer from '../features/generic/campaignSlice'
import adminprofileReducer from '../features/admin/profile/profileSlice'
import withdrawReducer from '../features/cfd/withdrawSlice';
import a_withdrawSlice from '../features/admin/withdraw/a_withdrawSlice';
import grantReducer from '../features/grant/grantSlice';
import userProfileReducer from '../features/user/profile/userProfileSlice'
import verificationTypeReducer from '../features/generic/verification/verificationTypeSlice'


export const store = configureStore({
  reducer: {
    auth: authReducer,
    individual: individualReducer,
    bank: bankReducer,
    business: businessReducer,
    director: directorReducer,
    upload: uploadReducer,
    project: projectReducer,
    admin: adminReducer,
    adminProject: generalProjectReducer,
    campaign: campaignReducer,
    adminProfile: adminprofileReducer,
    withdraw: withdrawReducer,
    awithdraw: a_withdrawSlice,
    grant: grantReducer,
    userProfile: userProfileReducer,
    verificationTypes: verificationTypeReducer
  },
});
