import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ImSpinner3 } from 'react-icons/im'
import { toast } from "react-toastify";
import logo from "../../assets/img/precisionverify/precision.png";

import { resendUserEmailLink, verifyEmailLink } from "features/admin/admin-control/adminService";

const EmailVerified = () => {
  const [queryParameters] = useSearchParams()

  const expires = queryParameters.get("expires")
  const hash = queryParameters.get("hash")
  const id = queryParameters.get("id")
  const signature = queryParameters.get("signature")


  const url = process.env.NODE_ENV === 'development' ? 
  process.env.REACT_APP_DEV_CALL_BACK_URL : process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_PROD_CALL_BACK_URL : ''

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(false)

  const verifyEmail = async() =>{
    try {
        setLoading(true)
        const resp = await verifyEmailLink(expires, hash, id, signature)
        if(resp.success){
            toast.success(resp.message)
            localStorage.removeItem("verificationData")
            setLoading(false)
            setMessage(true)
        }
    
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        // toast.error(message)
        setLoading(false)
        setMessage(false)
    }
  }


  useEffect(() => {
    verifyEmail()
  }, [])

 
  return (
    <section className="section-sm bg-light-2 reg-bg">
      <div className="container px-4">
        <div className="row gx-4 gx-lg-5">
          <div className="inner-row">
            <div className="auth-form-wrap">
              <div className="navbar-brand logo-wrap align-items-center">
                <Link className="navbar-brand" to={`/`}>
                  <img className="logo auth-logo" src={logo} alt="precisionverify" />
                </Link>
              </div>
              <div className="auth-form">
                <form autoComplete="off" name="reset-password">
                    {loading ? 
                        <div className="d-flex align-items-center justify-content-center">
                             <ImSpinner3 className="icon-spin" />
                        </div> :
                        <div className="form-header text-center">
                            <h3 className="form-title">Account Activated</h3>
                            <div className="alert alert-info mt-4">
                                Thank you, your email has been verified. Your account is now active. <br />
                                Please, use the link below to login to your account
                            </div>
                            <div className="form-group btn-wrap">
                                <Link className="btn btn-primary btn-shadow btn-round btn-md btn-login" type="button" to={"/auth"}>
                                    Login to your Account{" "}
                                </Link>
                            </div>
                        </div>
                    }
                  {/* <div className="form-footer text-center">
                    <p>
                        Didn't get the mail? &nbsp; 
                        <Link className="inline-link" onClick={handleSubmit}>
                            Resend{" "}
                            {loading  && <ImSpinner3 className="icon-spin" />}
                        </Link>
                    </p>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmailVerified ;
