import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Component pages
import ForgotPassword from "./pages/auth/ForgotPassword";
import Register from "./pages/auth/Register";
import Signin from "./pages/auth/Signin";
import Dashboard from "./pages/inner/Dashboard";

import UserResetPassword from "./pages/auth/UserResetPassword";
import ScrollToTop from "./components/ScrollToTop";
import Redirect from "./pages/auth/Redirect";
import PageNotFound from "./components/PageNotFound";
import CompleteProfile from "admin/auth/CompleteProfile";
import Privacy from "pages/Privacy/Privacy";
import Terms from "pages/Terms/Terms";
import AdminUsers from "pages/inner/Admin/Users/Users";
import UserWallet from "pages/inner/UserWallet/Wallet";
import UserProfile from "pages/inner/Profile/Profile";
import Verification from "pages/inner/Verification/Verification";
import ReportPage from "pages/inner/Reports/Report";
import AdminWallet from "pages/inner/Admin/AdminWallet/AdminWallet";
import Settings from "pages/inner/Settings/Settings";
import ConfigSetting from "pages/inner/Settings/ConfigSetting";
import { useSelector } from "react-redux";
import VerifyEmail from "pages/auth/VerifyEmail";
import EmailVerified from "pages/auth/EmailVerified";
import Login from "admin/auth/Login";
import AdminDashboard from "pages/inner/Admin/Dashboard/AdminDashboard";
import AdminForgotPassword from "admin/auth/Fogetpassword";
import AdminResetPassword from "admin/auth/AdminResetPassword";
import AdminProfile from "pages/inner/Admin/Profile/Profile";
import LivenessCheck from "pages/inner/Verification/Liveness";


function App() {
  const { user, verificationData, admin } = useSelector(state => state.auth)

  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/">
              <Route index element={<Navigate to="/inner" />} />
              <Route path="privacy-policy" element={<Privacy />} />
              <Route path="terms-of-use" element={<Terms />} />

              <Route path="auth">
                <Route index element={user ? <Navigate to="/inner" /> : <Signin />} />
                <Route path='register' element={user ? <Navigate to="/inner" /> : <Register />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                <Route path='reset-password' element={<UserResetPassword />} />
                <Route path='verify-email' element={verificationData ? <VerifyEmail /> : <Navigate to="/inner/profile" />} />
                <Route path='email-verified' element={verificationData ? <EmailVerified /> : <Navigate to="/inner/profile" />} />
              </Route>

              <Route path="admin">
                <Route index element={admin ? <AdminDashboard /> : <Navigate to="/admin/login" />} />
                <Route path='login' element={<Login />} />
                <Route path='forgot-password' element={<AdminForgotPassword />} />
                <Route path='reset-password' element={<AdminResetPassword />} />
                <Route path='settings' element={admin ? <Settings /> : <Navigate to="/admin/login" />} />
                <Route path='profile' element={admin ? <AdminProfile /> : <Navigate to="/auth" />} />
              </Route>
              
              <Route path='google/redirect' element={<Redirect />} />
              <Route path='admin/complete-profile' element={<CompleteProfile />} />

              <Route path="inner">

                <Route index element={user ? <Dashboard /> : <Navigate to="/auth" />} />

                {/* 
                  -------------------------------------
                    Precision Verify Routes
                  -------------------------------------
                */}
                  {/* Users */}
                  <Route path='users' element={<AdminUsers />} />
                  <Route path='wallet' element={user ? <UserWallet /> : <Navigate to="/auth" />} />
                  <Route path='profile' element={user ? <UserProfile /> : <Navigate to="/auth" />} />
                  <Route path='verification' element={user ? <Verification /> : <Navigate to="/auth" />} />
                  <Route path='report' element={user ? <ReportPage /> : <Navigate to="/auth" />} />
                  <Route path='admin/wallet' element={<AdminWallet />} /> {/*  To be removed laterwards */}
                  <Route path='config-setting' element={user ? <ConfigSetting /> : <Navigate to="/auth" />} /> {/*  To be removed laterwards */}
                  {/* <Route path='liveness' element={user ? <LivenessCheck /> : <Navigate to="/auth" />} /> */}

                {/* 
                  -------------------------------------
                    End ofPrecision Verify Routes
                  -------------------------------------
                */}
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </Router>
      <ToastContainer />

    </div>
  );
}

export default App;
