import InnerFooter from "../InnerFooter";
import WalletContent from "./WalletContent";

const UserWallet = () => {
  return (
    <div className="inner-page">
      {/* <Navbar /> */}
      {/* <InnerHeader /> */}
      <WalletContent />
      <InnerFooter />
    </div>
  );
};

export default UserWallet;
