import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import projectService from './projectService'


const initialState = {
    project: null,
    projects:null,
    report:null,
    isErrorP: false,
    isSuccessP:false,
    isFetchSuccessP: false,
    isDeleteP:false,
    isDeletingP:false,
    isLoadingP:false,
    messageP:''
}

//@function: create director record
export const createProject = createAsyncThunk('project/create', async (inputData, thunkAPI) =>{
  
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await projectService.createProject(inputData, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getProjects = createAsyncThunk('projects/fetch', async (_,thunkAPI) =>{
    try {
        
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await projectService.getProjects(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})



//@function: create individual record
export const getProject = createAsyncThunk('project/fetch', async (projectId, thunkAPI) =>{
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await projectService.getProject(projectId, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete bussiness
export const deleteProject = createAsyncThunk('project/delete', async (projectId, thunkAPI) =>{
    
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await projectService.deleteProject(projectId,data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const projectSlice = createSlice({
    name:'project',
    initialState,
    reducers:{
        reset:(state) => {
            state.isErrorP = false
            state.isLoadingP = false
            state.isSuccessP = false
            state.isFetchSuccessP = false
            state.isDeleteP = false
            state.isDeletingP = false
            state.messageP = ""
            state.project = null
            state.projects = null
            state.report = null
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createProject.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(createProject.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isSuccessP = true
                state.project = action.payload
            })
            .addCase(createProject.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })
            .addCase(getProjects.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(getProjects.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isFetchSuccessP = true
                state.projects = action.payload
            })
            .addCase(getProjects.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })
           

            .addCase(deleteProject.pending, (state)=>{
                state.isDeletingP = true
            })
            .addCase(deleteProject.fulfilled, (state, action)=>{
                state.isDeletingP = false
                state.isDeleteP = true
            })
            .addCase(deleteProject.rejected, (state, action) =>{
                state.isErrorP = true
                state.isDeletingP = false
                state.messageP = action.payload
            })
            .addCase(getProject.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(getProject.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isFetchSuccessP = true
                state.project = action.payload
            })
            .addCase(getProject.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })
    }
})

export const { reset} = projectSlice.actions
export default projectSlice.reducer