import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import uploadService from './uploadService'


const initialState = {
    upload:null,
    uploads:null,
    isErrorD: false,
    isSuccessD:false,
    isFetchSuccessD: false,
    isLoadingD:false,
    messageD:''
}

//@function: create director record
export const uploadDocument = createAsyncThunk('upload/create', async (inputData, businessId, thunkAPI) =>{
  console.log('Business ID', businessId);
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await uploadService.uploadDoc(inputData, businessId, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
        
    }
})

//@function: create individual record
export const getUploads = createAsyncThunk('upload/fetch', async (businessId, thunkAPI) =>{
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await uploadService.getUpload(businessId, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


export const uploadSlice = createSlice({
    name:'upload',
    initialState,
    reducers:{
        reset:(state) => {
            state.isErrorD = false
            state.isLoadingD = false
            state.isSuccessD = false
            state.isFetchSuccessD = false
        
            state.messageD = ""
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(uploadDocument.pending, (state)=>{
                state.isLoadingD = true
            })
            .addCase(uploadDocument.fulfilled, (state, action)=>{
                state.isLoadingD = false
                state.isSuccessD = true
                state.upload = action.payload
            })
            .addCase(uploadDocument.rejected, (state, action) =>{
                state.isErrorD = true
                state.isLoadingD = false
                state.messageD = action.payload
              
            })
            .addCase(getUploads.pending, (state)=>{
                state.isLoadingD = true
            })
            .addCase(getUploads.fulfilled, (state, action)=>{
                state.isLoadingD = false
                state.isFetchSuccessD = true
                state.uploads = action.payload
            })
            .addCase(getUploads.rejected, (state, action) =>{
                state.isErrorD = true
                state.isLoadingD = false
                state.messageD = action.payload
              
            })
           
    }
})

export const { reset} = uploadSlice.actions
export default uploadSlice.reducer