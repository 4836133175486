import { apiUrl, axiosInstance } from "hooks/axiosinstance";

const getVerificationTypes = async () => {
   const {data} = await axiosInstance.get(`${apiUrl}/generic/verification/types`);

   return data
}

const getUserVerificationTypes = async () => {
    const {data} = await axiosInstance.get(`${apiUrl}/user/verification/types`);
 
    return data
 }

const verificationTypeService = {
    getVerificationTypes,
    getUserVerificationTypes
}

export default verificationTypeService