import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const createGrant = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/user/grants`, inputData, config);
    return data
}

const updateGrant = async (inputData, id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.put(`${apiUrl}/user/grants/${id}`, inputData, config);
    return data
}

// @ retun list of directors for a particular business
const getGrants = async ( token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/grants`, config);
    return data
}

// @ retun list of directors for a particular business
const getGrant = async (id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/grants/${id}`, config);
    return data
}

// @ function return a single director
const deleteGrant = async (id, token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.delete(`${apiUrl}/user/grants/${id}`, config);
  return data
}

const grantService = {
    createGrant,  
    getGrant,
    getGrants,
    updateGrant,
    deleteGrant
}

export default grantService