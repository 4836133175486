import { apiUrl, axiosInstance } from "hooks/axiosinstance";

export const getUserWallet = async () => {
    return axiosInstance.get(`${apiUrl}/user/wallet`);
}

export const fundWallet = async (payload) => {
    return axiosInstance.post(`${apiUrl}/user/wallet/payment-intent`, payload);
}

export const verifyPayment = async (payload) => {
    return axiosInstance.post(`${apiUrl}/user/wallet/verify-payment`, payload);
}

export const getUserWalletTransactions = async (page, limit) => {
    return axiosInstance.get(`${apiUrl}/user/wallet/transactions?page=${page}&limit=${limit}`);
}

export const verifyIdentity = async (payload) => {
    return axiosInstance.post(`${apiUrl}/user/verification`, payload);
}

export const fetchVAT = async (payload) => {
    return axiosInstance.get(`${apiUrl}/generic/vat`, payload);
}