import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import campaignService from './campaignService'


const initialState = {
    project: null,
    projects:null,
    isError: false,
    isSuccess:false,
    isFetchSuccess: false,
    isLoading:false,
    message:''
}



//@function: create individual record
export const getCampaigns = createAsyncThunk('generic/projects', async (_,thunkAPI) =>{
    try {
        return await campaignService.getCampaigns()
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getCampaign = createAsyncThunk('generic/project', async (projectId, thunkAPI) =>{
    try {
        
        return await campaignService.getCampaign(projectId)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})



export const campaignSlice = createSlice({
    name:'generic',
    initialState,
    reducers:{
        reset:(state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.isFetchSuccess = false
           
            state.message = ""
            state.project = null
            state.projects = null
        }
    },
    extraReducers: (builder) =>{
        builder
            
            .addCase(getCampaigns.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getCampaigns.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isFetchSuccess = true
                state.projects = action.payload
            })
            .addCase(getCampaigns.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload
              
            })
            
            .addCase(getCampaign.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getCampaign.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isFetchSuccess = true
                state.project = action.payload
            })
            .addCase(getCampaign.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload
              
            })
    }
})

export const { reset} = campaignSlice.actions
export default campaignSlice.reducer