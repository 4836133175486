import Forminputgroup from "components/reusables/Forminputgroup"
import { useEffect, useState } from "react";

export default function VirtualNin({setValue}) {
    const [formData, setFormData] = useState({
        firstname:'',
        lastname:'',
        idNumber: '',
        phone: '',
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setValue(formData)
    }, [formData])

    return (
        <>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Identity Number`}
                    inputName={'idNumber'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.idNumber}
                    onChange={handleChange}
                    size="form-control-lg"
                />
                <small className="text-success pl-2" style={{fontSize: '12px', fontWeight: '800'}}>
                    This is the virtual NIN generated with NIMC mobile app with the shortCode
                </small>
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`First Name`}
                    inputName={'firstname'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.firstname}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Last Name`}
                    inputName={'lastname'}
                    inputType={`text`}
                    inputRequire={true}
                    value={formData.lastname}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
            <div className="mb-4">
                <Forminputgroup
                    inputLabel={`Phone`}
                    inputName={'phone'}
                    inputType={`text`}
                    inputRequire={false}
                    value={formData.phone}
                    onChange={handleChange}
                    size="form-control-lg"
                />
            </div>
        </>
    )
}