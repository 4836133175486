import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const createProject = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/user/projects`, inputData, config);
    return data
}

const updateProject = async (inputData, projectId, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.put(`${apiUrl}/user/projects/${projectId}`, inputData, config);
    return data
}

// @ retun list of directors for a particular business
const getProjects = async ( token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/projects?include=donations`, config);
    return data
}




// @ retun list of directors for a particular business
const getProject = async (projectId, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/projects/${projectId}?include=donations,city,state,country`, config);
    return data
}

// @ function return a single director
const deleteProject = async (projectId, token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.delete(`${apiUrl}/user/projects/${projectId}`, config);
  return data
}

const projectService = {
    createProject,  
    getProject,
    getProjects,
    updateProject,
    deleteProject,
  
}

export default projectService