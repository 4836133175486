import { apiUrl, axiosInstanceAdmin } from "hooks/axiosinstance";

export const getAllUserWallet = async(page, limit, payload) => {
    return axiosInstanceAdmin.post(`${apiUrl}/admin/wallet/all?page=${page}&limit=${limit}`, payload);
}

export const adminFundWallet = async (payload) => {
    return axiosInstanceAdmin.post(`${apiUrl}/admin/wallet/payment-intent`, payload);
}

export const adminVerifyPayment = async (payload) => {
    return axiosInstanceAdmin.post(`${apiUrl}/admin/wallet/verify-payment`, payload);
}

export const adminUpdateVerificationServiceCost = async (id, payload) => {
    return await axiosInstanceAdmin.put(`${apiUrl}/admin/verification/types/${id}`, payload);
}
export const setStatus = async (id, payload) => {
    return await axiosInstanceAdmin.put(`${apiUrl}/admin/verification/types/status/${id}`, payload);
}


// export const getUserWalletTransactions = async (page, limit) => {
//     return axiosInstance.get(`${apiUrl}/user/wallet/transactions?page=${page}&limit=${limit}`);
// }

// export const verifyIdentity = async (payload) => {
//     return axiosInstance.post(`${apiUrl}/user/verification`, payload);
// }