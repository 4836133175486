import { apiUrl, axiosInstance } from "hooks/axiosinstance";
import { useEffect} from "react";

// let r = (Math.random() + 1).toString(36).substring(2);

export default function LivenessCheck({typeId, selection, customerRef}) {
    const sendData = () => {
        return axiosInstance.post(`${apiUrl}/user/verification/liveness/save`, {selection, ref: customerRef});
    }
    useEffect(() => {
        sendData()
    }, [typeId])
    return (
        <qoreid-button id="QoreIDButton"
            title="Verify"
            sdkSources="ios_sdk"
            clientId="P9O2QA90U4EXQEPOJXY8"
            productCode={typeId==14 ? 'liveness_nin' : 
                typeId == 15 ? 'liveness_vnin' : 
                typeId == 16 ? 'liveness_drivers_license' : 
                typeId == 17 ? 'liveness_passport' : 'liveness_check'}
            customerReference={customerRef}
            applicantData='{"firstname": "", "middlename": "", "lastname": "", "gender": "", "phone": "", "email": ""}'
            onQoreIDSdkSubmitted="function(event){window.location.reload()}"
            onQoreIDSdkError="function(event){console.log(event)}"
            onQoreIDSdkClosed="function(event){console.log(event)}"
        ></qoreid-button>
    )
}