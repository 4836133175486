import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import individualService from './individualService'


const initialState = {
    respData: {},
    isError: false,
    isSuccess:false,
    isLoading:false,
    message:''
}

//@function: create individual record
export const createIndividual = createAsyncThunk('individual/create', async (inputData, thunkAPI) =>{
    // const token  = thunkAPI.getState().auth
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await individualService.create(inputData, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getIndividual = createAsyncThunk('individual/fetch', async (_, thunkAPI) =>{
    // const token  = thunkAPI.getState().auth
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await individualService.getRecord(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const individualSlice = createSlice({
    name:'individual',
    initialState,
    reducers:{
        reset:(state) => initialState
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createIndividual.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(createIndividual.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.respData = action.payload
            })
            .addCase(createIndividual.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.respData ={}
            })
            .addCase(getIndividual.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getIndividual.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.respData = action.payload
            })
            .addCase(getIndividual.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.respData ={}
            })
    }
})

export const { reset} = individualSlice.actions
export default individualSlice.reducer