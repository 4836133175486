import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`



const getProfile = async (token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/admin/profile`, config);
    return data
}

const resetPassword = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/user/change-password`, inputData, config);
    return data
}

const updateProfile = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
    }

    const { data } = await axios.post(`${apiUrl}/user/profile`, inputData, config);
    if(data){
      const userResponse = data.data.user
      let userData  = JSON.parse(localStorage.getItem('user'))
      userData.data.user = userResponse
      localStorage.setItem("user", JSON.stringify(userData));
    }
    return data
}


const userProfileService = {
    updateProfile,
    resetPassword,
    getProfile,
}

export default userProfileService