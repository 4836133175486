import React, { useRef, useCallback, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';
import './FaceMatch.css'; // Make sure to create and import a styles.css file
import Forminputgroup from 'components/reusables/Forminputgroup';

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user"
};

const FaceCapture = ({ setValue }) => {
  const webcamRef = useRef(null);
  const [isFaceFitted, setIsFaceFitted] = useState(false);
  const [error, setError] = useState("");
  const [modelsLoaded, setModelsLoaded] = useState(false);

  const [formData, setFormData] = useState({
    firstname:'',
    lastname:'',
    idNumber: '',
    phone: '',
    photoBase64: '',
    isFaceOk: false
  })

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

    useEffect(() => {
        setValue(formData)
    }, [formData])

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = '/models';
      await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
      setModelsLoaded(true);
    };

    loadModels();
  }, []);

  const detectFace = useCallback(async () => {
    if (modelsLoaded && webcamRef.current) {
      const video = webcamRef.current.video;
      const detections = await faceapi.detectSingleFace(video, new faceapi.TinyFaceDetectorOptions());
      if (detections) {
        const box = detections.box;
        const { width, height, x, y } = box;
        const boundingBox = { x: 200, y: 150, width: 320, height: 320 }; // Smaller and oval-shaped
        if (
          x >= boundingBox.x &&
          y >= boundingBox.y 
          // &&
          // x + width <= boundingBox.x + boundingBox.width &&
          // y + height <= boundingBox.y + boundingBox.height
        ) {
          setIsFaceFitted(true);
          // setFormData({ ...formData, isFaceFitted: true });
          setError("");
        } else {
          setIsFaceFitted(false);
          // setFormData({ ...formData, isFaceFitted: false });
          setError("Please fit your face in the box.");
        }
      } else {
        setIsFaceFitted(false);
        // setFormData({ ...formData, isFaceFitted: false });
        setError("No face detected. Please fit your face in the center of the box.");
      }
    }
  }, [modelsLoaded, webcamRef]);

  useEffect(() => {
    if (modelsLoaded) {
      const interval = setInterval(detectFace, 500);
      return () => clearInterval(interval);
    }
  }, [detectFace, modelsLoaded]);

  // const capture = useCallback(() => {
  //   if (isFaceFitted) {
  //     const imageSrc = webcamRef.current.getScreenshot();
  //     setFormData({ ...formData, photoBase64: imageSrc });
  //   } else {
  //     setError("Face not fitted in the oval. Please try again.");
  //   }
  // }, [webcamRef, isFaceFitted]);

  useEffect(() => {
    if (isFaceFitted) {
        const imageSrc = webcamRef.current.getScreenshot();
        setFormData({ ...formData, photoBase64: imageSrc, isFaceOk: isFaceFitted});
    } else {
        setFormData({ ...formData, photoBase64: '', isFaceOk: isFaceFitted});
        setError("Face not fitted in the center of the box. Please try again.");
    }
  }, [webcamRef, isFaceFitted]);

  return (
    <>
        <div className="mb-4">
            <Forminputgroup
                inputLabel={`Identity Number`}
                inputName={'idNumber'}
                inputType={`text`}
                inputRequire={true}
                value={formData.idNumber}
                onChange={handleChange}
                size="form-control-lg"
            />
        </div>
        <div className="mb-4">
            <Forminputgroup
                inputLabel={`First Name`}
                inputName={'firstname'}
                inputType={`text`}
                inputRequire={true}
                value={formData.firstname}
                onChange={handleChange}
                size="form-control-lg"
            />
        </div>
        <div className="mb-4">
            <Forminputgroup
                inputLabel={`Last Name`}
                inputName={'lastname'}
                inputType={`text`}
                inputRequire={true}
                value={formData.lastname}
                onChange={handleChange}
                size="form-control-lg"
            />
        </div>
        {formData.idNumber && 
          <>
            <div className='alert alert-warning px-3 py-2 instructions'>
              <p className='mb-2'><b>Instructions for Face Match on Precision Verification</b></p>
              <ul>
                <li>Ensure you are in a well-lit environment to improve image quality.</li>
                <li>Maintain a distance of approximately 2.5 feet (or 76 centimeters) from the camera.</li>
                <li>Align your face within the oval frame, ensuring that your head, ears, and a portion of your neck are visible.</li>
              </ul>
            </div>
            <div className="face-capture-container">
                <Webcam
                    audio={false}
                    height={490}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={640}
                    videoConstraints={videoConstraints}
                    className="webcam"
                />
                <div className="overlay" />
                <div className="face-box" />
                {/* <button className="capture-button" type ='button' onClick={() => capture()} disabled={!isFaceFitted}>Capture Photo</button> */}
                {error && <p className="error-message">{error}</p>}
            </div>
          </>
        }
    </>
  );
};

export default FaceCapture;
