import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from './a_withdrawService'


const initialState = {
    withdraw: null,
    withdraws:null,
    isErrorW: false,
    isSuccessW:false,
    isFetchSuccessW: false,
    
    isLoadingW:false,
    isUpdating:false,
    isUpdated:false,
    messageW:''
}



//@function: create individual record
export const getWithdraws = createAsyncThunk('withdraws/admin/fetch', async (_,thunkAPI) =>{
    try {
        
        const { data } = JSON.parse(localStorage.getItem("admin"));
       
        return await service.getWithdraws(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getWithdraw = createAsyncThunk('withdraw/admin/fetch', async (projectId, thunkAPI) =>{
    try {
        const { data } = JSON.parse(localStorage.getItem("admin"));
        return await service.getWithdraw(projectId, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const updateWithdraw = createAsyncThunk('withdraw/update', async (inputData, Id, thunkAPI) =>{
    try {
        const { data } = JSON.parse(localStorage.getItem("admin"));
        return await service.updateWithdraw(inputData, Id, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})



export const a_withdrawSlice = createSlice({
    name:'awithdraw',
    initialState,
    reducers:{
        reset:(state) => {
            state.isErrorW = false
            state.isLoadingW = false
            state.isSuccessW = false
            state.isFetchSuccessW = false
           
            state.isUpdated = false
            state.isUpdating = false
            state.messageW = ""
            state.withdraw = null
            state.withdraws = null
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(updateWithdraw.pending, (state)=>{
                state.isUpdating = true
            })
            .addCase(updateWithdraw.fulfilled, (state, action)=>{
                state.isUpdating = false
                state.isUpdated = true
                state.withdraw = action.payload
            })
            .addCase(updateWithdraw.rejected, (state, action) =>{
                state.isErrorW = true
                state.isUpdating = false
                state.messageW = action.payload
              
            })
            .addCase(getWithdraws.pending, (state)=>{
                state.isLoadingW = true
            })
            .addCase(getWithdraws.fulfilled, (state, action)=>{
                state.isLoadingW = false
                state.isFetchSuccessW = true
                state.withdraws = action.payload
            })
            .addCase(getWithdraws.rejected, (state, action) =>{
                state.isErrorW = true
                state.isLoadingW = false
                state.messageW = action.payload
              
            })
            
            .addCase(getWithdraw.pending, (state)=>{
                state.isLoadingW = true
            })
            .addCase(getWithdraw.fulfilled, (state, action)=>{
                state.isLoadingW = false
                state.isFetchSuccessW = true
                state.withdraw = action.payload
            })
            .addCase(getWithdraw.rejected, (state, action) =>{
                state.isErrorW = true
                state.isLoadingW = false
                state.messageW = action.payload
              
            })
            
    }
})

export const { reset} = a_withdrawSlice.actions
export default a_withdrawSlice.reducer