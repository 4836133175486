import { apiUrl, axiosInstance, axiosInstanceAdmin } from "hooks/axiosinstance";


export const fetchApiKey = async () => {
    return axiosInstance.get(`${apiUrl}/user/setting/get-api-key`);
}
export const fetchLiveWebhook = async (payload) => {
  return axiosInstance.get(`${apiUrl}/user/setting/get-live-webhook-key`);
}
export const fetchTestWebhook = async (payload) => {
  return axiosInstance.get(`${apiUrl}/user/setting/get-test-webhook-key`);
}
export const setWebhook = async (payload) => {
  return axiosInstance.post(`${apiUrl}/user/setting/set-api-key`,payload);
}
export const setVatPercentage = async (payload) => {
  return axiosInstanceAdmin.post(`${apiUrl}/admin/setting/set-vat-percentage`,payload);
}
export const fetchVat = async () => {
  return axiosInstanceAdmin.get(`${apiUrl}/admin/setting/get-vat-percentage`);
}
