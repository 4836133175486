import InnerFooter from "../InnerFooter";
import ReportContent from "./ReportContent";

const ReportPage = () => {
  return (
    <div className="inner-page">
      {/* <Navbar /> */}
      {/* <InnerHeader /> */}
      <ReportContent />
      <InnerFooter />
    </div>
  );
};

export default ReportPage;