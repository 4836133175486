const Forminputgroup = ({ inputLabel, inputType, inputName, inputRequire, value, onChange, isDisable, size, errorMsg }) => {
  const checkRequired = ()=>{
    return inputRequire ? '*' : ''
  }

  return (
    <div className="form-group input-wrapper">
      <input
        type={inputType}
        className={`form-control ${size ? size : ''}`}
        name={inputName}
        id={inputName}
        autoComplete="off"
        placeholder={`${inputLabel} ${checkRequired()}`}
        required={inputRequire}
        value={value} 
        onChange={onChange}
        disabled={isDisable}
      />
      {errorMsg && <small className='text-danger my-1'>{errorMsg}</small>}
    </div>
  );
};

export default Forminputgroup;
