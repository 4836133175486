import Footer from "components/Footer"
import Navbar from "components/Navbar"
import HelpCta from "pages/helpCenter/HelpCta"
import PrivacyComponent from "./PrivacyComponent"
import PrivacyHeader from "./PrivacyHeader"


const Privacy = () => {
  return (
    <>
        <Navbar />
        <PrivacyHeader />
        <PrivacyComponent />
        <HelpCta />
        <Footer />
    </>
  )
}

export default Privacy