import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import bankService from './bankService'


const initialState = {
    account: {},
    accounts:[],
    isError: false,
    isSuccess:false,
    isLoading:false,
    message:''
}

//@function: create individual record
export const createBank = createAsyncThunk('bank/create', async (inputData, thunkAPI) =>{
    // const token  = thunkAPI.getState().auth
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await bankService.create(inputData, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getBank = createAsyncThunk('bank/fetch', async (_, thunkAPI) =>{
    // const token  = thunkAPI.getState().auth
    try {
        const { data } = JSON.parse(localStorage.getItem("user"));
        return await bankService.getRecord(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const bankSlice = createSlice({
    name:'bank',
    initialState,
    reducers:{
        reset:(state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createBank.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(createBank.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.account = action.payload
            })
            .addCase(createBank.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload
              
            })
            .addCase(getBank.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getBank.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.accounts = action.payload
            })
            .addCase(getBank.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload
              
            })
    }
})

export const { reset} = bankSlice.actions
export default bankSlice.reducer