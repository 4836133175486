import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Forminputgroup from "../../components/reusables/Forminputgroup";
import logo from "../../assets/img/precisionverify/precision.png";
import { FaEnvelope } from "react-icons/fa";
import { toast } from "react-toastify";
import { forgetPassword } from "features/admin/admin-control/adminService";
import { ImSpinner3 } from "react-icons/im";

const AdminForgotPassword = () => {

  const url = process.env.NODE_ENV === 'development' ? 
  process.env.REACT_APP_DEV_CALL_BACK_URL : process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_PROD_CALL_BACK_URL : ''

  const [loading, setLoading] = useState(false)
  const [resetData, setResetData] = useState({
    email:"",
    callbackUrl: `${url}/admin/reset-password`
  });
  

  const handleChange = (e) =>{
    setResetData({...resetData, email:e.target.value})
  }

  const handleSubmit = async(e) =>{
    e.preventDefault()
    if(resetData.email === "") {
      toast.error("Pls enter your email address")
    }else{
      try {
        setLoading(true)
        const resp = await forgetPassword(resetData)
       
        if(resp.success){
          toast.success(resp.message)
          // navigate("/admin")
          setLoading(false)
          localStorage.setItem('resetAdminEmail', resetData.email)
        }
       
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        toast.error(message)
        setLoading(false)
      }
    }
  }

  return (
    <section className="section-sm bg-light-2 reg-bg">
      <div className="container px-4">
        <div className="row gx-4 gx-lg-5">
          <div className="inner-row">
            <div className="auth-form-wrap">
              <div className="navbar-brand logo-wrap align-items-center">
                <Link className="navbar-brand" to={`/`}>
                  <img className="logo auth-logo" src={logo} alt="giving.ng" />
                </Link>
              </div>
              <div className="auth-form">
                <form autoComplete="off" name="reset-password">
                  <div className="form-header text-center">
                    <h3 className="form-title">Recover Your Password</h3>
                    <p>
                      We get it, stuff happens. Just enter your email address
                      below and we'll send you a link to reset your password!
                    </p>
                  </div>
                  <div className="form-body">
                  <Forminputgroup
                      inputLabel={`Email Address`}
                      inputType={`email`}
                      inputName="email"
                      inputRequire={`required`}
                      value={resetData.email}
                      onChange={handleChange}
                    />
                    <div className="form-group btn-wrap">
                      <button className="btn btn-primary btn-shadow btn-round btn-md btn-login" onClick={handleSubmit}>
                        <FaEnvelope /> Send Link
                        {loading && <ImSpinner3 className="icon-spins" />}
                      </button>
                    </div>
                  </div>
                  <div className="form-footer text-center">
                    <p>
                      Remember your pasword?
                      <Link className="inline-link" to={`/admin`}>
                        Sign in{" "}
                      </Link>
                      here.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminForgotPassword;
