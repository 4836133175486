import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const create = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/user/business-directors`, inputData, config);
    return data
}

// @ retun list of directors for a particular business
const getRecord = async (businessId, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/business-directors?business_id=${businessId}`, config);
    return data
}

// @ retun list of directors for a particular business
const getSingleDirector = async (directorId, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/business-directors/${directorId}`, config);
    return data
}

// @ function return a single director
const deleteRecord = async (directorId, token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.delete(`${apiUrl}/user/business-directors/${directorId}`, config);
  return data
}

const directorService = {
    create,  
    getRecord,
    deleteRecord,
    getSingleDirector,
}

export default directorService