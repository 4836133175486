import { apiUrl, axiosInstanceAdmin } from "hooks/axiosinstance";

export const fetchAllUsersAdmin = async(page, limit, payload) => {
    return axiosInstanceAdmin.post(`${apiUrl}/admin/users/all?page=${page}&limit=${limit}`, payload);
}

export const createUsersAdmin = async(payload) => {
    return axiosInstanceAdmin.post(`${apiUrl}/admin/users`, payload);
}

export const updateUsersAdmin = async(id, payload) => {
    return axiosInstanceAdmin.put(`${apiUrl}/admin/users/${id}`, payload);
}

export const deleteUsersAdmin = async(id) => {
    return axiosInstanceAdmin.delete(`${apiUrl}/admin/users/${id}`);
}

export const resetUsersPasswordAdmin = async(id) => {
    return axiosInstanceAdmin.post(`${apiUrl}/admin/users/password/reset/${id}`);
}

export const getUserServicePrice = async(id) => {
    return axiosInstanceAdmin.get(`${apiUrl}/admin/verification/types/user-price/${id}`);
}

export const updateUserServicePrice = async(id, payload) => {
    return axiosInstanceAdmin.post(`${apiUrl}/admin/verification/types/user-price/${id}`, payload);
}