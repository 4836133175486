import InnerFooter from "../InnerFooter";
import ProfileContent from "./ProfileContent";

const UserProfile = () => {
    return (
      <div className="inner-page">
        {/* <Navbar /> */}
        {/* <InnerHeader /> */}
        <ProfileContent />
        <InnerFooter />
      </div>
    );
  };
  
  export default UserProfile;