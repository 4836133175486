import { useState } from "react";
import { Button, Modal } from "react-bootstrap"
import Moment from 'react-moment';


export default function VerificationResult({closeModal, show, data, verificationInfo, idNumber}) {
    const handleClose = () => {
        closeModal()
    }
    const [showDetails, setShowDetails] = useState(false)
    const [currentStakeholder, setCurrentStakeholder] = useState(null)

    const setStakeholder = (data) => {
        setCurrentStakeholder(data)
        setShowDetails(true)
    }

    return (
        <>
            {/* verification result modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="prompt-title font-weight-bold">
                        Verification Result
                    </Modal.Title>
                </Modal.Header>
                <div className="bg-info-light">
                    <h4>{verificationInfo.name}</h4>
                    {verificationInfo.id == 11  && <span 
                        className={`badge rounded-pill ${data.address.status.status == 'VERIFIED' ? 'badge-light-success' : 
                            'badge-light-danger'} px-3 py-2 text-capitalize`}
                        >
                        {data.address.status.status}
                    </span>}
                    {verificationInfo.id == 12  && <span 
                        className={`badge rounded-pill ${data.guarantor.status.status == 'VERIFIED' ? 'badge-light-success' : 
                            'badge-light-danger'} px-3 py-2 text-capitalize`}
                        >
                        {data.guarantor.status.status}
                    </span>}
                    {(verificationInfo.id != 11 && verificationInfo.id != 12) && <span 
                        className={`badge rounded-pill ${data.status.status == 'verified' ? 'badge-light-success' : 
                            'badge-light-danger'} px-3 py-2 text-capitalize`}
                        >
                        {data.status.status}
                    </span>}
                </div>
                <Modal.Body>
                    {(verificationInfo.id == 11 || verificationInfo.id == 12) && (
                        <div>
                            {(data.address && data.address.status.status == 'IN_PROGRESS') && 
                                <div className="alert alert-info py-1" style={{fontSize: '12px'}}>
                                    You will be notified via email once the verification is completed
                                </div>
                            }
                            {(data.guarantor && data.guarantor.status.status == 'IN_PROGRESS') && 
                                <div className="alert alert-info py-1" style={{fontSize: '12px'}}>
                                    You will be notified via email once the verification is completed
                                </div>
                            }
                        </div>
                    )}
                    {![14,15,16,17,18].includes(verificationInfo.id) && data[verificationInfo.code].photo && 
                        <div className="text-center mb-3">
                            <img 
                                src={`data:image/jpeg;base64,${data[verificationInfo.code].photo}`}
                                alt={verificationInfo.name}
                                style={{borderRadius: '7%'}}
                                width={100}
                            />
                        </div>
                    }
                    {[14,15,16,17,18].includes(verificationInfo.id) && data[verificationInfo.code].photo && 
                        <div className="d-flex justify-content-center mb-3">
                            <span>
                                <img 
                                    src={`data:image/jpeg;base64,${data[verificationInfo.code].photo}`}
                                    alt={verificationInfo.name}
                                    style={{borderRadius: '7%'}}
                                    width={100}
                                    height={135}
                                />
                                <p className="text-center mt-1" style={{fontSize: '12px'}}>
                                    ID Image
                                </p>
                            </span>
                            <span className="text-center confidence py-1 mx-3">
                                <p>Liveness</p>
                                <p><svg width="116" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M92.97 12.532H23.03v6.966L0 10 23.03.5v6.966h69.94V.5L116 10l-23.03 9.5v-6.968Z" fill="#3374EF"></path></svg></p>
                                <p>{data.metadata.percentageSimilarity ? data.metadata.percentageSimilarity : data.metadata.matching_threshold}%</p>
                                <p>Confidence Level</p>
                            </span>
                            <span>
                                <img 
                                    src={data.metadata.imageUrl}
                                    alt={verificationInfo.name}
                                    style={{borderRadius: '7%'}}
                                    width={100}
                                    height={135}
                                />
                                <p className="text-center mt-1" style={{fontSize: '12px'}}>
                                    Selfie Image
                                </p>
                            </span>
                        </div>
                    }
                    <div className="table-responsive">
                        {(verificationInfo.id == 11 || verificationInfo.id == 12) && 
                            <table className="table table-hover mb-4">
                                <thead>
                                    <tr>
                                        <th colSpan={3}>User Information</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data[verificationInfo.code].applicant && <tr>
                                        <td>First Name</td>
                                        <td className="text-right">{data[verificationInfo.code].applicant.firstname}</td>
                                    </tr>}
                                    {data[verificationInfo.code].applicant && <tr>
                                        <td>Last Name</td>
                                        <td className="text-right">{data[verificationInfo.code].applicant.lastname}</td>
                                    </tr>}
                                    {data[verificationInfo.code].applicant && <tr>
                                        <td>Middle Name</td>
                                        <td className="text-right">
                                            {data[verificationInfo.code].applicant.middlename ? 
                                                data[verificationInfo.code].applicant.middlename : 'N/A'
                                            }
                                        </td>
                                    </tr>}
                                    {data[verificationInfo.code].applicant && <tr>
                                        <td>Phone Number</td>
                                        <td className="text-right">
                                            {data[verificationInfo.code].applicant.phone ? 
                                                data[verificationInfo.code].applicant.phone : 'N/A'
                                            }
                                        </td>
                                    </tr>}
                                    {data[verificationInfo.code].applicant && <tr>
                                        <td>Gender</td>
                                        <td className="text-right text-capitalize">
                                            {data[verificationInfo.code].applicant.gender ? 
                                                data[verificationInfo.code].applicant.gender : 'N/A'
                                            }
                                        </td>
                                    </tr>}
                                    {data[verificationInfo.code].applicant && <tr>
                                        <td>Date of Birth</td>
                                        <td className="text-right">
                                            {data[verificationInfo.code].applicant.dob ? 
                                                data[verificationInfo.code].applicant.dob : 'N/A'
                                            }
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        }

                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>{verificationInfo.name} details</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data[verificationInfo.code].title && <tr>
                                    <td>Title</td>
                                    <td className="text-right">{data[verificationInfo.code].title}</td>
                                </tr>}
                                {[14, 15, 16, 17, 18].includes(verificationInfo.id) && (<tr>
                                    <td>
                                        {verificationInfo.id == 14 ? 'NIN' : 
                                        verificationInfo.id == 15 ? 'VNIN' :
                                        verificationInfo.id == 16 ? 'License Number' :
                                        verificationInfo.id == 17 ? 'Passport Number' :
                                        'BVN'}
                                    </td>
                                    <td className="text-right">
                                        {verificationInfo.id == 11 || verificationInfo.id == 12 ? data.id : idNumber}
                                    </td>
                                </tr>)}
                                {data[verificationInfo.code].firstname && <tr>
                                    <td>First Name</td>
                                    <td className="text-right">{data[verificationInfo.code].firstname}</td>
                                </tr>}
                                {data[verificationInfo.code].lastname && <tr>
                                    <td>Last Name</td>
                                    <td className="text-right">{data[verificationInfo.code].lastname}</td>
                                </tr>}
                                {data[verificationInfo.code].middlename && <tr>
                                    <td>Middle Name</td>
                                    <td className="text-right">{data[verificationInfo.code].middlename}</td>
                                </tr>}
                                {data[verificationInfo.code].fullname && <tr>
                                    <td>Full Name</td>
                                    <td className="text-right">{data[verificationInfo.code].fullname}</td>
                                </tr>}
                                {data[verificationInfo.code].email && <tr>
                                    <td>Email</td>
                                    <td className="text-right">{data[verificationInfo.code].email}</td>
                                </tr>}
                                {data[verificationInfo.code].birthdate && <tr>
                                    <td>Birth Date</td>
                                    <td className="text-right">{data[verificationInfo.code].birthdate}</td>
                                </tr>}
                                {data[verificationInfo.code].gender && <tr>
                                    <td>Gender</td>
                                    <td className="text-right text-capitalize">{data[verificationInfo.code].gender}</td>
                                </tr>}
                                {data[verificationInfo.code].phone && <tr>
                                    <td>Phone Number</td>
                                    <td className="text-right">{data[verificationInfo.code].phone}</td>
                                </tr>}
                                {data[verificationInfo.code].occupation && <tr>
                                    <td>Occupation</td>
                                    <td className="text-right">{data[verificationInfo.code].occupation}</td>
                                </tr>}
                                <tr>
                                    <td>
                                        {verificationInfo.id == 1 ? 'vNIN' : 
                                        verificationInfo.id == 2 ? 'License Number' :
                                        verificationInfo.id == 3 ? 'Account Number' :
                                        verificationInfo.id == 4 ? 'BVN' :
                                        verificationInfo.id == 5 ? 'BVN' :
                                        verificationInfo.id == 6 ? 'Vin' :
                                        verificationInfo.id == 7 ? 'RC Number' :
                                        verificationInfo.id == 8 ? 'Tin' :
                                        verificationInfo.id == 9 ? 'Plate Number' :
                                        verificationInfo.id == 11 ? 'Request ID' :
                                        verificationInfo.id == 12 ? 'Request ID' :
                                        verificationInfo.id == 13 ? 'Passport Number' :
                                        verificationInfo.id == 19 ? 'NIN' :
                                        verificationInfo.id == 20 ? 'BVN' :
                                        'Id Number'}
                                    </td>
                                    <td className="text-right">
                                        {verificationInfo.id == 11 || verificationInfo.id == 12 ? data.id : idNumber}
                                    </td>
                                </tr>
                                {data[verificationInfo.code].issued_date && <tr>
                                    <td>Date of Issue</td>
                                    <td className="text-right">{data[verificationInfo.code].issued_date}</td>
                                </tr>}
                                {data[verificationInfo.code].issuedDate && <tr>
                                    <td>Date Issued</td>
                                    <td className="text-right">{data[verificationInfo.code].issuedDate}</td>
                                </tr>}
                                {data[verificationInfo.code].state_of_issue && <tr>
                                    <td>State of Issue</td>
                                    <td className="text-right">{data[verificationInfo.code].state_of_issue}</td>
                                </tr>}
                                {data[verificationInfo.code].issuedAt && <tr>
                                    <td>Issued At</td>
                                    <td className="text-right">{data[verificationInfo.code].issuedAt}</td>
                                </tr>}
                                {data[verificationInfo.code].expiry_date && <tr>
                                    <td>Expiry Date</td>
                                    <td className="text-right">{data[verificationInfo.code].expiry_date}</td>
                                </tr>}
                                {data[verificationInfo.code].expiryDate && <tr>
                                    <td>Expiry Date</td>
                                    <td className="text-right">{data[verificationInfo.code].expiryDate}</td>
                                </tr>}
                                {data[verificationInfo.code].bvn && verificationInfo.id != 20 && <tr>
                                    <td>BVN</td>
                                    <td className="text-right">{data[verificationInfo.code].bvn}</td>
                                </tr>}
                                {verificationInfo.id == 20 && data[verificationInfo.code].fieldMatches.firstname && <tr>
                                    <td>Firstname</td>
                                    <td className="text-right">{data[verificationInfo.code].fieldMatches.firstname ? 'True' : 'False'}</td>
                                </tr>}
                                {verificationInfo.id == 20 && data[verificationInfo.code].fieldMatches.lastname && <tr>
                                    <td>Lastname</td>
                                    <td className="text-right">{data[verificationInfo.code].fieldMatches.lastname ? 'True' : 'False'}</td>
                                </tr>}
                                {data[verificationInfo.code].nationality && <tr>
                                    <td>Nationality</td>
                                    <td className="text-right">{data[verificationInfo.code].nationality}</td>
                                </tr>}
                                {data[verificationInfo.code].registration_date && <tr>
                                    <td>Registration Date</td>
                                    <td className="text-right">{data[verificationInfo.code].registration_date}</td>
                                </tr>}
                                {data[verificationInfo.code].enrollment_bank && <tr>
                                    <td>Enrollment Bank</td>
                                    <td className="text-right">{data[verificationInfo.code].enrollment_bank}</td>
                                </tr>}
                                {data[verificationInfo.code].enrollment_branch && <tr>
                                    <td>Enrollment Branch</td>
                                    <td className="text-right">{data[verificationInfo.code].enrollment_branch}</td>
                                </tr>}
                                {data[verificationInfo.code].level_of_account && <tr>
                                    <td>Level of Account</td>
                                    <td className="text-right">{data[verificationInfo.code].level_of_account}</td>
                                </tr>}
                                {data[verificationInfo.code].lga_of_origin && <tr>
                                    <td>Lga of Origin</td>
                                    <td className="text-right">{data[verificationInfo.code].lga_of_origin}</td>
                                </tr>}
                                {data[verificationInfo.code].marital_status && <tr>
                                    <td>Marital Status</td>
                                    <td className="text-right">{data[verificationInfo.code].marital_status}</td>
                                </tr>}
                                {data[verificationInfo.code].name_on_card && <tr>
                                    <td>Name on Card</td>
                                    <td className="text-right">{data[verificationInfo.code].name_on_card}</td>
                                </tr>}
                                {data[verificationInfo.code].residential_address && <tr>
                                    <td>Residential Address</td>
                                    <td className="text-right">{data[verificationInfo.code].residential_address}</td>
                                </tr>}
                                {data[verificationInfo.code].state_of_origin && <tr>
                                    <td>State of Origin</td>
                                    <td className="text-right">{data[verificationInfo.code].state_of_origin}</td>
                                </tr>}
                                {data[verificationInfo.code].state_of_residence && <tr>
                                    <td>State of Residence</td>
                                    <td className="text-right">{data[verificationInfo.code].state_of_residence}</td>
                                </tr>}
                                {data[verificationInfo.code].pollingUnitCode && <tr>
                                    <td>Polling Unit Code</td>
                                    <td className="text-right">{data[verificationInfo.code].pollingUnitCode}</td>
                                </tr>}
                                {data[verificationInfo.code].companyName && <tr>
                                    <td>Company Name</td>
                                    <td className="text-right">{data[verificationInfo.code].companyName}</td>
                                </tr>}
                                {data[verificationInfo.code].companyType && <tr>
                                    <td>Company Type</td>
                                    <td className="text-right">{data[verificationInfo.code].companyType}</td>
                                </tr>}
                                {data[verificationInfo.code].registrationDate && <tr>
                                    <td>Registration Date</td>
                                    <td className="text-right">
                                        <Moment format='DD-MM-YYYY'>
                                            {data[verificationInfo.code].registrationDate}
                                        </Moment>
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].branchAddress && <tr>
                                    <td>Branch Address</td>
                                    <td className="text-right">{data[verificationInfo.code].branchAddress}</td>
                                </tr>}
                                {data[verificationInfo.code].companyEmail && <tr>
                                    <td>Company Email</td>
                                    <td className="text-right">{data[verificationInfo.code].companyEmail}</td>
                                </tr>}
                                {data[verificationInfo.code].city && <tr>
                                    <td>City</td>
                                    <td className="text-right">{data[verificationInfo.code].city}</td>
                                </tr>}
                                {data[verificationInfo.code].classification && <tr>
                                    <td>Classification</td>
                                    <td className="text-right">{data[verificationInfo.code].classification}</td>
                                </tr>}
                                {data[verificationInfo.code].headOfficeAddress && <tr>
                                    <td>Head Office Address</td>
                                    <td className="text-right">{data[verificationInfo.code].headOfficeAddress}</td>
                                </tr>}
                                {data[verificationInfo.code].lga && <tr>
                                    <td>LGA</td>
                                    <td className="text-right">{data[verificationInfo.code].lga}</td>
                                </tr>}
                                {data[verificationInfo.code].affiliates && <tr>
                                    <td>Affiliates</td>
                                    <td className="text-right">{data[verificationInfo.code].affiliates}</td>
                                </tr>}
                                {data[verificationInfo.code].shareCapital > 0 && <tr>
                                    <td>Share Capital</td>
                                    <td className="text-right">{data[verificationInfo.code].shareCapital}</td>
                                </tr>}
                                {data[verificationInfo.code].shareCapitalInWords && <tr>
                                    <td>Share Capital (in words)</td>
                                    <td className="text-right">{data[verificationInfo.code].shareCapitalInWords}</td>
                                </tr>}
                                {data[verificationInfo.code].state && <tr>
                                    <td>State</td>
                                    <td className="text-right">{data[verificationInfo.code].state}</td>
                                </tr>}
                                {data[verificationInfo.code].taxpayerName && <tr>
                                    <td>Tax Payer Name</td>
                                    <td className="text-right">{data[verificationInfo.code].taxpayerName}</td>
                                </tr>}
                                {data[verificationInfo.code].cacRegNo && <tr>
                                    <td>CAC Reg No.</td>
                                    <td className="text-right">{data[verificationInfo.code].cacRegNo}</td>
                                </tr>}
                                {data[verificationInfo.code].entityType && <tr>
                                    <td>Entity Type</td>
                                    <td className="text-right">{data[verificationInfo.code].entityType}</td>
                                </tr>}
                                {data[verificationInfo.code].jittin && <tr>
                                    <td>JITTIN</td>
                                    <td className="text-right">{data[verificationInfo.code].jittin}</td>
                                </tr>}
                                {data[verificationInfo.code].chassisNumber && <tr>
                                    <td>Chassis Nimber</td>
                                    <td className="text-right">{data[verificationInfo.code].chassisNumber}</td>
                                </tr>}
                                {data[verificationInfo.code].vehicleMake && <tr>
                                    <td>Vehicle Make</td>
                                    <td className="text-right">{data[verificationInfo.code].vehicleMake}</td>
                                </tr>}
                                {data[verificationInfo.code].vehicleModel && <tr>
                                    <td>Vehicle Model</td>
                                    <td className="text-right">{data[verificationInfo.code].vehicleModel}</td>
                                </tr>}
                                {data[verificationInfo.code].vehicleCategory && <tr>
                                    <td>Vehicle Category</td>
                                    <td className="text-right">{data[verificationInfo.code].vehicleCategory}</td>
                                </tr>}
                                {data[verificationInfo.code].accountName && <tr>
                                    <td>Account Name</td>
                                    <td className="text-right">{data[verificationInfo.code].accountName}</td>
                                </tr>}
                                {data[verificationInfo.code].mobileNumber && <tr>
                                    <td>Mobile Number</td>
                                    <td className="text-right">{data[verificationInfo.code].mobileNumber}</td>
                                </tr>}
                                {data[verificationInfo.code].dob && <tr>
                                    <td>DOB</td>
                                    <td className="text-right">{data[verificationInfo.code].dob}</td>
                                </tr>}
                                {data[verificationInfo.code].requestedAt && <tr>
                                    <td>Requested At</td>
                                    <td className="text-right">
                                        <Moment format='MMM DD, YYYY'>
                                            {data[verificationInfo.code].requestedAt}
                                        </Moment>    
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].approvedAt && <tr>
                                    <td>Approved At</td>
                                    <td className="text-right">
                                        <Moment format='MMM DD, YYYY'>
                                            {data[verificationInfo.code].approvedAt}
                                        </Moment>     
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].stateName && <tr>
                                    <td>State</td>
                                    <td className="text-right">{data[verificationInfo.code].stateName}</td>
                                </tr>}
                                {data[verificationInfo.code].lgaName && <tr>
                                    <td>LGA</td>
                                    <td className="text-right">{data[verificationInfo.code].lgaName}</td>
                                </tr>}
                                {data[verificationInfo.code].location &&  <tr>
                                    <td>City</td>
                                    <td className="text-right">{data[verificationInfo.code].location.city}</td>
                                </tr>}
                                {data[verificationInfo.code].location && data[verificationInfo.code].location.street && <tr>
                                    <td>Street Name & Number</td>
                                    <td className="text-right">{data[verificationInfo.code].location.street}</td>
                                </tr>}
                                {data[verificationInfo.code].location && data[verificationInfo.code].location.locationUrl &&<tr>
                                    <td>Location Url</td>
                                    <td className="text-right">
                                        <a href={data[verificationInfo.code].location.locationUrl} target="_blank">{data[verificationInfo.code].location.locationUrl}</a>
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].location && data[verificationInfo.code].location.longitude &&<tr>
                                    <td>Address Coordinates</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].location.longitude}, {data[verificationInfo.code].location.latitude}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].neighbor && data[verificationInfo.code].neighbor.firstname &&<tr>
                                    <td>Neighbor Name</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].neighbor.lastname} {data[verificationInfo.code].neighbor.firstname}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].neighbor && data[verificationInfo.code].neighbor.phone &&<tr>
                                    <td>Neighbor Phone</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].neighbor.phone}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].neighbor && data[verificationInfo.code].neighbor.comment &&<tr>
                                    <td>Neighbor Comment</td>
                                    <td className="text-right w-50">
                                        {data[verificationInfo.code].neighbor.comment}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].agentComment &&<tr>
                                    <td>Agent Comment</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].agentComment}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].exteriorPhotos && <tr>
                                    {/* <td>Exterior Photos</td> */}
                                    <td colSpan={'2'}>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                Photos
                                            </div>
                                            <div className="col-md-4">
                                                {data[verificationInfo.code].exteriorPhotos.photo1 && 
                                                    <a href={data[verificationInfo.code].exteriorPhotos.photo1} target="_blank" className="mr-2">
                                                        <img src={data[verificationInfo.code].exteriorPhotos.photo1} width={'140'} alt="" />
                                                    </a>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                {data[verificationInfo.code].exteriorPhotos.photo2 && 
                                                    <a href={data[verificationInfo.code].exteriorPhotos.photo2} target="_blank" className="mr-2">
                                                        <img src={data[verificationInfo.code].exteriorPhotos.photo2} width={'140'} alt="" />
                                                    </a>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                {data[verificationInfo.code].exteriorPhotos.photo3 && 
                                                    <a href={data[verificationInfo.code].exteriorPhotos.photo3} target="_blank">
                                                        <img src={data[verificationInfo.code].exteriorPhotos.photo3} width={'140'} alt="" />
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].guarantorDetail &&  data[verificationInfo.code].guarantorDetail.lastname && <tr>
                                    <td>Guarantor Name</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].guarantorDetail.lastname} {data[verificationInfo.code].guarantorDetail.firstname}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].guarantorDetail && data[verificationInfo.code].guarantorDetail.email && <tr>
                                    <td>Guarantor Email</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].guarantorDetail.email}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].guarantorDetail && data[verificationInfo.code].guarantorDetail.phone && <tr>
                                    <td>Guarantor Phone</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].guarantorDetail.phone}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].guarantorVerification && data[verificationInfo.code].guarantorVerification.street && 
                                    <tr>
                                        <td>Guarantor Address</td>
                                        <td className="text-right">
                                            {data[verificationInfo.code].guarantorVerification.street}
                                        </td>
                                    </tr>
                                }
                                {data[verificationInfo.code].guarantorRelationship && data[verificationInfo.code].guarantorRelationship.relationshipType && <tr>
                                    <td>Guarantor Relationship Type</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].guarantorRelationship.relationshipType}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].guarantorRelationship && data[verificationInfo.code].guarantorRelationship.relationshipLength && <tr>
                                    <td>Guarantor Relationship Length</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].guarantorRelationship.relationshipLength}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].guarantorIdentity && data[verificationInfo.code].guarantorIdentity.identityType && <tr>
                                    <td>Guarantor Identity Type</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].guarantorIdentity.identityType}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].guarantorIdentity && data[verificationInfo.code].guarantorIdentity.identity && <tr>
                                    <td>Guarantor Identity Number</td>
                                    <td className="text-right">
                                        {data[verificationInfo.code].guarantorIdentity.identity}
                                    </td>
                                </tr>}
                                {data[verificationInfo.code].guarantorVerification && data[verificationInfo.code].guarantorVerification.comment && 
                                    <tr>
                                        <td>Guarantor Comments</td>
                                        <td className="text-right w-50">
                                            {data[verificationInfo.code].guarantorVerification.comment}
                                        </td>
                                    </tr>
                                }
                                {data[verificationInfo.code].guarantorIdentity && data[verificationInfo.code].guarantorIdentity.identityPhoto && <tr>
                                    {/* <td>Exterior Photos</td> */}
                                    <td colSpan={'2'}>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                Photo
                                            </div>
                                            <div className="col-md-12">
                                                <a href={`data:image/jpeg;base64,${data[verificationInfo.code].guarantorIdentity.identityPhoto}`} target="_blank">
                                                    <img src={`data:image/jpeg;base64,${data[verificationInfo.code].guarantorIdentity.identityPhoto}`} width={'150'} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>}

                                {[14, 15, 16, 17, 18].includes(verificationInfo.id) && (
                                    <>
                                        {data.metadata && data.metadata.type &&<tr>
                                            <td>Type</td>
                                            <td className="text-right text-capitalize">
                                                {data.metadata.type}
                                            </td>
                                        </tr>}
                                        {data.metadata && data.metadata.isLive &&<tr>
                                            <td>Is Live</td>
                                            <td className="text-right text-capitalize">
                                                {data.metadata.isLive == true ? 'Yes' : 'No'}
                                            </td>
                                        </tr>}
                                        {data.metadata && data.metadata.message &&<tr>
                                            <td>Message</td>
                                            <td className="text-right text-capitalize">
                                                {data.metadata.message}
                                            </td>
                                        </tr>}
                                    </>
                                )}
                            </tbody>
                        </table>

                        {data[verificationInfo.code].affiliatesData &&  data[verificationInfo.code].affiliatesData.length > 0 &&
                            <table className="table table-hover stakeholders">
                                <thead>
                                    <tr>
                                        <th colSpan={3}>Stakeholder Information</th>
                                    </tr>
                                    <tr className="bg-info-light">
                                        <th className="text-left">Name</th>
                                        <th className="text-left">Designation</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data[verificationInfo.code].affiliatesData.map((data, i) => (
                                        <tr key={i} onClick={() => setStakeholder(data)}>
                                            <td>{data.surname} {data.firstname}</td>
                                            <td>{data.affiliateType.name}</td>
                                            <td className="text-center">
                                                <i className="mdi mdi-chevron-right"></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" type="button" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* CAC premium affiliate data modal */}
            {currentStakeholder &&
                <Modal show={showDetails} 
                    onHide={() => setShowDetails(false)}
                    style={{cursor: 'pointer'}}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="prompt-title font-weight-bold">
                            {currentStakeholder.surname} {currentStakeholder.firstname}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <tbody>
                                    {currentStakeholder.surname && <tr>
                                        <td>Accreditation Number</td>
                                        <td className="text-right">{currentStakeholder.accreditationnumber ? 
                                            currentStakeholder.accreditationnumber : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Name</td>
                                        <td className="text-right">{currentStakeholder.surname} {currentStakeholder.firstname}</td>
                                    </tr>}
                                    {currentStakeholder.affiliateType.name && <tr>
                                        <td>Designation</td>
                                        <td className="text-right">{currentStakeholder.affiliateType.name}</td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Appointed On</td>
                                        <td className="text-right">{currentStakeholder.dateOfAppointment ? 
                                            <Moment format='DD-MM-YYYY'>
                                                {currentStakeholder.dateOfAppointment}
                                            </Moment> : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Resigned On</td>
                                        <td className="text-right">{currentStakeholder.dateOfResignation ? 
                                            currentStakeholder.dateOfResignation : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Gender</td>
                                        <td className="text-right text-capitalize">{currentStakeholder.gender ? 
                                            currentStakeholder.gender : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Phone</td>
                                        <td className="text-right">{currentStakeholder.phone ? 
                                            currentStakeholder.phone : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Email</td>
                                        <td className="text-right">{currentStakeholder.email ? 
                                            currentStakeholder.email : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Address</td>
                                        <td className="text-right">{currentStakeholder.address ? 
                                            currentStakeholder.address : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Date of Birth</td>
                                        <td className="text-right">{currentStakeholder.dateOfBirth ? 
                                            <Moment format='DD-MM-YYYY'>
                                                {currentStakeholder.dateOfBirth}
                                            </Moment> : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Country of Residence</td>
                                        <td className="text-right">{currentStakeholder.affiliatesResidentialAddress.country ? 
                                            currentStakeholder.affiliatesResidentialAddress.country : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>State of Residence</td>
                                        <td className="text-right">{currentStakeholder.affiliatesResidentialAddress.state ? 
                                            currentStakeholder.affiliatesResidentialAddress.state : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>LGA of Residence</td>
                                        <td className="text-right">{currentStakeholder.affiliatesResidentialAddress.lga ? 
                                            currentStakeholder.affiliatesResidentialAddress.lga : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>City of Residence</td>
                                        <td className="text-right">{currentStakeholder.affiliatesResidentialAddress.city ? 
                                            currentStakeholder.affiliatesResidentialAddress.city : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Document Type</td>
                                        <td className="text-right">{currentStakeholder.idType ? 
                                            currentStakeholder.idType : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Document Number</td>
                                        <td className="text-right">{currentStakeholder.identityNumber ? 
                                            currentStakeholder.identityNumber : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Shares Type</td>
                                        <td className="text-right">{currentStakeholder.typeOfShares ? 
                                            currentStakeholder.typeOfShares : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Shares Value</td>
                                        <td className="text-right">{currentStakeholder.shareCapital ? 
                                            currentStakeholder.shareCapital : 'N/A'}
                                        </td>
                                    </tr>}
                                    {currentStakeholder.surname && <tr>
                                        <td>Shares Count</td>
                                        <td className="text-right">{currentStakeholder.numSharesAlloted ? 
                                            currentStakeholder.numSharesAlloted : 'N/A'}
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-danger" type="button" onClick={() => setShowDetails(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}