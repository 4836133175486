
import DashboardContent from "./DashboardContent";
import InnerFooter from "./InnerFooter";


const Dashboard = () => {
  return (
    <div className="inner-page">
      {/* <Navbar /> */}
      {/* <InnerHeader /> */}
      <DashboardContent />
      <InnerFooter />
    </div>
  );
};

export default Dashboard;
