import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const register = async (userData) =>{
    const response = await axios.post(`${apiUrl}/user/auth/register`, userData)

    if(response.data){
        localStorage.setItem('verificationData', JSON.stringify(response.data))
    }

    return response.data
}

//login
const login = async (userData) =>{
    const response = await axios.post(`${apiUrl}/user/auth/login`, userData)

    if(response.data){
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

//admin login
const adminLogin = async (userData) =>{
    const response = await axios.post(`${apiUrl}/admin/auth/login`, userData)

    if(response.data){
        localStorage.setItem('admin', JSON.stringify(response.data))
    }

    return response.data
}

//logout 

const logout = async (token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const response = await axios.post(`${apiUrl}/user/auth/logout`, {}, config);
    
      if(response.status === 200){
        localStorage.removeItem("user")
    }
    return response.data
}

//admin logout 

const adminLogout = async (token) =>{

    const config = {
        headers :{
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(`${apiUrl}/admin/auth/logout`, {}, config);

    if(response.status === 200){
        localStorage.removeItem("admin")
    }
    return response.data
}



// const logout = () => localStorage.removeItem("user")

const authService = {
    register,
    logout,
    login,
    adminLogin,
    adminLogout
}

export default authService