import { apiUrl, axiosInstance, axiosInstanceAdmin } from "hooks/axiosinstance"

export const getAnalytics = async() => {
    return await axiosInstance.get(`${apiUrl}/user/dashboard/analytics`)
}

export const getChartAnalytics = async() => {
    return await axiosInstance.get(`${apiUrl}/user/dashboard/verifications/services/chart`)
}

export const getAnalyticsByService = async() => {
    return await axiosInstance.get(`${apiUrl}/user/dashboard/verifications/services`)
}

export const getAdminAnalytics = async() => {
    return await axiosInstanceAdmin.get(`${apiUrl}/admin/dashboard/analytics`)
}

export const getAdminChartAnalytics = async() => {
    return await axiosInstanceAdmin.get(`${apiUrl}/admin/dashboard/verifications/services/chart`)
}

export const getAdminAnalyticsByService = async() => {
    return await axiosInstanceAdmin.get(`${apiUrl}/admin/dashboard/verifications/services`)
}