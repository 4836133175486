import React from "react";
import { Link } from "react-router-dom";

const FooterItemComponent = ({ url, label }) => {
  return (
    <li className="nav-item">
      <Link to={url} className="nav-link"> {label}</Link>
    </li>
  );
};

export default FooterItemComponent;
