import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import profileService from './profileService'

const profile = JSON.parse(localStorage.getItem('admin'))
const initialState = {
    profile: profile,
    isError: false,
    isSuccess:false,
    isLoading:false,
    message:'',
    isPasswordSuccess: false,
    validationError: {}
}

//@function: reet admin password
export const resetPassword = createAsyncThunk('admin/reset', async (inputData, thunkAPI) =>{
  
    try {
        const { data } = JSON.parse(localStorage.getItem("admin"));
        return await profileService.resetPassword(inputData, data.token)
    } catch (error) {
        let validation = ''
        let message = ''
        if(error.response.status == 422) {
            validation = error.response.data.errors
            message = "The given data was invalid"
        } else {
        message = (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        }

        return thunkAPI.rejectWithValue({message,validation})
    }
})

//@function: get admin profile
export const getProfile = createAsyncThunk('admin/profile', async (_,thunkAPI) =>{
    try {
        
        const { data } = JSON.parse(localStorage.getItem("admin"));
        return await profileService.getProfile(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: update admin profile
export const updateProfile = createAsyncThunk('admin/update', async (inputData, thunkAPI) =>{
  
    try {
        const { data } = JSON.parse(localStorage.getItem("admin"));
        return await profileService.updateProfile(inputData, data.token)
    } catch (error) {
        let validation = ''
        let message = ''
        if(error.response.status == 422) {
            validation = error.response.data.errors
            message = "The given data was invalid"
        } else {
        message = (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        }

        return thunkAPI.rejectWithValue({message,validation})
    }
})


export const profileSlice = createSlice({
    name:'admin/profile',
    initialState,
    reducers:{
        reset:(state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.isPasswordSuccess = false
            state.message = ""
            state.profile = null
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(resetPassword.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(resetPassword.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isPasswordSuccess = true
                state.profile = action.payload
            })
            .addCase(resetPassword.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload.message
                state.validationError = action.payload.validation
            })
            .addCase(getProfile.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getProfile.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.profile = action.payload
            })
            .addCase(getProfile.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload
            })
            .addCase(updateProfile.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(updateProfile.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.profile = action.payload
                state.message = action.payload.message
            })
            .addCase(updateProfile.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.message = action.payload.message
                state.validationError = action.payload.validation
            })
           
    }
})

export const { reset} = profileSlice.actions
export default profileSlice.reducer