import InnerFooter from "../InnerFooter";
import SettingsContent from "./SettingsContent";

const Settings = () => {
  return (
    <div className="inner-page">
      {/* <Navbar /> */}
      {/* <InnerHeader /> */}
      <SettingsContent />
      <InnerFooter />
    </div>
  );
};

export default Settings;
