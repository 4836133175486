import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import verificationTypeService from "./verificationTypeService"

const initialState = {
    verificationTypes: null,
    userVerificationTypes: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: ''
}

export const getUserVerificationTypes = createAsyncThunk('verification/userTypes', async(_, thunkApi) => {
    try {
        return await verificationTypeService.getUserVerificationTypes()
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()

        return thunkApi.rejectWithValue(message)
    }
})

export const getVerificationTypes = createAsyncThunk('verification/types', async(_, thunkApi) => {
    try {
        return await verificationTypeService.getVerificationTypes()
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()

        return thunkApi.rejectWithValue(message)
    }
})

export const verificationTypeSlice = createSlice({
    name: 'verification/services',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getVerificationTypes.pending, (state) => {
                state.isLoading =  true
            })
            .addCase(getVerificationTypes.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess =  true
                state.verificationTypes = action.payload
            })
            .addCase(getVerificationTypes.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getUserVerificationTypes.pending, (state) => {
                state.isLoading =  true
            })
            .addCase(getUserVerificationTypes.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess =  true
                state.userVerificationTypes = action.payload
            })
            .addCase(getUserVerificationTypes.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export default verificationTypeSlice.reducer