import logo from "../../assets/img/giving_logo.svg";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { authenticate } from "../../services/post.service";
import { useState } from "react";

const Redirect = () => {
   
    const [loading, setLoading] = useState(false)
   
    
    const [queryParameters] = useSearchParams()
 
    const navigate = useNavigate()
    async function handleLogin() {
        try {
            setLoading(true)
          const { data } = await authenticate({code:queryParameters.get("code")});
            
          if(data.success){
            localStorage.setItem('user', JSON.stringify(data))
            navigate("/inner");
        }
          setLoading(false)
        } catch (error) {
          console.log(error);
        }
      }

   
  return (
    <div className="r-wrapper">
                    <Link className="navbar-brand" to={`/`}>
                            <img className="logo auth-logo r-logo" src={logo} alt="giving.ng" />
                    </Link>

                    <p className="description mt-4">Hi! you are just a step away from completing your google authentication. <br />
                      Just click on <strong>Continue</strong> button bellow, then you are done! </p>
                    <button className="btn btn-primary mt-2" onClick={handleLogin}> {loading ?  <> Loading... </> : <>Continue  <FaArrowAltCircleRight /></> } </button>
    </div>
 
  )
}

export default Redirect