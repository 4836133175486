import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from './authService'

const user = JSON.parse(localStorage.getItem("user"))
const admin = JSON.parse(localStorage.getItem("admin"))
const verificationData = JSON.parse(localStorage.getItem("verificationData"))

const initialState = {
    user: user ? user : null,
    admin: admin ? admin : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    isAdminError: false,
    isAdminSuccess: false,
    isAdminLoading: false,
    adminMessage: '',
    validationError: {},
    verificationData: verificationData ? verificationData : null
}

// Register new users
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {

    try {
        return await authService.register(user)
    } catch (error) {
        let validation = ''
        let message = ''
        if(error.response.status == 422) {
            validation = error.response.data.errors
            message = "The given data was invalid"
        } else {
        message = (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        }

        return thunkAPI.rejectWithValue({message,validation})
    }
})


// Login users
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
    try {
        return await authService.login(user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Login admin
export const adminLogin = createAsyncThunk('auth/admin-login', async (admin, thunkAPI) => {
    try {
        return await authService.adminLogin(admin)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Logout users
export const logout = createAsyncThunk('auth/logout', async (thunkAPI) => {
    const { data } = JSON.parse(localStorage.getItem("user"));
    try {
        return await authService.logout(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Logout admin
export const adminLogout = createAsyncThunk('auth/admin-logout', async (thunkAPI) => {
    const { data } = JSON.parse(localStorage.getItem("admin"));
    try {
        return await authService.adminLogout(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.message = ""
            state.isAdminError = false
            state.isAdminLoading = false
            state.isAdminSuccess = false
            state.adminMessage = ""
        },
        removeUser: (state) => {
            state.user = null
        },
        removeAdmin: (state) => {
            state.admin = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.verificationData = action.payload
            })
            .addCase(register.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.user = null
                state.message = action.payload.message
                state.validationError = action.payload.validation
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.user = null
                state.message = action.payload
            })
            .addCase(adminLogin.pending, (state) => {
                state.isAdminLoading = true
            })
            .addCase(adminLogin.fulfilled, (state, action) => {
                state.isAdminLoading = false
                state.isAdminSuccess = true
                state.admin = action.payload
            })
            .addCase(adminLogin.rejected, (state, action) => {
                state.isAdminError = true
                state.isAdminLoading = false
                state.user = null
                state.adminMessage = action.payload
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null
            })
            .addCase(adminLogout.fulfilled, (state) => {
                state.admin = null
            })
    }
})

export const { reset, removeUser, removeAdmin } = authSlice.actions
export default authSlice.reducer
