import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import projectService from './generalProjectService'


const initialState = {
    project: null,
    projects:null,
    report:null,
    stat:null,
    isErrorP: false,
    isSuccessP:false,
    isFetchSuccessP: false,
    isDeleteP:false,
    isDeletingP:false,
    isLoadingP:false,
    isActivating:false,
    isActivate:false,
    messageP:''
}

//@function: create director record
export const createProject = createAsyncThunk('project/general/create', async (inputData, thunkAPI) =>{
  
    try {
        const { data } = JSON.parse(localStorage.getItem("admin"));
        return await projectService.createProject(inputData, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getProjects = createAsyncThunk('projects/general/fetch', async (_,thunkAPI) =>{
    try {
        
        const { data } = JSON.parse(localStorage.getItem("admin"));
       
        return await projectService.getProjects(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const getProjectReport = createAsyncThunk('projects/report/fetch', async (_,thunkAPI) =>{
    try {
        
        const { data } = JSON.parse(localStorage.getItem("admin"));
       
        return await projectService.getProjectReport(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const getStat = createAsyncThunk('projects/stat/fetch', async (_,thunkAPI) =>{
    try {
        
        const { data } = JSON.parse(localStorage.getItem("admin"));
       
        return await projectService.getStat(data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getProject = createAsyncThunk('project/general/fetch', async (projectId, thunkAPI) =>{
    try {
        const { data } = JSON.parse(localStorage.getItem("admin"));
        return await projectService.getProject(projectId, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const activateProject = createAsyncThunk('project/general/activate', async (projectId, thunkAPI) =>{
    try {
        const { data } = JSON.parse(localStorage.getItem("admin"));
      
        return await projectService.activateProject(projectId, data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


//@function: delete bussiness
export const deleteProject = createAsyncThunk('project/general/delete', async (projectId, thunkAPI) =>{
    
    try {
        const { data } = JSON.parse(localStorage.getItem("admin"));
        return await projectService.deleteProject(projectId,data.token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const generalProjectSlice = createSlice({
    name:'generalProject',
    initialState,
    reducers:{
        reset:(state) => {
            state.isErrorP = false
            state.isLoadingP = false
            state.isSuccessP = false
            state.isFetchSuccessP = false
            state.isDeleteP = false
            state.isDeletingP = false
            state.isActivate = false
            state.isActivating = false
            state.messageP = ""
            state.project = null
            state.projects = null
            state.report = null
            state.stat = null
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createProject.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(createProject.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isSuccessP = true
                state.project = action.payload
            })
            .addCase(createProject.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })
            .addCase(getProjects.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(getProjects.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isFetchSuccessP = true
                state.projects = action.payload
            })
            .addCase(getProjects.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })

            .addCase(getProjectReport.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(getProjectReport.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isFetchSuccessP = true
                state.report = action.payload
            })
            .addCase(getProjectReport.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })

            .addCase(getStat.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(getStat.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isFetchSuccessP = true
                state.stat = action.payload
            })
            .addCase(getStat.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })

            .addCase(deleteProject.pending, (state)=>{
                state.isDeletingP = true
            })
            .addCase(deleteProject.fulfilled, (state, action)=>{
                state.isDeletingP = false
                state.isDeleteP = true
                state.projects = action.payload
            })
            .addCase(deleteProject.rejected, (state, action) =>{
                state.isErrorP = true
                state.isDeletingP = false
                state.messageP = action.payload
            })
            .addCase(getProject.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(getProject.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isFetchSuccessP = true
                state.project = action.payload
            })
            .addCase(getProject.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })
            .addCase(activateProject.pending, (state)=>{
                state.isActivating = true
            })
            .addCase(activateProject.fulfilled, (state, action)=>{
                state.isActivating = false
                state.isActivate = true
            })
            .addCase(activateProject.rejected, (state, action) =>{
                state.isErrorP = true
                state.isActivating = false
                state.messageP = action.payload
            })
    }
})

export const { reset} = generalProjectSlice.actions
export default generalProjectSlice.reducer