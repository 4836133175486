import InnerFooter from "../../InnerFooter";
import AdminWalletContent from "./AdminWalletContent";

const AdminWallet = () => {
  return (
    <div className="inner-page">
      {/* <Navbar /> */}
      {/* <InnerHeader /> */}
      <AdminWalletContent />
      <InnerFooter />
    </div>
  );
};

export default AdminWallet;