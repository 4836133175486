
import logo from "../assets/img/giving_logo.svg";
export const footerData = {
    logo: logo,
    logoAlt: "giving.png",
    // brief:"Giving.ng helps you raise funds, and build local and international partnerships to create lasting social impact, reduce poverty and create a better world.",
    brief: "Our mission is to provide a very secure platform that facilitates alternative ﬁnancing for social impact projects across critical sectors using the power of the collective.",
    learnMoreUrl: "#",
    learnMoreLabel: "Learn More",
    copyright: " - Giving.NG",
    fundRaise: {
      title: "Categories",
      data: [
        {
          id:1,
          url: "#",
          label: "Education",
        },
        {
          id:2,
          url: "#",
          label: "Emergency",
        },
        {
          id:3,
          url: "#",
          label: "Charity",
        },
        {
          id:4,
          url: "#",
          label: "Medical",
        },
        {
          id:5,
          url: "#",
          label: "Project",
        },
        {
          id:6,
          url: "#",
          label: "Memorial",
        },
      ],
    },

    learnMore: {
      title: "Learn More",
      data: [
        {
          id:1,
          url: "/how-it-works",
          label: "How It Works",
        },
        {
          id:2,
          url: "/crowdfunding",
          label: "CrowdFunding",
        },
        {
          id:3,
          url: "/giving-impact",
          label: "Giving Impact",
        },
        {
          id:4,
          url: "/giving-group",
          label: "Giving Group",
        },
        {
          id:5,
          url: "/help-center",
          label: "Completion Grant",
        },
        {
          id:6,
          url: "/volunteer-network",
          label: "Volunteer Network",
        },
      ],
    },
    resources: {
      title: "Resources",
      data: [
        {
          id:1,
          url: "#",
          label: "News Blog",
        },
        {
          id:2,
          url: "#",
          label: "Press Releases",
        },
        {
          id:3,
          url: "#",
          label: "Announcements",
        },
        {
          id:4,
          url: "/help-center",
          label: "Help Center",
        },
        {
          id:5,
          url: "/careers",
          label: "Careers",
        },
        {
          id:6,
          url: "/about",
          label: "About Giving",
        },
      ],
    },
  };
