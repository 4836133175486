import InnerFooter from "../../InnerFooter";
import UserContent from "./UsersContent";

const AdminUsers = () => {
  return (
    <div className="inner-page">
      {/* <Navbar /> */}
      {/* <InnerHeader /> */}
      <UserContent />
      <InnerFooter />
    </div>
  );
};

export default AdminUsers;
