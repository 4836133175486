import Forminputgroup from "components/reusables/Forminputgroup";
import { adminFundWallet, adminVerifyPayment } from "features/admin/wallet/adminWalletService";
import { fetchVAT } from "features/user/wallet/userWalletService";
import { paystackKey } from "hooks/axiosinstance";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";


export default function AdminWalletTopUp({show, closeModal, currentUser, walletId}) {

    //  top up form data
    const [formData, setFormData] = useState({
        amount: '',
        user: currentUser !== null ? currentUser.id : '',
        wallet_id: walletId,
        vat: '',
        vatAmount: ''
    })
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        closeModal()
        formData.amount = ''
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        calculateVatAmount(e.target.value);
    };

    const fetchWalletVat = async() => {
        const resp = await fetchVAT()

        if(resp.success){
            setFormData(prevFormData => (
                {...prevFormData, vat: resp.data.vat?.vat_percentage}
            ))
        }
    }

    const payWithPaystack = (transRef, transAmount) => {
        var handler = window.PaystackPop.setup({
          key: paystackKey, // Replace with your public key
          email: currentUser !== null ? currentUser.email : '',
          amount: transAmount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
          currency: 'NGN', // Use GHS for Ghana Cedis or USD for US Dollars
          ref: transRef, // Replace with a reference you generated
          callback: function(response) {
            //this happens after the payment is completed successfully
            paymentCallback(response); 
          },
          onClose: function() {
            alert('Transaction was not completed, window closed.');
          },
        });
        handler.openIframe();
    }

    const paymentCallback = async(response) => {
        const reference = response.reference;
        const resp = await adminVerifyPayment({transRef: reference})

        if(resp.success){
            window.location.reload()
        }
    }

    const calculateVatAmount = (amount) => {
        if(amount) {
            const calculated_vat =  (formData.vat / 100) * amount
            setFormData(prevFormData => (
                {...prevFormData, vatAmount: calculated_vat}
            ))
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()

        if(formData.amount == '') {
            return toast.error('Amount cannot be empty')
        }

        if(formData.amount < 50) {
            return toast.error('Enter amount greater than ₦50')
        }

        try {
            setLoading(true)
            const resp = await adminFundWallet(formData)
            
            if(resp.success){
                setLoading(false)
                const trans_amount = formData.vatAmount ? Number(formData.vatAmount) + Number(resp.data.item.amount) :
                Number(resp.data.item.amount)
                payWithPaystack(resp.data.item.transaction_reference, trans_amount)
            }
        
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchWalletVat()
    }, [])

    return (
        <>
            {/* wallet top up modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="prompt-title font-weight-bold">
                        Wallet Top Up for {currentUser.last_name} {currentUser.first_name}
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="form-body pb-3">
                            <Forminputgroup
                                inputLabel={`Enter Amount (₦)`}
                                inputName="amount"
                                inputType={`text`}
                                inputRequire={true}
                                value={formData.amount}
                                onChange={handleChange}
                            />
                        </div>
                        {formData.amount && (<div className="amount-info">
                            <div className="d-flex">
                                <span>Amount</span>
                                <span className="ml-auto">₦{Number(formData.amount).toLocaleString()}</span>
                            </div>
                            {formData.vat && (<div className="d-flex">
                                <span>Vat ({formData.vat}%)</span>
                                <span className="ml-auto">₦{Number(formData.vatAmount).toLocaleString()}</span>
                            </div>)}
                            <div className="d-flex">
                                <span>Subtotal</span>
                                <span className="ml-auto">₦{
                                    formData.vatAmount ? Number(Number(formData.vatAmount) + Number(formData.amount)).toLocaleString() :
                                        Number(formData.amount).toLocaleString()
                                    }
                                </span>
                            </div>
                        </div>)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" type="button" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="success" type="submit">
                            Top Up
                            {loading  && <ImSpinner3 className="icon-spin" />}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}