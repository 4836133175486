import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const create = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/user/businesses`, inputData, config);
    return data
}

const getRecord = async (token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/user/businesses`, config);
    return data
}

const deleteRecord = async (businessId, token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.delete(`${apiUrl}/user/businesses/${businessId}`, config);
  return data
}

const businessService = {
    create,  
    getRecord,
    deleteRecord
}

export default businessService