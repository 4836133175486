import logo from "../../assets/img/precisionverify/precision.png";
import { Link, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import PasswordChecklist from "react-password-checklist"
import { useState } from "react";

import PasswordInput from "components/reusables/PasswordInput";
import { toast } from "react-toastify";
import {  resetUserPassword } from "features/admin/admin-control/adminService";
import { ImSpinner3 } from "react-icons/im";

const UserResetPassword = () => {
  const [queryParameters] = useSearchParams()

    const [loading, setLoading] = useState(false)
    const [toggle, setToggle] = useState(false)
    const [userData, setUserData] = useState({
      email:localStorage.getItem('resetEmail'),
      password: "",
      password_confirmation: "",
      token: queryParameters.get("token")
    });
    const { password, password_confirmation} = userData
   
    const navigate = useNavigate()
    

      const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
      };
    
      const handleToggele = () =>{
        setToggle(!toggle)
      }
      const handleSubmit = async (e) =>{
        e.preventDefault()
        if(password === "" || password_confirmation === "") {
          toast.error("Pls enter all required!")
        } else if (password !== password_confirmation){
          toast.error("Password not match")
        }else {
          try {
            setLoading(true)
            const resp = await resetUserPassword(userData)
           
            if(resp.success){
              toast.success(resp.message)
              localStorage.removeItem('resetEmail')
              navigate("/auth")
              setLoading(false)
            }
           
          
          } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            toast.error(message)
            setLoading(false)
          }
        }
        
      }

  return (
    <section className="section-sm bg-light-2 reg-bg">
    <div className="container px-4">
      <div className="row gx-4 gx-lg-5">
        <div className="inner-row">
          <div className="auth-form-wrap">
            <div className="navbar-brand logo-wrap align-items-center">
              <Link className="navbar-brand" to={`/`}>
                <img className="logo auth-logo" src={logo} alt="giving.ng" />
              </Link>
            </div>
            <div className="auth-form">
              <form autoComplete="off" name="reset-password">
                <div className="form-header text-center">
                  <h3 className="form-title">Reset Password</h3>
                  <p>
                   Kindly complete the form below to reset your password.
                  </p>
                </div>
                <div className="form-body">
      
                <PasswordInput
                      inputLabel={`Password`}
                      inputType={toggle ? `text` : `password`}
                      inputName="password"
                      inputRequire={`required`}
                      value={password}
                      onChange={handleChange}
                      toggle={toggle}
                      handleToggele ={handleToggele}
                    />

              <PasswordInput
                      inputLabel={`Confirm Password`}
                      inputType={toggle ? `text` : `password`}
                      inputName="password_confirmation"
                      inputRequire={`required`}
                      value={password_confirmation}
                      onChange={handleChange}
                      toggle={toggle}
                      handleToggele ={handleToggele}
                    />
 {
                    password !== "" ? 
                    <PasswordChecklist
                    rules={["minLength","specialChar","number","capital","match"]}
                    minLength={8}
                    value={password}
                    valueAgain={password_confirmation}
                    onChange={(isValid) => {}}
                    className="password-format"
                  />
                  :
                  <></>
                  }

                  <div className="form-group btn-wrap">
                    <button onClick={handleSubmit} className="btn btn-primary btn-shadow btn-round btn-md btn-login">
                      Reset Password 
                      {loading && <ImSpinner3 className="icon-spins" />}
                    </button>
                  </div>
                </div>
                <div className="form-footer text-center">
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default UserResetPassword