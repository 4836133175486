import InnerFooter from "../InnerFooter";
import VerificationContent from "./VerifyContent";

const Verification = () => {
  return (
    <div className="inner-page">
      {/* <Navbar /> */}
      {/* <InnerHeader /> */}
      <VerificationContent />
      <InnerFooter />
    </div>
  );
};

export default Verification;